/*
@name Collapse list
@description
This is a <a href="/platform/components/collapse.html">Collapse</a> component in inside a list. This is the browser standard and thus more accessible version of <a href="/platform/components/collapsible-list-deprecated.html">Collapsible list</a>.

@section Components
@type UI
@extended false
@className collapseList

@markup
<ul class="collapseList">
    <li class="collapseList__item">
        <details class="collapse collapse--lighterColor collapse--right" data-eventdata="{}">
            <summary class="collapse__header">
                Header
            </summary>

            <div class="collapse__body collapse__body--padded">
                Body
            </div>
        </details>
    </li>
    <li class="collapseList__item">
        <details class="collapse collapse--lighterColor collapse--right" data-eventdata="{}">
            <summary class="collapse__header">
                Header 2
            </summary>

            <div class="collapse__body collapse__body--padded">
                Body
            </div>
        </details>
    </li>
    <li class="collapseList__item">
        <details class="collapse collapse--lighterColor collapse--right" data-eventdata="{}">
            <summary class="collapse__header">
                Header 3
            </summary>
            <div class="collapse__body collapse__body--padded">
                Body
            </div>
        </details>
    </li>
</ul>
*/

.collapseList {
    margin-top: toRem(26px);
    margin-bottom: toRem(16px);
}

.collapseList__item {
    border-bottom: toRem(1px) solid $ns-grey-70;
    list-style: none;
    margin: 0;
    padding: 0;

    &:first-child {
        border-top: toRem(1px) solid $ns-grey-70;
    }
}
