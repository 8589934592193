/*
@name Form Radiobutton
@description Styling for composite form html
@section Objects
@type UI
@extended false
@className radioButton

@markup
<fieldset class="formfield" data-label="Hoe komt u op het station?" >
    <legend class="formfield__label">Hoe komt u op het station?<br><span class="formfield__required">(optioneel)</span></legend>
    <div class="formfield__inputContainer">
        <ul class="list formfield__inputGroup">
            <li class="list__item formfield__inputGroupItem">
                <div class="radioButton">
                    <input type="radio"
                           class="radioButton__input"
                           id="input3"
                           name="input3">
                    <label class="radioButton__label" for="input3" tabindex="-1">Met de auto</label>
                </div>
            </li>
            <li class="list__item formfield__inputGroupItem">
                <div class="radioButton">
                    <input type="radio" class="radioButton__input" id="input4" name="input3" >
                    <label class="radioButton__label" for="input4" tabindex="-1">Met de fiets</label>
                </div>
            </li>
            <li class="list__item formfield__inputGroupItem">
                <div class="radioButton">
                    <input type="radio" class="radioButton__input" id="input5" name="input3" >
                    <label class="radioButton__label" for="input5" tabindex="-1">Met het openbaar vervoer</label>
                </div>
            </li>
            <li class="list__item formfield__inputGroupItem">
                <div class="radioButton radioButton--otherValue" data-controller="generic/ui/OtherValueRadioButton">
                    <input type="radio" class="radioButton__input" id="input6" name="input3" value="-other" >
                    <label class="radioButton__label" for="input6" tabindex="-1">
                        <span class="alt">Anders, namelijk:</span>
                    </label>
                    <div class="radioButton__otherValue">
                        <input type="text"
                               class="textInput textInput--fullWidth"
                               name="input3_other"
                               aria-label="textveld voor Anders, namelijk:"
                            />
                    </div>
                </div>
            </li>
        </ul>
        <div class="formfield__errorMessage">
            Vertrekdatum is een verplicht veld
        </div>
        <a href="javascript:void(0)" class="toolTip formfield__toolTip" data-controller="generic/ui/ToolTip">
            <span class="toolTip--hidden"> E illis, cum volemus. Ea possunt paria non esse.
                At eum nihili facit; Nec tamen ullo modo summum pecudis bonum et hominis idem mihi videri pot
            </span>
        </a>
    </div>
</fieldset>

*/

$radiobutton-space: 28px;

.radioButton {
    position: relative;
    vertical-align: top;
}

.radioButton__input {
    @extend %visuallyhidden;

    & + .radioButton__label {
        &::before {
            content: '';
            background: $white;
            border: $input-border;
            width: toRem(20px);
            height: toRem(20px);
            position: absolute;
            left: 0;
            top: toRem(2px);
            padding: 0;
            border-radius: 50%;
            cursor: pointer;
            transition: $fade-link;
        }

        &::after {
            content: '';
            display: block;
            left: toRem(5px);
            top: toRem(7px);
            width: toRem(10px);
            height: toRem(10px);
            position: absolute;
            border-radius: 50%;
            line-height: 0;
            padding: 0;
            background: $white;
            cursor: pointer;
            transition: $fade-all;
            transform: scale(0);
            backface-visibility: hidden; // Fix for strange bug in Chrome on Windows causing all the labels to change colors
        }
    }

    .has-error &:not(:focus):not(:hover) + .radioButton__label::before {
        border-color: $input-border-color-error;
    }

    &:hover + .radioButton__label {
        &::before {
            border-color: $input-border-color-hover;
        }
    }

    &:checked + .radioButton__label {
        color: $ns-blue;

        &::after {
            background-color: $ns-acc-green;
            transition: $fade-all;
            transform: scale(1);
        }
    }

    &:focus + .radioButton__label {
        &::before {
            border-color: $input-border-color-focus;
            box-shadow: $input-box-shadow-focus;
        }
    }
}

.radioButton__label {
    display: block;
    cursor: pointer;
    padding-left: toRem($radiobutton-space);

    &:focus {
        outline: none;
    }
}

/**
 * Modifier for "other" checkbox layout
 */
.radioButton--otherValue {
    margin-top: toRem($line-height-base - $input-height) * 0.5;

    .radioButton__label {
        position: absolute;
        left: 0;
        top: toRem($form-element-height - $line-height-base) * 0.5;
    }
}

.radioButton__otherValue {
    margin-left: toRem($radiobutton-space);
}

.radioButton--underlined {
    text-align: center;

    .radioButton__label {
        color: $ns-blue;
        padding-top: toRem(15px);
        padding-left: 0;
        padding-bottom: toRem(13px);

        // Remove radio outer + inner circles
        &::before,
        &::after {
            display: none;
        }
    }

    .radioButton__input {
        & + .radioButton__label {
            border-bottom: toRem(2px) solid $ns-grey-alpha-10;
            transition: border-color 0.2s ease-in-out;
        }

        &:focus + .radioButton__label {
            @extend %outline;
        }

        &:hover + .radioButton__label {
            border-bottom-color: $ns-grey-alpha-20;
        }

        &:checked + .radioButton__label {
            border-bottom-color: $ns-blue;
            font-weight: bold;
            padding-top: toRem(14px); // Because the font-weight changes, adjust the top/bottom padding
            padding-bottom: toRem(14px); // Because the font-weight changes, adjust the top/bottom padding
        }
    }
}
