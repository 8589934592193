/*
@name Breadcrumb
@description
Breadcrumb also used as header

@section Objects
@type UI
@extended false

@markup
<ul class="breadcrumb">
    <li class="breadcrumb__item"><a class="breadcrumb__link" href="javascript:void(0)">Klantenservice</a></li>
    <li class="breadcrumb__item"><a class="breadcrumb__link" href="javascript:void(0)">Wijzigen</a></li>
</ul>
*/

.breadcrumb {
    color: $ns-light-blue;
    margin-top: 0;
    margin-bottom: toRem(10px);
    list-style: none;
}

.breadcrumb__item {
    padding: 0;
    margin: 0;
    display: inline-block;

    &::after {
        //Old syntax first for browsers that do not support the new syntax
        content: ' /';
        content: ' /' / '';
    }
}

.breadcrumb__link {
    @include un-anchorlink;

    &:hover {
        color: $ns-blue;
    }
}

.breadcrumb--white {
    color: $ns-white;

    .breadcrumb__link {
        color: $ns-white;

        &:hover {
            color: $ns-grey-90;
        }
    }
}

.breadcrumb--blue {
    color: $ns-blue;

    .breadcrumb__link {
        color: $ns-blue;

        &:hover {
            color: #022259; /* custom color */
        }
    }
}
