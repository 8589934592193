/*
@name Form Elements
@description
Inputs and elements that want to look like an input (like a `<div>` or a `<textarea>`).
The inputs use only the `.textInput` class in able for it to look how it suppose to.
This class also works when applying it to a div. It will take the styling of an input.
<br/>The textarea uses the `.textArea` class.

@section Objects
@type UI
@extended false
@category form
@hideClassName true

@markup
<input class="textInput" type="text" value="input type text" /><br><br>
<input class="textInput" type="email" value="input type email" /><br><br>
<input class="textInput" type="search" value="input type search" /><br><br>
<input class="textInput" type="password" value="input type password" /><br><br>
<input class="textInput" type="text" readonly="readonly" value="input readonly" /><br><br>
<input class="textInput textInput--noBorder" type="text" value="input type text no border" /><br><br>
<input class="textInput textInput--focusBorderOnly" type="text" value="input type text only focus border" /><br><br>
<input class="textInput textInput--focusBorderOnly textInput--darkFocus" type="text" value="input type text only focus border for use on light-blue backgrounds" /><br><br>
<input class="textInput textInput--search" type="search" value="input type search with search styling" /><br><br>
<textarea class="textArea">This is a simple texarea</textarea><br><br>
<textarea class="textArea textArea--noBorder">This is a simple texarea without border</textarea><br><br>
<div class="textInput">text in a dummy textInput</div><br>

*/

$input-height: $form-element-height;
$input-line-height: $form-element-line-height;
$input-vertical-padding: $form-element-vertical-space;
$input-horizontal-space: 15px;

$input-border-color: $ns-grey-80;
$input-border-color-error: $ns-red-alert;
$input-border-color-hover: $ns-grey-60;
$input-border-color-focus: $ns-light-blue;
$input-dark-border-color-focus: $ns-blue;

$input-border: toRem(1px) solid $input-border-color;
$input-border-radius: toRem(4px);

$input-box-shadow-focus: 0 0 0 1px $ns-light-blue, 0 2px 28px rgba($ns-black, 0.1);
$input-dark-box-shadow-focus: 0 0 0 1px $ns-blue, 0 2px 28px rgba($ns-black, 0.1);
$input-box-shadow-autofill: 0 0 0 1000px $ns-light-blue-90 inset;

@mixin textfield__lineheightfix {
    // lineheight is bepaald op 25px. Om de tekst optisch in het midden van een textinput van 45px hoog te zetten, is het nodig om
    // hem net onder het absolute midden te zetten. Daarom de padding-top + 1px, en de padding-bottom - 1px.
    padding-top: toRem($input-vertical-padding + 1px);
    padding-bottom: toRem($input-vertical-padding - 1px);
}

%textfield__lineheightfix {
    @include textfield__lineheightfix();
}

%textfield {
    @extend %textfield__lineheightfix;
    @extend %textfield--border;

    appearance: none;
    padding-left: toRem($input-horizontal-space);
    padding-right: toRem($input-horizontal-space);
    background-color: $white;
    color: $ns-blue;
    height: toRem($input-height);
    line-height: toRem($input-line-height);
    font-weight: bold;

    &:-webkit-autofill {
        box-shadow: $input-box-shadow-autofill;
        -webkit-text-fill-color: $ns-blue;
    }

    &:-webkit-autofill:focus {
        box-shadow: $input-box-shadow-focus, $input-box-shadow-autofill;
    }
}

%textfield--readonly {
    color: $ns-grey-60;
    background-color: $off-white;
}

%textfield--border {
    border: $input-border;
    border-radius: $input-border-radius;
    transition: $fade-link;

    &:hover {
        border-color: $input-border-color-hover;
    }

    &:focus {
        outline: 0;
        border-color: $input-border-color-focus;
        box-shadow: $input-box-shadow-focus;
    }

    .has-error &:not(:focus):not(:hover) {
        border-color: $input-border-color-error;
    }
}

%textfield--noBorder {
    border: none;

    &:hover {
        border: none;
    }

    &:focus {
        border: none;
        box-shadow: none;
    }

    .has-error &:not(:focus):not(:hover) {
        border: none;
    }
}

.textInput {
    @extend %textfield;

    // remove webkit number spinners
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    // firefox
    -moz-appearance: textfield;
}

.textInput--noBorder {
    @extend %textfield--noBorder;
}

.textInput--focusBorderOnly {
    @extend %textfield--noBorder;

    &:focus {
        border: $input-border;
        outline: 0;
        border-color: $input-border-color-focus;
        box-shadow: $input-box-shadow-focus;

        &.textInput--darkFocus {
            border-color: $input-dark-border-color-focus;
            box-shadow: $input-dark-box-shadow-focus;
        }
    }
}

.textInput--search {
    display: block;

    @include createIconBg('magnifier', $ns-blue);

    background-size: toRem($icon-size-base);
    background-position: toRem(5px);
    background-repeat: no-repeat;
    padding-left: toRem($input-height);
    padding-right: toRem($input-height);
    width: 100%;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        // IE11 desktop only. do not touch mobile!
        &::-ms-clear {
            display: none;
        }
    }
}

.textInput--ovChipkaart {
    position: relative;
    width: toRem(240px);
    height: toRem(44px);

    @include respond-min($m) {
        width: toRem(303px);
    }
}

.textInput--fullWidth,
.textArea--fullWidth {
    width: 100%;
}

.textArea {
    @extend %textfield;

    height: auto;
    max-width: 100%;
}

.textArea--noBorder {
    @extend %textfield--noBorder;
}

// style placeholder color. somehow below selectors cannot be combined.
$placeholder-color: $ns-grey-40;

::placeholder {
    color: $placeholder-color;
    font-weight: normal;
}

// remove all webkit search decoration
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input[readonly] {
    @extend %textfield--readonly;
}
