/*
@name Promosticker
@description
The sticker component is used to hightlight some additional information like a promotion.
The sticker floats in the top right conner its container.
It's usually used on tile elements or in detail pages of the top block.
<br/>
Based on that you should use the correct container classes. When the sticker is part of a detail page use the classes `.container__sticker.promosticker`
for the containers. When the sticker is part of an tile use `.tile__sticker .tile__sticker--compact .promosticker`.<br/>
On the tiles the stickers are a slight smaller that is why we use the `.tile__sticker--compact` class.

<br/><br/>
<strong>Note:</strong><br/>
Both HTML examples are shown, use the correct one according the situation.

@section Components
@type UI
@extended false
@category Sticker

@markup
<div class="grid">
    <div class="grid__unit s-6-12">
        <div class="container__sticker promosticker">
            <span class="promosticker__text headingS">Nu eerste 3 maanden gratis</span>
        </div>
    </div>

    <div class="grid__unit s-4-4 m-6-12 l-4-12">
        <div class="tile tile--clickable">
            <div class="tile__header tile__header--image">
                <div class="tile__expander">
                    <picture>
                        <source srcset="<%= path.styleguideStatic %>/images/eropuit/mordor_372x160.jpg" media="(min-width: 0px)">
                        <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
                    </picture>
                </div>
            </div>
            <div class="tile__headerHolder">
                <div class="tile__title">
                    <h3 class="tile__heading headingM">Mordor</h3>
                </div>
            </div>

            <div class="tile__sticker tile__sticker--compact promosticker">
                <span class="promosticker__text">Gratis gids genaamd Gollum</span>
            </div>

            <div class="tile__content">
                <div class="tile__shortenedText tile__shortenedText--aanbieding">
                    <p>One Does Not Simply Walk Into Mordor</p>
                </div>
            </div>

            <div class="tile__footer">
                <p>
                    <a href="eropuit_aanbieding-detail.shtml" class="arrowLink" title="Bekijk dit uitje">Bekijk dit uitje</a>
                </p>
            </div>
        </div>
    </div>

</div>
*/

/*
For reference:
$tile-sticker-width: 130px;
$tile-sticker-height: 120px;
$tile-sticker-right: 15px;
$tile-sticker-top: -5px;
*/

$price-sticker-padding: 21px;
$price-sticker-padding-mobile: 14px;

.promosticker {
    background-color: $ns-yellow;
    padding: toRem($price-sticker-padding-mobile);

    @include respond-min($m) {
        padding: toRem($price-sticker-padding);
        // flex properties
        display: flex;
        align-items: center;
    }

    .promosticker__text {
        color: $ns-blue;
        // remove margin-bottom
        margin-bottom: 0;
        // set this, because the parent is flex
        display: block;
    }
}
