/*
@name Form Error Summary
@section Components
@type UI
@extended false
@className formErrorSummary

@description
Error Summary for Forms.<br />
**Note:** The error summary should on default be provided by a `.is-hidden` class.

@markup
<div class="tile tile--icon tile--alert tile--nsGrey8 tile--slim tile--noShadow formErrorSummary" aria-live="polite" role="alert" data-formElement="errorSummary">
    <div class="tile__title">
        <p class="formErrorSummary__intro"><span class="icon icon--alert icon--nsRed"><span class="alt">Foutmelding:</span></span>Er zijn één of meerdere velden niet juist ingevuld. Controleer uw gegevens en verstuur het formulier opnieuw.</p>
    </div>
    <div class="tile__content">
        <ul class="list redBullets formErrorSummary__list">
            <li>Voorletter(s)</li>
            <li>Roepnaam</li>
            <li>Nummer</li>
        </ul>
    </div>
</div>

*/

.formErrorSummary {
    @include focusOutline('&', 0, 0, 0, 0);

    margin-bottom: toRem(30px);
    color: $ns-blue;
}

.formErrorSummary__intro {
    position: relative;
    //aligns icon with text
    padding-top: toRem(3px);
}

.formErrorSummary__list {
    //Overrides specifity of tile__content > .list:first-child
    .tile__content > &:first-child {
        margin-top: toRem(30px);
    }
}
