/*
@name Notification Bar
@description
A component which is used to display a message to the user in case of an exception.

Examples of these exceptions:
* There is an interference of the normal train schedule
* The call centre is experiencing long waiting times

@section Components
@type UI
@extended false
@className notificationBar

@modifier .notificationBar--alert     - A message with high urgency
@modifier .notificationBar--info      - An information message
@modifier .notificationBar--ellipsis  - The text inside the notificationBar is cut off using ellipsis if the test won't fit the on one line.
@modifier .notificationBar--slim      - The notificationBar has a tighter padding on mobile.

@markup
<div class="container">
    <div class="notificationBar {{modifier_class}}">
        <span class="notificationBar__icon icon icon--info icon--nsWhite">
            <span class="alt">Let op:</span>
        </span>
        <span class="notificationBar__text">Dit is een mededeling die belangrijk en ernstig van aard kan zijn maar ook slechts informatief, soms overbodig maar meestal super-interessant.</span>
    </div>
</div>

*/

$notificationBar__padding: 10px;
$notificationBar__iconTop: $notificationBar__padding + ($line-height-base - $icon-size-base) * 0.5;

.notificationBar {
    position: relative;
    display: block;
    margin: 0;
    padding: toRem($notificationBar__padding) toRem($tile-space) toRem($notificationBar__padding) toRem($tile-space + $icon-size-base);
    box-shadow: inset 0 toRem(-1px) 0 0 rgba(0, 0, 0, 0.1);
    background-color: $ns-grey-20;
    color: $white;
}

a.notificationBar__link {
    font-weight: normal;
    transition: none;
    position: static;

    @include un-anchorlink;

    &:focus {
        outline-offset: toRem(5px);
    }
}

.notificationBar__text {
    color: $ns-white;
}

.notificationBar__icon {
    position: absolute;
    top: toRem($notificationBar__iconTop);
    left: toRem($tile-space - $icon-padding);
}

.notificationBar__arrow {
    position: relative;
    transition: transform 0.1s ease-out;
    transform: none;

    .notificationBar__link:hover & {
        transform: translateX(toRem(5px));
    }
}

// alert
.notificationBar--alert {
    background-color: $ns-red-alert;

    .notificationBar__text {
        color: $ns-white;
    }
}

// info
.notificationBar--info {
    background: $white;

    .notificationBar__text {
        color: $ns-blue;
    }
}

.notificationBar--slim {
    padding-left: toRem($tile-space * 0.5 + $icon-size-base);
    padding-right: toRem($tile-space * 0.5);

    @include respond-min($m) {
        padding-left: toRem($tile-space + $icon-size-base);
        padding-right: toRem($tile-space);
    }

    .notificationBar__icon {
        left: toRem($tile-space * 0.5 - $icon-padding);

        @include respond-min($m) {
            left: toRem($tile-space - $icon-padding);
        }
    }
}

.notificationBar--ellipsis {
    .notificationBar__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: top;
        font-weight: normal;
        max-width: 92%;
        max-width: calc(100% - #{toRem(15px)});
    }

    .notificationBar__arrow {
        position: absolute;
        top: toRem($notificationBar__iconTop);
        margin-right: toRem(-12px);
        margin-left: toRem(-$icon-padding);
    }
}
