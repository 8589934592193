/*
@name ActionBar
@description
A component that is used at the bottom of a form.

@section Components
@type UI
@extended false
@className actionBar

@modifier .actionBar--atArticleBottom     - Makes the actionbar overlap the standard article padding-bottom. If it needs to be placed at the bottom of an article.

@markup
<div class="actionBar {{modifier_class}}">
    <div class="actionBar__container">
        <a href="javascript:void(0)" class="backArrowLink backArrowLink--compact backArrowLink--button actionBar__item" data-formAction="previousPage">
            <span class="backArrowLink__text">Vorige stap</span>
        </a>
    </div>
    <div class="actionBar__container actionBar__container--right">
        <button type="submit" class="button button--arrowRight actionBar__item" data-formAction="processPage">
            <span data-buttonLabel="nextPage" class="">Volgende stap</span>
            <span data-buttonLabel="submit" class="is-hidden">Verzenden</span>
        </button>
    </div>
</div>

*/
.actionBar {
    @include separator-top;

    padding-top: toRem(15px);
    padding-bottom: toRem(15px);
    width: 100%;
    display: flex;
    justify-content: space-between;

    .no-flexbox & {
        display: table;
    }
}

// Makes the actionbar overlap the standard article padding-bottom.
// adjustst for both the variable padding and variable gutter in responsive behavior.
.actionBar--atArticleBottom {
    margin-bottom: - toRem($global--grid-quartergutter + $article-padding-bottom-default);

    @include respond-min($m) {
        margin-bottom: - toRem($global--grid-halfgutter + $article-padding-bottom-medium);
    }
}

.actionBar__container {
    flex-grow: 1;

    .no-flexbox & {
        display: table-cell;
    }
}

.actionBar__container--right {
    text-align: right;
}

.actionBar__item {
    &.is-hidden {
        display: none;
    }
}
