.darkener {
    position: fixed;
    min-height: 100%;
    overflow: hidden;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: transparentize($ns-black, 0.4);
    z-index: -1;
    cursor: pointer;
    backface-visibility: hidden; // performance
}

.darkener--header {
    z-index: z('header') - 1;
}

.darkener--planbar {
    z-index: z('header') + 1;

    @include respond-min($l) {
        z-index: z('header') - 2;
    }
}

.darkener--datepicker {
    z-index: z('header') + 1;
}
