/**
 * Visibility helpers
 */

.h-acc-hidden {
    @include visuallyhidden();
}

.h-acc-visible {
    @include show();
}

@each $breakpoint in map_keys($breakpoints) {
    .h-acc-visible-#{$breakpoint} {
        @include visuallyhidden();

        @include respond-min(#{map-get($breakpoints, $breakpoint)}) {
            @include show();
        }
    }
}

@each $breakpoint in map_keys($breakpoints) {
    .h-acc-hidden-#{$breakpoint} {
        @include show();

        @include respond-min(#{map-get($breakpoints, $breakpoint)}) {
            @include visuallyhidden();
        }
    }
}
