// ==========================================================================
// $VARIABLES
// ==========================================================================

// Make sure nothing is above the picker.
$picker-z-index-dropdown: z('header') - 1;
$picker-z-index-popup: z('header') + 2;

// Animation speeds.
$speed-animate-in: 0.15s;

// Focused input border color.
$input-active-border: $ns-blue;

// Typography.
$base-font-size: 16px;
$base-line-height: 1.2;

// The year and weekday labels.
$year-weekday-label: $ns-grey-60;

// Disabled things.. such as days, month nav, etc.
$disabled-things-bg: $ns-grey-94;
$disabled-things-text: $ns-grey-90;
$disabled-highlighted-things-bg: $ns-grey-70;

//
// Theme configurations
//

// The "default" min & max widths.
$picker-base-width: 300px;
$picker-table-width: 302px;
$picker-min-width: $picker-base-width;
$picker-max-width: $picker-base-width;

// The time picker min & max widths.
$time-min-width: $picker-min-width;
$time-max-width: toRem(320px);

// The "classic" theme settings.
$classic-max-width: $picker-max-width;
$classic-min-width: $picker-min-width;
$classic-max-height: 400px;
$classic-box-shadow: 0 toRem(6px) toRem(18px) toRem(1px) rgba(0, 0, 0, 0.12);

// ==========================================================================
// $MIXINS
// ==========================================================================

// Highlighted.
%picker-item-highlighted {
    border-color: $ns-blue;
}

// Hovered.
%picker-item-hovered {
    @extend %picker-item-selected;

    cursor: pointer;
}

// Selected.
%picker-item-selected {
    background-color: $ns-light-blue;
    color: $ns-white;
}
