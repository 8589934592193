/*
@name Form Search
@description
A search element that is used on page (e.g. Aanbiedingen detail). This script works out of the box as long as it has the correct classes.
The search form is also seen with a yellow background. This is usually used because it has a blue button, so keep this in mind when implementing.
The class for the yellow background is `.tile--nsYellow` and should be placed on the `.tile` level.

@section Objects
@type UI
@extended false
@category Form
@className searchForm

@markup
<form class="searchForm" action="/zoeken/search" method="GET">
    <label for="searchField" class="alt">Zoeken</label>
    <div class="searchForm__field">
        <input id="searchField" name="q" type="text" class="searchField" placeholder="Waar kunnen we u mee helpen?" autocomplete="off" />
    </div>
    <div class="searchForm__submit">
        <button type="submit" class="button">
            Zoeken
        </button>
    </div>
</form>
*/

.searchForm {
    display: table-row;
    position: relative;
    vertical-align: middle;
}

.searchForm .formfield--innerlabel .formfield__label {
    z-index: 102; //sets the label on top when autosuggest is used
}

.searchForm__field {
    display: block;
    padding-right: 0;
    margin-bottom: toRem($tile-space * 0.5);
    width: 100%;
    vertical-align: middle;

    $icon-space: toRem($icon-size-base + ($tile-space * 0.5) - 1px); // we have to move the icon 1px, requirement from Jerry

    .searchField {
        @extend .textInput; // TODO: add these classes to HTML and remove extends.
        @extend .textInput--focusBorderOnly; // TODO: add these classes to HTML and remove extends.
        @extend .textInput--search; // TODO: add these classes to HTML and remove extends.

        background-position: toRem($tile-space * 0.25);
        background-position-x: toRem(($tile-space * 0.25) - 1px); // we have to move the icon 1px, requirement from Jerry
        padding-left: $icon-space;
        padding-right: toRem($input-horizontal-space);
        border: transparent 1px solid;
    }

    &.has-input {
        input[type='search'] {
            padding-right: $icon-space;
        }

        .searchForm__clear {
            display: inline-block;
        }
    }

    @include respond-min($m) {
        display: table-cell;
        padding-right: toRem($global--grid-gutter);
        margin-bottom: 0;

        .header & {
            display: block;
            padding-right: 0;
        }
    }

    &.searchForm__field--full {
        display: block;
        padding-right: 0;
        margin-bottom: 0;
    }
}

.searchForm__clear {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 103;
}

.searchForm__submit {
    display: block;
    width: 100%;
    overflow: auto;

    .button {
        width: 100%;
    }

    @include respond-min($m) {
        display: table-cell;
        width: 100%;

        .button {
            width: auto;
        }
    }
}
