
// Mixin for icons.
@mixin icon {
    display: inline-block;
    background-size: contain; // not cover, as it may overflow
    background-repeat: no-repeat;
    background-position: center center;
    width: toRem($icon-size-base);
    height: toRem($icon-size-base);
}

// List which will be filled with all icon-color sets, like: alert--#FFFFFF, alert--#FF0000 etc.
$silentIcons: () !default;

/*
 * Create a default icon classes for $iconName and $colorName.
 *
 * Will output the standard selector set for your icon.
 */
@mixin createIconClass($iconName, $colorName: false){

    // change the iconName from kebab-case to camelCase
    $camelizedIconName: strKebabToCamel($iconName);

    @if ($colorName) {
        $colorCode: map-get($ns-icon-colors,$colorName);
        @if ($colorCode) {

            //deprecated
            .icon.icon--#{$iconName}--#{$colorName},
            .icon.icon--#{$iconName}.icon--#{$colorName},

            .icon.icon--#{$camelizedIconName}.icon--#{$colorName} {
                @include createIconBg($iconName, $colorCode);
            }
        }
    }
    @else {
        // deprecated
        .icon.icon--#{$iconName},

        .icon.icon--#{$camelizedIconName} {
            @include createIconBg($iconName);
        }
    }
}

@mixin createIconBg($iconName, $color: false){
    // we want each color to be defined in $ns-icon-colors, to prevent spamming our css (and thus the site) with too many colored icons
    $iconObj: getIconObj($iconName);

    @if ($iconObj) {
        $iconData: map-get($iconObj, 'data');
        $iconNormalizedName: map-get($iconObj, 'name');

        @if ($color) {
            $colorIndex: index($ns-icon-colorcodes, $color);
            @if $colorIndex {
                // let's create a var with the iconAndColorCombination
                $iconAndColorCombination: #{$iconNormalizedName}--#{$colorIndex};

                @if index($silentIcons, $iconAndColorCombination) {

                    // this iconAndColorCombination already exists, so we can extend it.
                    @extend %#{$iconAndColorCombination};

                } @else {

                    // the iconAndColorCombination is new, so we create a silent class for it.
                    @at-root %#{$iconAndColorCombination} {
                        // we create the (colorized) icon-data uri, and set it as a background-image
                        $colorizedIconData: colorizeEncodedSVG( $iconData, $color);
                        background-image: url($colorizedIconData);
                    }

                    // now that we have created the silent class, we can extend it
                    @extend %#{$iconAndColorCombination};

                    // and we add this iconAndColorCombination to the list of $silentIcons
                    $silentIcons: append($silentIcons, $iconAndColorCombination) !global;
                }
            } @else {
                @warn '#{$color} is not defined in $ns-icon-colors; either define it or change color';
            }
        } @else {
            // no color defined: use default icon-data-uri
            background-image: url($iconData);
        }
    } @else {
        @warn '#{$iconName} doesnt exist, either create it or correct iconName';
    }
}

@function getIconObj($iconName) {
    $data: map-get($all-icons, $iconName);
    $normalizedName: $iconName;
    $kebabName: null;

    @if ($data == null) {
        // if no icon data found, convert $iconName to kebab-case and try again:
        $kebabName: strCamelToKebab($iconName);

        $data: map-get($all-icons, $kebabName);
        $normalizedName: $kebabName;
    }

    @if ($data == null) {
        @warn 'No iconData found for #{$iconName}. There is no SVG named #{$iconName}.svg or #{$kebabName}.svg';
    }

    @return (
        'data': $data,
        'name': $normalizedName
    );
}

@function colorizeEncodedSVG($iconData, $color){

    @if $color {
        // convert color to a
        $colorString: inspect($color);

        // replace # in front of hex-color with unicode %23
        $colorUnicodeString : str-replace($colorString, '#', '%23');

        // below the string that contains the style-tag: <style type="text/css">circle, ellipse, line, path, polygon, polyline, rect, text { fill: red !important; }</style>
        $cssString : "%3Cstyle%20type%3D%22text%2Fcss%22%3Ecircle%2C%20ellipse%2C%20line%2C%20path%2C%20polygon%2C%20polyline%2C%20rect%2C%20text%20%7B%20fill%3A%20#{$colorUnicodeString}%3B%20%7D%3C%2Fstyle%3E";

        // insert style before closing  </svg>
        // $insertBefore: '%3Cg%3E'; // insert style after opening <g>
        $insertBefore: '%3C%2Fsvg%3E'; // insert style before closing  </svg>

        @if $iconData {

            // get the position of the closing svg-tag
            $insertIndex: str-index($iconData, $insertBefore);

            // if we have a closing svg-tag (if not, we have a faulty svg)
            @if $insertIndex {
                $insertIndex: $insertIndex;
                @return str-insert($iconData, $cssString, $insertIndex);
            } @else {
                @warn 'Error in colorizeEncodedSVG: Cannot find string index to insert colorization CSS.';
                @return $iconData;
            }
        }
        @else {
            @warn 'Error in colorizeEncodedSVG: No iconData given.';
            @return $iconData;
        }
    }
    @else {
        @warn 'Error in colorizeEncodedSVG: No color given.';
        @return $iconData;
    }
}
