/*
@name Rich Text
@description
Basic (user generated) text elements. It showcases the default behaviour of basic HTML-tags, like `p`, `strong`, `em`,
`a`, `ul`, `ol`, `li`, `blockquote`

@section Objects
@type UI
@extended false
@className richText

@markup
<div class="richText">
    <h1>HTML Ipsum Presents</h1>
    <p>
        This is a paragraph. <strong>I am strong</strong> senectus et netus et malesuada fames ac turpis egestas. Vestibulum
        tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. <em>I
        am em (emphasis).</em> Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum
        erat wisi, condimentum sed, <code>this is code</code>, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt
        condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. <a href="javascript:void(0)">I am a link</a> in turpis pulvinar
        facilisis. Ut felis.
    </p>
    <p>
        Here is the second paragraph ellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis
        egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam
        egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.
    </p>
    <h2>This is a h2, followed by an ordered list</h2>
    <ol>
       <li>List item in a ol, lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
       <li>Aliquam tincidunt mauris eu risus.</li>
    </ol>
    <h2>This is a h2, followed by an paragraph</h2>
    <p>
        Another paragraph, pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
        Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas
        semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.
    </p>
    <blockquote>
        <p>
            I'm a p inside a blockquote. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna.
            Cras in mi at felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur massa. Donec eleifend, libero at
            sagittis mollis, tellus est malesuada tellus, at luctus turpis elit sit amet quam. Vivamus pretium ornare est.
        </p>
    </blockquote>
    <h3>This is a h3 header, followed by a unordered list</h3>
    <ul>
       <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
       <li>Aliquam tincidunt mauris eu risus.</li>
    </ul>
    <p>
        Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor
        quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean
        ultricies mi vitae est. Mauris placerat eleifend leo.
    </p>
</div>
*/

$richtext-element-spacing: toRem(25px);

$richtext-header-bottom: toRem(20px);
$richtext-header-top: toRem(60px); //H2 top-margin & tabel bottom-margin
$richtext-header-top-mobile: $richtext-header-top - toRem(20px);

.richText {
    p,
    ul,
    ol {
        margin-bottom: $richtext-element-spacing;
    }

    h2 {
        margin-bottom: $richtext-header-bottom; // 20px
        margin-top: $richtext-header-top-mobile;

        @include respond-min($m) {
            margin-top: $richtext-header-top;
        }
    }

    h3 {
        margin-top: $richtext-element-spacing + toRem(5px); // 30px
        margin-bottom: $richtext-header-bottom * 0.5; // 10px
    }

    h2:first-child,
    h3:first-child {
        .bodyItem__text--left &,
        .bodyItem__text--right & {
            margin-top: 0;
        }
    }

    table {
        @extend %genericTableStyling;

        margin-bottom: 0;
    }

    .vcard .tel {
        @include un-anchorlink;

        color: inherit;
        display: inline;
    }

    &.grey {
        color: $ns-grey-60;
    }

    hr {
        @extend .articleSeparator;

        margin-right: 0;
        margin-left: 0;
    }

    li {
        @extend %listItem;
    }
}

.richText--overlay {
    h1,
    h2,
    h3,
    h4 {
        margin-top: 0;
        margin-bottom: toRem(10px);
    }
}

.richText--compactBottom {
    margin-bottom: 0;

    > :last-child {
        margin-bottom: 0;
    }
}

.richText--usp {
    ul {
        @extend .list--usp;

        &:first-child {
            margin-top: 0;
        }
    }
}

.richText--intro {
    p,
    ul,
    ol {
        @include font-size('intro-text');
    }
}
