@mixin iconLink ($settings: () ) {

    $button-height:         map-get( $settings, 'button-height' );
    $padding-left:          map-get( $settings, 'padding-left' );
    $icon-size:             map-get( $settings, 'icon-size' );
    $icon-x:                map-get( $settings, 'icon-x' );
    $icon-y:                map-get( $settings, 'icon-y' );
    $icon-hover-x:          map-get( $settings, 'icon-hover-x' );
    $icon-selector:         map-get( $settings, 'icon-selector');
    $icon-name:             map-get( $settings, 'icon-name');
    $color:                 map-get( $settings, 'color');
    $color-hover:           map-get( $settings, 'color-hover');
    $icon-selector:         map-get( $settings, 'icon-selector');
    $display:               map-get( $settings, 'display');

    $default-button-height: 25px;

    $button-height: $default-button-height  !default;
    $padding-left: 40px                     !default;
    $icon-size: $icon-size-base             !default;
    $icon-x: -8px                           !default;
    $icon-y: -6px                           !default;
    $icon-hover-x: -3px                     !default;
    $color: $ns-light-blue                  !default;
    $color-hover: $ns-blue                  !default;
    $icon-selector: ':before'               !default;
    $display: inline-block                  !default;

    @if ( $button-height != $default-button-height ) {
        $difference: $button-height - $default-button-height;
        $half-difference: $difference * 0.5;

        $icon-y: $icon-y + $half-difference;

        line-height: toRem($button-height);
    }

    @include un-anchorlink;

    @if ($icon-name) {
        @include iconLink__iconAndColor($icon-name, $color, $color-hover, $icon-selector);
    }

    display: $display;
    padding-left: toRem( $padding-left );
    position: relative;

    &#{$icon-selector} {
        @include icon();
        pointer-events: none;
        transition: left 0.1s ease-out, transform 0.1s ease-out;

        @if ($icon-size != $icon-size-base) {
            @include iconLink__iconSize($icon-size, ''); // no icon-selector
        }

        @if ($icon-selector == ':before') {
            content: '';
        }

        @if ( $icon-selector == ':after' ) {
            content: '';
            margin-bottom: toRem($icon-y); // ?
            transform: translateX($icon-x);
        }

        @if ($icon-selector != ':after') {
            position: absolute;
            top: toRem( $icon-y );
            left: toRem( $icon-x );

        }
    }

    @if($icon-hover-x != 0) {

        &:focus,
        &:hover {
            @if ($icon-selector == ':before') {
                &:before {
                    left: toRem($icon-hover-x);
                }
            }
            @if ( $icon-selector == ':after' ) {
                 &:after {
                    transform: translateX($icon-hover-x);
                }
            }
        }
    }

    /*
    &:focus {
        outline: none;
        text-decoration: underline;
    }
    */
}

@mixin iconLink__iconSize( $icon-size, $icon-selector: ':before' ) {

    #{$icon-selector} {
        height: toRem( $icon-size );
        width: toRem( $icon-size );
    }
}


@mixin iconLink__iconAndColor( $icon-name, $color: $ns-light-blue, $color-hover: $ns-blue, $icon-selector: ':before', $colorizeIcon: true ) {

    $iconColor: $color;
    $iconColorHover: $color-hover;

    @if ($colorizeIcon == false) {
        $iconColor: false;
        $iconColorHover: false;
    }

    color: $color;

    &#{$icon-selector} {
        @include createIconBg($icon-name, $iconColor);
    }

    &:hover {
        color: $color-hover;

        &#{$icon-selector} {
            @include createIconBg($icon-name, $iconColorHover);
        }
    }
}

%iconLink--default {
    @include iconLink();
}


%iconLink--listItem {
    @include iconLink( (
        'padding-left': 25px,
        'icon-x': -11px,
        'icon-y': -5px,
        'icon-hover-x': -6px
    ));
}

%iconLink--collapsible {
    @include iconLink( (
        'padding-left': 25px,
        'icon-x': -8px,
        'icon-y': -5px,
        'icon-hover-x': 0
    ));
}

%iconLink--listItem--small {
    @include iconLink( (
        'padding-left': 25px,
        'icon-x': -11px,
        'icon-y': -8px,
        'icon-hover-x': -6px
    ));
}

%iconLink--listItemRight {
     @include iconLink( (
        'padding-left': 25px,
        'icon-x': -11px,
        'icon-y': -5px,
        'icon-hover-x': -6px,
        'icon-selector': ':after'
    ));
}


%iconLink--chevronMedium {
    @include iconLink( (
        'icon-name': 'chevron-medium',
        'icon-x': -4px,
        'icon-hover-x': -8px,
        'padding-left': 31px
    ));
}
