.sortItems__desktop {
    display: none;

    @include respond-min($l) {
        display: block;
    }
}

.sortItems__mobile {
    @include clearfix;

    display: block;
    padding-bottom: toRem(12px);

    @include respond-min($l) {
        display: none;
    }
}

.sortItems__pickerLabel {
    display: block;
    color: $ns-blue;
    position: relative;

    @include textfield__lineheightfix();

    z-index: 1;
}

.sortItems__toolTip {
    position: absolute;
    background-position: toRem(7px);
    width: toRem($tooltip-width);
    z-index: 1;
    top: toRem(5px);
}

.sortItems__toolTipContainer {
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.sortItems__picker {
    width: 100%;
    margin-bottom: toRem(12px);

    @include respond-min($l) {
        margin-bottom: toRem(10px);
    }
}

.sortItems__caption {
    display: block;
    color: $ns-grey-60;
    font-size: toRem(14px);

    @include respond-min($l) {
        text-align: center;
        font-size: toRem($font-size-base);
        min-height: toRem(25px);
    }
}

.sortItems__heading {
    margin-top: toRem(20px);
    margin-bottom: 0;
    padding-bottom: toRem(10px);
    border-bottom: toRem(1px) solid $ns-grey-70;

    @include respond-min($m) {
        margin-left: toRem(15px);
        border-bottom: none;
    }
}

.sortItems__resultCount {
    font-weight: bold;
}

.sortItems__mismatch {
    margin-top: toRem($global--grid-gutter);

    &.is-hidden {
        display: none;
    }

    @include respond-min($m) {
        margin-top: 0;
    }
}
