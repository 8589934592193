/* ==========================================================================
   $BASE-PICKER
   ========================================================================== */

/**
 * Note: the root picker element should *NOT* be styled more than what’s here.
 */
.picker {
    width: toRem($picker-min-width);

    // The base font stylings.
    font-size: toRem($base-font-size);
    text-align: left;
    line-height: $base-line-height;
    color: $black;

    // The picker shouldn’t affect or be affected by elements around it.
    position: absolute;
    z-index: $picker-z-index-popup;

    @include respond-min($m) {
        z-index: $picker-z-index-dropdown;
    }

    // The picker shouldn’t be selectable.
    user-select: none;

    // When opened, show triangle.
    &.picker--opened {
        @include respond-min($m) {
            &::before,
            &::after {
                content: ' ';
                position: absolute;
                border-left: toRem(10px) solid transparent;
                border-right: toRem(10px) solid transparent;
                bottom: 100%;
                left: 50%;
                margin-left: toRem(-10px);
            }

            &::before {
                border-bottom: toRem(10px) solid $ns-grey-70;
            }

            &::after {
                border-bottom: toRem(10px) solid $ns-white;
                margin-bottom: toRem(-1px);
                z-index: 1;
            }
        }
    }
}

/**
 * The picker input element.
 */
.picker__input {
    cursor: default;
}

/**
 * When the picker is opened, the input element is "activated".
 */
.picker__input.picker__input--active {
    border-color: $input-active-border;
}

.picker__frame {
    padding: toRem(1px);
    width: 100%;
}

.picker__wrap {
    margin: toRem(-1px);
}

/**
 * The header containing the month and year stuff.
 */
.picker__header {
    position: relative;
    padding: toRem(10px);
    margin-top: toRem(28px);

    @include respond-min($m) {
        margin-top: 0;
    }
}
