$spacing-values: (
    'mt': 'margin-top',
    'ml': 'margin-left',
    'mb': 'margin-bottom',
    'mr': 'margin-right'
);

// We don't need all breakpoints
$spacing-breakpoints: (
    'sm': $sm,
    'm': $m,
    'ml': $ml,
    'l': $l,
    'xl': $xl
);

// How many spacing helpers do we want to render per defined breakpoint?
$spacing-total: 5;

// Spacing base
$spacing-base: $global--grid-quartergutter;

@mixin spacing-line($name, $properties, $index) {
    .h-#{$name}-#{$index} {
        @each $property in $properties {
            #{$property}: ($spacing-base * $index) !important;
        }
    }
}

@mixin spacings() {
    // Generate default helpers
    @each $spacing-name, $spacing-property in $spacing-values {
        @for $i from 0 through $spacing-total {
            @include spacing-line(#{$spacing-name}, $spacing-property, $i);
        }
    }

    // Generate responsive helpers
    @each $breakpoint-name, $breakpoint in $spacing-breakpoints {
        @include respond-min(#{$breakpoint}) {
            @each $spacing-name, $spacing-property in $spacing-values {
                @for $i from 0 through $spacing-total {
                    @include spacing-line(#{$spacing-name}-#{$breakpoint-name}, $spacing-property, $i);
                }
            }
        }
    }
}

// Render margin: 0 helper
@include spacing-line('m', 'margin', 0);

// Render all variants
@include spacings();
