.nslogo {
    padding-top: toRem(floor(($line-height-base - $global--logo-height) * 0.5));
}

.nslogo--footer {
    float: right;
}

.nslogo__link {
    @include un-anchorlink;

    display: block;
}

.nslogo__image {
    width: toRem($global--logo-width);
    height: toRem($global--logo-height);
    display: block;
}
