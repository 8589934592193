.picker__button--close {
    position: absolute;
    right: toRem(2px);
    top: toRem(2px);

    // hide button styling to make it act like an icon
    border: 0;
    padding: 0;
    background-color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;

    @include respond-min($m) {
        display: none;
    }
}
