/*
@name Filter
@description
The filter component is used in filter applications like Dagje Uit.
Its functionality can be found in the Angular 2 application.

@section Components
@type UI
@extended false

@markup
<div class="grid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12">
        <form class="filter ng-untouched ng-pristine ng-valid">
           <div class="filter__field">
              <ns-resetform><button class="button button--native-link">Wis alle filters</button></ns-resetform>
           </div>
           <ns-filter class="filter__field" id="afstanden">
              <fieldset>
                 <legend class="filter__fieldLegend headingXS">Afstand</legend>
                 <ul class="list formfield__inputGroup">
                    <li class="list__item filter__inputGroupItem ng-untouched ng-pristine ng-valid">
                        <ns-filtertype-checkbox>
                          <div class="checkbox checkbox--filter ng-untouched ng-pristine ng-valid">     <input class="checkbox__input ng-untouched ng-pristine ng-valid" type="checkbox" id="kleinerdan11">     <label class="checkbox__label" tabindex="-1" for="kleinerdan11">       Minder dan 10 km        <span class="filter__amount">(8)</span>     </label>    </div>
                        </ns-filtertype-checkbox>
                    </li>
                    <li class="list__item filter__inputGroupItem ng-untouched ng-pristine ng-valid">
                        <ns-filtertype-checkbox>
                            <div class="checkbox checkbox--filter ng-untouched ng-pristine ng-valid">     <input class="checkbox__input ng-untouched ng-pristine ng-valid" type="checkbox" id="van11tot17">     <label class="checkbox__label" tabindex="-1" for="van11tot17">       11 tot 16 km       <span class="filter__amount">(5)</span>     </label>    </div>
                        </ns-filtertype-checkbox>
                    </li>
                 </ul>
              </fieldset>
           </ns-filter>
           <ns-filter class="filter__field" id="soort">
              <fieldset>
                 <legend class="filter__fieldLegend headingXS">Soort wandeling</legend>
                 <ul class="list formfield__inputGroup">
                    <li class="list__item filter__inputGroupItem ng-untouched ng-pristine ng-valid">
                        <ns-filtertype-checkbox>
                            <div class="checkbox checkbox--filter ng-untouched ng-pristine ng-valid">     <input class="checkbox__input ng-untouched ng-pristine ng-valid" type="checkbox" id="duinenenstrand">     <label class="checkbox__label" tabindex="-1" for="duinenenstrand">       Duinen en strand       <span class="filter__amount">(5)</span>     </label>    </div>
                        </ns-filtertype-checkbox>
                    </li>
                    <li class="list__item filter__inputGroupItem ng-untouched ng-pristine ng-valid">
                        <ns-filtertype-checkbox>
                            <div class="checkbox checkbox--filter ng-untouched ng-pristine ng-valid">     <input class="checkbox__input ng-untouched ng-pristine ng-valid" type="checkbox" id="bos">     <label class="checkbox__label" tabindex="-1" for="bos">       Bos       <span class="filter__amount">(4)</span>     </label>    </div>
                        </ns-filtertype-checkbox>
                    </li>
                 </ul>
              </fieldset>
           </ns-filter>
        </form>
    </div>
</div>
*/

.filter__field + .filter__field {
    border-top: toRem(1px) solid $ns-grey-alpha-10;
    padding-top: toRem(22px);
    margin-top: toRem(13px);
    display: block; //for a non div/fieldset
}

.filter__amount {
    color: $ns-grey-20;
    position: absolute;
    top: toRem(3px); /* align with sibling line height */
    right: 0;

    @include fontsize('body-small');

    z-index: 0;
}

.filter__inputGroupItem {
    &:not(:last-child) {
        margin-bottom: toRem(15px);
    }
}

.filter__fieldLegend {
    margin-bottom: toRem(5px);
}

.filterPage__header {
    display: block;
    margin-bottom: toRem(40px);
}

.filterPage__intro {
    @include respond-min($ml) {
        background-size: auto;
        background-position: right bottom;
        background-repeat: no-repeat;
        padding-bottom: toRem(60px) - $richtext-element-spacing;
    }
}

.filterPage__intro--wandeling {
    @include respond-min($ml) {
        background-image: url('../images/dagjeuit_wandelingfilter.svg');
        min-height: toRem(240px);
    }
}

.filterPage__intro--aanbieding {
    @include respond-min($ml) {
        background-image: url('../images/dagjeuit_aanbiedingfilter.svg');
        min-height: toRem(240px);
    }
}

.filterLanding__intro {
    background-color: #fff;
    overflow: auto;

    @include respond-min($l) {
        display: block;
        background-image: url('../images/dagjeuit_wandelinglanding.svg');
        background-size: auto;
        background-position: right top;
        background-repeat: no-repeat;
        min-height: toRem(230px);
    }
}

.filter__buttonHolder {
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    pointer-events: none;
    display: none;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: toRem($tile-space * 0.5) 5%;
    z-index: z('overlay') + 1;
    max-width: toRem($max-overlay-width);
    min-width: toRem(320px - $global--grid-quartergutter * 2);

    @include respond-min($m) {
        left: toRem(14px);
        width: calc(100% - #{toRem(28px)});
        padding: toRem($tile-space * 0.5) 12%;
    }

    .overlay & {
        display: block;
    }

    &::before {
        display: block;
        bottom: toRem(78px);
        height: toRem(2 * $tile-space);
        background: linear-gradient(to bottom, rgba($white, 0) 0, rgba($white, 1) 100%);
        content: '';
        z-index: z('overlay') + 2;
        left: 2%;
        width: 100%;
    }

    &::after {
        bottom: 0;
        height: toRem(2.5 * $tile-space);
        content: '';
        position: absolute;
        z-index: -1;
        left: 4%;
        width: 91%;
        background: white;

        @include respond-min($m) {
            left: 12%;
            width: 76%;
        }
    }
}

.filter__overlayButton {
    width: 100%;
    position: relative;
    pointer-events: auto;
}
