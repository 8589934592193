/*
@name Tiles
@description A tile is a blockelement which is 'painted'. It would normally be used as the first direct decendant of a `.grid__unit', but it's not necessary.
It defines it's padding, it's shade and it's color (including gradients).
@section Components
@type UI
@extended false
@uses Button, Promosticker
@category tiles

@modifier .tile--nsLightBlue                          - NS-lightblue tile
@modifier .tile--nsBlue                               - NS-blue tile
@modifier .tile--nsYellow                             - NS-yellow tile
@modifier .tile--shadeYellow                          - Tile with a yellow and thicker shade at the bottom
@modifier .tile--gradient                             - A tile that is semi-transparent at the top. The color of the transparency is defined in the tile.
@modifier .tile--transparent                          - A tile that has a 20% transparent white (default) background.
@modifier .tile--nsBlue.tile--gradient                - A blue tile with gradient.
@modifier .tile--nsBlue.tile--transparent             - A tile with a 20% transparent blue background.
@modifier .tile--nsYellow.tile--gradientOnChild       - A yellow tile with a 'gap' that could/should contain a gradient which is on a child.
@modifier .tile--fullyTransparent                     - A completely transparent tile without a dropshadow.
@modifier .tile--linkOnly                             - A tile that will be collapsed on small devices. Everything except the title (including it's link) will be hidden on mobile, but shown from table-devices (breakpoint m)
@modifier .tile--sCompact                             - A tile where tile__header for the mobile version will be hidden
@modifier .tile--shrink                               - A tile that is NOT 100% height of it's parent, but only as high as it's content dictates
@modifier .tile--clickable                            - A tile where the whole tile is clickable, this will have some clean styling.
@modifier .tile--highlight                            - A tile that has a border left with a standard color and in mobile version the color changes to the top
@modifier .tile--padRight                             - A tile that is a introblock, where the title__content has a margin-right to inset the text for fine readabillity
@modifier .tile--banner                               - A tile that is a banner
@modifier .tile--shadow                               - A tile that has a shadow at the bottom of the tile
@modifier .tile--status                               - A tile that functions as a status tile with a link
@modifier .is-clickable                               - A pointer as cursor when hovering the element

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <!-- Start of element markup -->
    <div class="grid__unit s-4-4 m-6-12 l-4-12">
        <div class="tile {{modifier_class}}">
            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h2>NS Calling action</h2>
                </div>
                <div class="tile__footer">
                    <a href="javascript:void(0)" class="button button--arrowRight" title="Voorleestitel uit bannerinhoud in het CMS">
                        Call to Action
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
*/

// TO DO: COMPLETELY REFACTOR THIS COMPONENT!!! [HG]
// =================================================

/*
   the tile gradient creates a gradient at the top of the tile, so the underlying content (usually a hero-image) will show through.
   The color of the gradient is defined by the color of the tile itself. The height of the gradient is (will be) defined by how much
   stuff will overlap the hero image (to be determined).
*/
@mixin tile-gradient($color, $height: $hero-overlap--tablet--default, $margin: 20px) {
    $gradient-color: $color;
    $gradient-start: transparentize($color, 0.1);
    $gradient-end: transparentize($color, 0);

    &.tile--gradient {
        background: linear-gradient(to bottom, $gradient-start 0, $gradient-end toRem(($height - $margin)), $gradient-color 100%); /* W3C */
    }

    &.tile--gradientOnChild {
        background: linear-gradient(
            to bottom,
            transparent 0,
            transparent toRem($height),
            $gradient-color toRem(($height - $margin)),
            $gradient-color 100%
        ); /* W3C */
    }
}

/*
    the tile transparent creates a transparency with the given color, so the underlying content (usually a themeLabel) will show through.
    The color of the transparent is defined by the color of the tile itself.
*/
@mixin tile-transparent($color, $alpha: 0.8) {
    $transparent-color: rgba($color, $alpha);

    &.tile--transparent,
    &.tile--transparent--onChild {
        // Is this used?
        background: $transparent-color;
    }
}

/*
    With this mixin we define title__title margin-bottom to 5px. This mixin can be reused i.f.e. tile--linkOnly
*/
@mixin tile-title-margin-bottom() {
    .tile__title {
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: toRem($heading-margin-bottom); // reset to original value
        }
    }
}

$tile-border-space: 5px;

@mixin highlightStroke($small: 'top', $medium: 'left', $color: $ns-light-blue) {
    position: relative;

    &::before {
        content: '';
        border-#{$small}: toRem($tile-border-space) solid $color;

        // add conditional to check if we have set the border to top
        @if $small == 'top' or $small == 'bottom' {
            height: toRem($tile-border-space);
            width: 100%;
        } @else {
            width: toRem($tile-border-space);
            height: 100%;
        }

        @include respond-min($m) {
            border: none;
            border-#{$medium}: toRem($tile-border-space) solid $color;

            // add conditional to check if we have set the border to top
            @if $medium == 'top' or $medium == 'bottom' {
                height: toRem($tile-border-space);
                width: 100%;
            } @else {
                width: toRem($tile-border-space);
                height: 100%;
            }
        }

        position: absolute;
        top: 0;
        left: 0;
    }
}

/*
    Adds an arrow on the right that also animates at hover. Set to true if it only needs to show on mobile. Otherwise set to false.
*/
@mixin tileArrowRight($mobileonly, $containerSelector: '') {
    &#{$containerSelector} {
        padding-right: toRem($tile-space * 2);
    }

    &::after {
        @extend %icon;

        @include createIconBg('chevron-medium', $ns-light-blue);

        content: '';
        height: 100%;
        left: auto;
        position: absolute;
        right: toRem(($tile-space * 0.5) + 3px);
        top: 0;
        transition: right 0.1s ease-out;
    }

    &:hover {
        &::after {
            right: toRem(($tile-space * 0.5) - $tile-border-space);
        }
    }

    z-index: 2; // fix clickability: CMS-3655

    @if $mobileonly == true {
        @include respond-min($m) {
            &#{$containerSelector} {
                padding-right: toRem($tile-space);
            }

            &::after {
                content: none;
            }
        }
    }
}

.tile {
    $tile-color: $white;

    @include ns-box-shadow(0.15);
    @include tile-gradient($tile-color, $hero-overlap--mobile--default);
    @include tile-transparent($tile-color);

    background-color: $tile-color;
    border-collapse: initial;
    border-spacing: initial;
    display: table;
    table-layout: fixed;
    height: 100%;
    padding: toRem($tile-space);
    position: relative;
    width: 100%;

    // remove bottom margin for only-child paragraphs.
    p {
        //:first-child {
        &:last-child {
            margin-bottom: 0;
        }
    }

    @include respond-min($m) {
        @include tile-gradient($tile-color, $hero-overlap--tablet--default);
    }

    &.tile--smallGradient {
        @include tile-gradient($tile-color, $hero-overlap--mobile--homepage);

        @include respond-min($m) {
            @include tile-gradient($tile-color, $hero-overlap--tablet--homepage);
        }
    }

    // Breadcrumb styling
    .breadcrumb {
        height: toRem(1px);
        display: table-row;
    }

    .grid--touch & {
        padding: toRem(18px) toRem(15px) toRem(28px) toRem(15px);
        border-top: toRem(1px) solid $ns-grey-70;
        box-shadow: none;
        outline-color: transparent;
        transition: outline-color 0.5s ease;

        &:hover {
            z-index: z('header') - 1;
            box-shadow: none;
            outline: toRem(1px) solid $ns-grey-70;
        }

        @include respond-min($m) {
            border: none;
        }
    }
}

// we set the margin-bottom for the tile__title headings to 5px
@include respond-min($m) {
    @include tile-title-margin-bottom();
}

.tile--nsLightBlue {
    $tile-color: $ns-light-blue;
    $text-color: $white;

    @include tile-gradient($tile-color);
    @include tile-transparent($tile-color);

    background-color: $tile-color;

    @include respond-min($m) {
        @include tile-gradient($tile-color, $hero-overlap--tablet--default);
    }

    &.tile--smallGradient {
        @include tile-gradient($tile-color, $hero-overlap--mobile--homepage);

        @include respond-min($m) {
            @include tile-gradient($tile-color, $hero-overlap--tablet--homepage);
        }
    }

    &,
    .tile__heading {
        color: $text-color;
    }
}

.tile--nsBlue {
    $tile-color: $ns-blue;
    $text-color: $white;

    @include tile-gradient($tile-color);
    @include tile-transparent($tile-color);

    background-color: $tile-color;
    color: $text-color;

    @include respond-min($m) {
        @include tile-gradient($tile-color, $hero-overlap--tablet--default);
    }

    &.tile--smallGradient {
        @include tile-gradient($tile-color, $hero-overlap--mobile--homepage);

        @include respond-min($m) {
            @include tile-gradient($tile-color, $hero-overlap--tablet--homepage);
        }
    }

    .tile__heading {
        color: $text-color;
    }

    .arrowLink {
        // yes?
        color: $text-color;
    }
}

.tile--ns-yellow, // DEPRECATED! [07mar19 nog in gebruik]
.tile--nsYellow {
    $tile-color: $ns-yellow;
    $text-color: $ns-blue;

    @include tile-gradient($tile-color);
    @include tile-transparent($tile-color);

    background-color: $tile-color;
    color: $text-color;

    @include respond-min($m) {
        @include tile-gradient($tile-color, $hero-overlap--tablet--default);
    }

    &.tile--smallGradient {
        @include tile-gradient($tile-color, $hero-overlap--mobile--homepage);

        @include respond-min($m) {
            @include tile-gradient($tile-color, $hero-overlap--tablet--homepage);
        }
    }
}

.tile--nsGrey8 {
    background-color: $ns-grey-94;
}

//Size and position for the icon large
.tile--tilegraphic {
    $tile_graphic_top: -28px;
    $tile_graphic_right: -14px;

    .tile__heading {
        padding-right: toRem($tilegraphic-size-base - 10px);
        margin-bottom: toRem(20px); // extra margin from Jerry's VD.
    }

    .tile__graphic {
        position: absolute;
        top: toRem($tile_graphic_top);
        right: toRem($tile_graphic_right - 2px);

        @include respond-min($m) {
            right: toRem($tile_graphic_right);
        }
    }
}

.tile__iconHeading {
    padding-bottom: toRem(16px);
    border-bottom: toRem(1px) solid $ns-blue;
    font-size: toRem(18px);
    font-weight: bold;

    &.headingXS {
        line-height: toRem(45px);
    }
}

.tile__backgroundimage, // deprecated! [07mar19 nog in gebruik]
.tile__backgroundImage {
    bottom: 0;
    display: block;
    left: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;

    // WTF: any next sibling of .tile__backgroundImage and of that the direct descendants will need to be positioned above the background image
    & ~ * > * {
        position: relative;
        z-index: 15;
    }

    picture {
        margin: 0;
        padding: 0;
        border: 0;
        overflow: hidden;

        img {
            margin: 0;
            padding: 0;
            border: 0;
            overflow: hidden;
            display: block;
        }
    }
}

.tile__header,
.tile__title,
.tile__content,
.tile__footer {
    display: table-row;
}

.tile__header--mobileVisuallyHidden {
    @include visuallyhidden;

    @include respond-min($m) {
        @include show;
    }
}

.tile__content {
    height: 100%;
}

.tile__content--compact {
    p {
        margin-bottom: 0;
    }
}

.tile__content--intro {
    p,
    ol,
    ul {
        @include font-size('intro-text');
    }
}

.tile__title,
.tile__footer,
.tile__header {
    height: toRem(1px); // faux min-height
}

.tile__footer {
    // TODO: this is a weird selector and must be re-evaluated.
    // >> This is not the only weird selector around here, my friend ;) [HG]
    // the purpose of below selector is to create some space between
    // the first footer element and the content above.
    // .buttonGroup in itself wishes even more space but its selector
    // is has a lower specificity.
    > :first-child:not(.buttonGroup) {
        margin-top: toRem(10px); // default vertical rhythm value.
    }

    @include respond-min($m) {
        > .button,
        > .buttonGroup {
            // negatieve bottom margin zorgt ervoor dat de tekst in de button
            // verticaal is uitgelijnd met arrowLinks in boxes ernaast.
            margin-bottom: toRem(($line-height-base - $button-height) * 0.5);
        }
    }
}

.tile__specs {
    margin-bottom: toRem(10px);
}

//A container if the order of the elements visually needs to be in a different order due to accessibility
.tile__headerHolder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .tile__title {
        order: 2;
        height: auto;
        //IE fix
        display: table;
    }

    .tile__specs {
        margin-bottom: toRem(10px);

        @extend %tile__subTitle;
    }

    .tile__label {
        margin-bottom: toRem(7px);
        font-weight: bold;
        color: $ns-blue;

        @extend %tile__subTitle;
    }
}

%tile__subTitle {
    order: 1;

    &,
    p {
        font-size: toRem(14px);
    }
}

@include respond-min($m) {
    .tile__content .tile__shortenedText {
        overflow: hidden;
        min-height: toRem($line-height-base * 8);
        max-height: toRem($line-height-base * 8);
    }

    .tile__content > .list {
        &:first-child {
            margin-top: toRem($heading-margin-bottom);
        }

        &.list--topExemption {
            //eliminates margin-top for lists like list--divided
            margin-top: 0;
        }
    }
}

.tile__header ~ .tile__content .tile__shortenedText {
    min-height: toRem($line-height-base * 2);
    max-height: toRem($line-height-base * 2);
    overflow: hidden;

    &.tile__shortenedText--aanbieding {
        min-height: toRem($line-height-base);
        max-height: toRem($line-height-base * 3);
    }
}

.tile--mediumAndUp {
    display: none;

    @include respond-min($m) {
        display: table;
    }
}

.tile--smallOnly {
    display: block;

    @include respond-min($m) {
        display: none;
    }
}

.tile__headingLink {
    @include un-anchorlink;
    @include fontsize('M');

    color: $ns-light-blue;
}

.tile--linkOnly {
    // for mobile, the tile is visibly reduced to only the tile__header link which is clickable.
    // in order to have the entire tile clickable, the padding on the tile must be applied to
    // the .tile__link element instead of the .tile element.
    padding: 0;

    .tile__header,
    .tile__content,
    .tile__footer {
        display: none;
    }

    .tile__title {
        display: block;
        height: auto;
    }

    .tile__heading {
        margin-bottom: 0;
    }

    &.tile--clickable,
    .tile__titleLink {
        padding: toRem($tile-space);

        // this overrides padding-right so it must be AFTER the generic padding statement.
        @include tileArrowRight(true);

        color: $ns-light-blue;
        display: block;
        position: relative;
        z-index: 2;
    }

    // reset for tablet+ :
    @include respond-min($m) {
        padding: toRem($tile-space);

        &.tile--clickable {
            display: table;
        }

        .tile__header,
        .tile__title,
        .tile__content,
        .tile__footer {
            display: table-row;
        }

        .tile__title {
            height: toRem(1px);
        }

        .tile__heading {
            margin-bottom: toRem(10px);
        }

        .tile__titleLink {
            padding: 0;
            color: $ns-blue;
            display: inherit;
        }

        .tile__content {
            color: $body-color;
        }

        @include tile-title-margin-bottom();
    }
}

.tile--sCompact {
    // TODO: refactor. is this the best name?
    .tile__header {
        display: none;

        @include respond-min($m) {
            display: table-row;
        }
    }
}

.tile--shrink {
    height: auto;
}

.tile--clickable {
    @extend %link-reset;

    color: inherit;

    &:hover {
        @include ns-box-shadow(0.15);
    }

    @include respond-min($m) {
        &::after {
            content: none;
        }
    }

    .arrowLink {
        &:hover {
            color: $ns-blue;
        }

        color: $ns-light-blue;
    }

    .tile__footer {
        .arrowLink {
            margin-top: toRem(10px);
        }
    }

    &.tile--linkOnly {
        .tile__title {
            h2,
            .h2,
            h3,
            .h3,
            h4,
            .h4,
            h5,
            .h5,
            h6,
            .h6 {
                color: $ns-light-blue;

                @include respond-min($m) {
                    color: $ns-blue;
                }
            }
        }
    }
}

.tile--highlight {
    @include highlightStroke();
}

/*
Some extra tile variations.

Tiles can be made more compact on mobile devices, make room for an icon in the heading or an arrow on the right, add an alert highlight, or combinations of these.

Markup:
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 l-12-12">
        <div class="tile {{modifier_class}}">
            <div class="tile__title">
                <h3 class="headingM">
                    <span class="icon icon--nsRedAlert icon--alert">
                        <span class="alt">Let op:</span>
                    </span>
                    Vlissingen-Kruiningen-Yrseke
                </h3>
            </div>
            <div class="tile__content">
                In de nacht van vrijdag 20 op zaterdag 21 oktober vanaf 00:00 tot en met maandag 23 oktober 06:00 uur.
            </div>
        </div>
    </div>
</div>

.tile--icon                                 - Creates extra padding on the left make room for the icon in the heading.
.tile--arrowRight                           - Adds an chevron to the right of the tile.
.tile--compact                              - Makes the tile compact (less padding) on mobile
.tile--alert                                - Adds a red alert highlight to the left
.tile--icon.tile--arrowRight.tile--compact.tile--alert  - Combines all the of the above.
.tile--slim                                 - 0 bottom to fit the Storingen en werkzaamheden on homepage

// Styleguide 3.2.4
*/

/*
Some tiles needs it's content to be compact

Markup:
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 l-12-12">
        <div class="tile">
            <div class="tile__title">
                <h3 class="headingM">
                    <span class="icon icon--nsRedAlert icon--alert">
                        <span class="alt">Let op:</span>
                    </span>
                    Vlissingen-Kruiningen-Yrseke
                </h3>
            </div>
            <div class="tile__content tile__content--compact">
                <p>In de nacht van vrijdag 20 op zaterdag 21 oktober vanaf 00:00 tot en met maandag 23 oktober 06:00 uur.</p>
                <p>Beperkt treinverkeer op dit traject. NS zet bussen in.</p>
            </div>
        </div>
    </div>
</div>

// Styleguide 3.2.6
*/

.tile--compact {
    // kleinere padding
    padding-top: toRem($tile-space * 0.5);
    padding-bottom: toRem($tile-space * 0.5);

    @include respond-min($m) {
        padding: toRem($tile-space);
    }
}

.tile--slim {
    //0 padding bottom fit the Storingen en werkzaamheden on homepage
    padding: toRem($tile-space * 0.5);

    @include respond-min($m) {
        padding: toRem($tile-space);
    }
}

.tile--noBottom {
    padding-bottom: 0;
}

//For anything that needs to be put in the tilefooter that needs to be 45px high.
.tile__footerContent {
    min-height: toRem(45px);
    padding: toRem(5px) 0;
}

.tile--icon {
    // extra padding links
    // align met icon op actuele situatie op spoor
    padding-left: toRem(55px);

    // positioneren van icon
    .tile__title {
        .icon {
            // is relatief aan heading.
            position: absolute;
            top: toRem(-5px);
            left: toRem(-($icon-size-base + 2px));

            @include respond-min($m) {
                top: toRem(-3px);
                left: toRem(-($icon-size-base + $icon-padding));
            }
        }
    }
}

.tile--alert {
    @include highlightStroke('left', 'left', $ns-red-alert);
}

.tile--arrowRight {
    @include tileArrowRight(false);
}

.tile--arrowRightMobile {
    @include tileArrowRight(true);
}

@include respond-min($m) {
    .tile--padRight {
        padding-right: toRem($tile-space + 88px);
    }
}

.tile--shadow {
    @include ns-box-shadow(0.15);
}

.tile--noShadow {
    box-shadow: none;
}

.is-clickable {
    cursor: pointer;
}

/*
Tiles: content elements

Below are some tiles with content and some extra's. The tiles now live in their 'natural' habitat: the grid-system.
We can now see how the tile's content-div behave relative to each other and their neighbours.
The tile's child-elements are all optional:

* `.tile__header`: is always stuck to the top of the tile.
* `.tile__title`: contains the tile's title. Is the only element that remains visible in  `.tile--linkOnly`-tiles
* `.tile__content`: contains the main content. This 'row' is the _faux-flex-row_: it stretches to fill up remaining space within the tile
* `.tile__footer`: contains the footer of the tile. This tile always sticks to the end of the tile.
* `.tile__backgroundImage` : contains the background image, see below

Markup:
<div class="grid"  data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit  s-4-4 m-12-12 l-4-12">
        <div class="tile tile--shade--yellow">
            <div class="tile__title">
                <h2>Title, just a h-tag</h2>
            </div>
            <div class="tile__content">
                <p>This is content over two<br>lines</p>
                <p>Since that is more lines than my neighbour, my neighbour will 'borrow' my height.</p>
                <p>Furthermore, I have no tile__header!</p>
                <p>AND: if have a yellow shade!</p>
            </div>
            <div class="tile__footer">
                <a href="" class="link arrowLink">I'm always at the bottom</a>
            </div>
        </div>
    </div>
    <div class="grid__unit  s-4-4 m-12-12 l-4-12">
        <div class="tile">
            <div class="tile__header">
                <div class="tile__expander">
                    I am a header expander, meaning I will 'undo' the padding of my parent-tile. Both left and right and top.
                </div>
            </div>
            <div class="tile__title">
                <h2>I'm a tile with a header.</h2>
            </div>
            <div class="tile__content">
                <p>The .tile__header__expander would usually be used for an image --or a warning</p>
                <p>I have no footer!</p>
            </div>
        </div>
    </div>
    <div class="grid__unit  s-4-4 m-12-12 l-4-12">
        <div class="tile tile--linkOnly">
            <div class="tile__header">
                <div class="tile__expander">
                    Normally I would be an image; hidden on mobile.
                </div>
            </div>
            <div class="tile__title">
                <h2><a href="javascript:void(0)">I will be the link on mobile</a></h2>
            </div>
            <div class="tile__content">
                <p>This is a .tile--linkOnly, meanding my tile__header, .tile__content and .tile__footer will be hidden on mobile.</p>
                <p>All that remains is .tile__title, which should contain a link</p>
            </div>
            <div class="tile__footer">
                <a href="" class="link arrowLink">Even I will be hidden on mobile</a>
            </div>
        </div>
    </div>
    <div class="grid__unit m-12-12 l-6-12">
        <a href="thema.shtml" class="tile tile--link-disruption tile--arrowRight">
            <div class="tile__title">
                <h3>Moon Base-Colony 3</h3>
            </div>
            <div class="tile__content">
                <span class="icon"></span>
                <p class="tile__description">Orbital elevator jammed. Disrupted from monday May 30th 6:00am till 8:00am. </p>
            </div>
        </a>
    </div>
</div>

// Styleguide 3.2.1
*/

/*
Tiles: content child-elements

Besides the direct children of a tile, we have some extra modifier elements (children of children), most notably:

* `.tile__verticalMiddle`: Aligns it's content in the middle of the element. Would usually live inside a `tile__content`, because this can have a forced height. Mostly (only?) used in `.cms-BannerBlock`.
* `.tile__verticalBottom`: As above, but aligns to the bottom
* `.tile__verticalLower-l`: Aligns (on breakpoint l) in the direction of the middle. The space above the bottom is arbitrary.

Markup:
<div class="grid"  data-controller="generic/ui/ResponsiveGrid"  data-config="{&quot;allEqual&quot;: true}">
    <!-- Start of element markup -->
    <div class="grid__unit  s-4-4 m-6-12 l-4-12">
        <div class="tile">
            <div class="tile__content">
                <div class="tile__verticalMiddle">
                    <p>I will align in the middle of the tile</p>
                </div>
            </div>
        </div>
    </div>
    <!-- End of element markup -->
    <!-- Start of element markup -->
    <div class="grid__unit  s-4-4 m-6-12 l-4-12">
        <div class="tile tile--linkOnly">
            <div class="tile__header">
                I'm a header
            </div>
            <div class="tile__title">
                <h2><a href="javascript:void(0)">I will be the link on mobile</a></h2>
            </div>
            <div class="tile__content">
                <p>I show off the tile__expander class. Besides that I create some height, so the alignment-classes will show.</p>
            </div>
            <div class="tile__footer">
                I'm a footer
            </div>
        </div>
    </div>
    <!-- End of element markup -->
    <!-- Start of element markup -->
    <div class="grid__unit  s-4-4 m-6-12 l-4-12"  data-responsivegrid-height-l="2.0" style="float:right">
        <div class="tile tile--linkOnly">
            <div class="tile__content">
                <div class="tile__verticalLower-l">
                    <p>I will align bottom-ish of the tile</p>
                    <p>Either I or my neighbours should be high enough to make this visible</p>
                    <p>This tile is made higher by setting 'data-responsivegrid-height', to make the alignment obvious.</p>
                </div>
            </div>
        </div>
    </div>
    <!-- End of element markup -->
    <!-- Start of element markup -->
    <div class="grid__unit  s-4-4 m-6-12 l-4-12">
        <div class="tile">
            <div class="tile__content">
                <div class="tile__verticalBottom">
                    <p>I will align to the bottom of the tile</p>
                </div>
            </div>
        </div>
    </div>
    <!-- End of element markup -->
    <!-- Start of element markup -->
    <div class="grid__unit  s-4-4 m-12-6 l-4-12">
        <div class="tile tile--linkOnly">
            <div class="tile__title">
                I'm the title
            </div>
            <div class="tile__content">
                <p>I'm just some content.</p>
            </div>
        </div>
    </div>
    <!-- End of element markup -->
</div>

// Styleguide 3.2.2
*/

/*
Tiles: content expanders

Besides the direct children of a tile, we have some extra modifier elements (children of children), most notably:

* `.tile__expander`: always lives in any of the `tile__XXXX` elements. It's used to stretch beyond the horizontal padding from the tile. When used in tile__header, it also stretches the tile's top-padding. (See 'Tiles: content elements' for examples).

Markup:
<div class="grid"  data-controller="generic/ui/ResponsiveGrid"  data-config="{&quot;allEqual&quot;: true}">
    <div class="grid__unit  s-4-4 m-6-12 l-4-12">
        <div class="tile">
            <div class="tile__header">
                <!-- Start of element markup -->
                <div class="kss--bg tile__expander">
                    Normally I would be an image; hidden on mobile. I expand beyond the tile's padding.
                </div>
                <!-- End of element markup -->
            </div>
            <div class="tile__title">
                <h2><a href="javascript:void(0)">I will be the link on mobile</a></h2>
            </div>
            <div class="tile__content">
                <p>I show off the tile__expander class. Besides that I create some height, so the alignment-classes will show.</p>
            </div>
            <div class="tile__footer">
                Just a footer
            </div>
        </div>
    </div>
    <div class="grid__unit  s-4-4 m-6-12 l-4-12">
        <div class="tile">
            <div class="tile__content">
                <div class="tile__verticalBottom">
                    <p>I will align to the bottom of the tile</p>
                </div>
            </div>
            <div class="tile__footer">
                <!-- Start of element markup -->
                <div class="kss--bg tile__expander">
                    I have lots of content, content content, this is enormous, hugh. What is the matter, it's too bad.I have lots of content, content content, this is enormous, hugh. What is the matter, it's too bad.
                </div>
                <!-- End of element markup -->
            </div>
        </div>
    </div>
    <div class="grid__unit  s-4-4 m-12-6 l-4-12">
        <div class="tile tile--linkOnly">
            <div class="tile__title">
                I am title
            </div>
            <div class="tile__content">
                <!-- Start of element markup -->
                <div class="kss--bg tile__expander">
                    I stretch left and right.
                </div>
                <!-- End of element markup -->
            </div>
        </div>
    </div>
</div>

// Styleguide 3.2.3
*/

$tile-ImgHeight: 140px;

%tile__expander,
.tile__expander {
    margin-left: toRem(-$tile-space);
    margin-right: toRem(-$tile-space);
    //min-height: toRem($tile-space*1.5);
    position: relative;
    vertical-align: bottom;

    .tile__header & {
        position: relative;
        top: toRem(-$tile-space);
        margin-bottom: toRem(-$tile-space * 0.5);
    }

    .tile__header--image & {
        height: toRem($tile-ImgHeight);
        overflow: hidden;
        margin-bottom: toRem(-$tile-space * 0.25 - 2px); // = -10px

        // below rules are to make IE10/IE11 behave :S
        line-height: 4;

        picture {
            display: inline-block;
        }
    }

    .tile__footer & {
        position: relative;
        bottom: toRem(-$tile-space); // margin-bottom won't work
        margin-top: 0; //toRem(-$tile-space);
    }
}

.tile--addSpace {
    padding: toRem($tile-space);
}

.tile--removeSpace {
    padding: 0;
}

/*
   .tile__verticalMiddle and  .tile__verticalBottom will have hardly any effect on mobile devices, because they will have no forced height.
   .tile__verticalLower-l will default to whatever you set (.tile__verticalMiddle o  .tile__verticalBottom), but the 'lower'-styling will kick in at breakpoint l.
*/
.tile__verticalMiddle {
    display: table-cell;
    vertical-align: middle;
}

.tile__verticalBottom {
    display: table-cell;
    vertical-align: bottom;
}

.tile__verticalLower {
    display: table-cell;
    vertical-align: bottom;
    padding-bottom: 10%;
}

.tile__verticalLower--l {
    display: table-cell;

    @include respond-min($l) {
        vertical-align: bottom;
        padding-bottom: 30%; // arbitrary
    }
}

//Place holder for promosticker
$tile-sticker-width: 130px;
$tile-sticker-height: 120px;
$tile-sticker-right: 15px;
$tile-sticker-top: -5px;

$tile-sticker-compact-width: 112px;
$tile-sticker-compact-height: 105px;

//Adds padding-right to title and content children prevent promosticker to cover it.
//Used padding right because width doesn't do anything because it's a table-row
.tile--stickerNextToText {
    .tile__content > *,
    .tile__title > * {
        @include respond-min($m) {
            padding-right: toRem($tile-sticker-width);
        }
    }

    //needs to hide on mobile to make way for text
    .tile__sticker {
        display: none;

        @include respond-min($m) {
            display: flex;
        }
    }
}

//Size and position for the promosticker
.tile__sticker {
    position: absolute;
    z-index: 1;
    top: toRem($tile-sticker-top * 2);
    height: toRem($tile-sticker-height);
    width: toRem($tile-sticker-width);
    right: toRem($tile-sticker-right);
}

.tile__sticker--compact {
    width: toRem($tile-sticker-compact-width);
    height: toRem($tile-sticker-compact-height);
}

/*
Tiles: with a backgroundimage

A tile with a backgroundimage.

Markup:
<div class="grid"  data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit  s-4-4 m-12-12 l-4-12">
        <div class="tile tile--banner">
            <div class="tile__backgroundImage tile__gradientTopDown">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/klantenservice/banner_thema_371x713.jpg" media="(min-width: <%= breakpoints.large %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/klantenservice/banner_thema_371x380.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/klantenservice/banner_thema_500x260.jpg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Description of image" class="img--contain">
                </picture>
            </div>
            <div class="tile__content">
                <p>This is normal content. It gets lifted above the image with a magic selector.</p>
            </div>
        </div>
    </div>
    <div class="grid__unit  s-4-4 m-12-12 l-4-12">
        <div class="tile tile--banner">
            <div class="tile__backgroundImage tile__gradientTopDown">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/klantenservice/banner_thema_371x713.jpg" media="(min-width: <%= breakpoints.large %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/klantenservice/banner_thema_371x380.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/klantenservice/banner_thema_500x260.jpg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Description of image" class="img--contain">
                </picture>
            </div>
            <div class="tile__content">
                <p>
                    Nieuwe tarieven <br /><strong>Deur tot Deur</strong>
                </p>
                <a href="javascript:void(0)" class="button button--secondary">
                    Bekijk de details
                </a>
            </div>
        </div>
    </div>
</div>

// Styleguide 3.2.5
*/

.tile--productUsp {
    display: none;

    .pricedetails {
        margin-bottom: toRem(10px);
    }

    @include respond-min($m) {
        display: table;
    }
}
