// --------------------------------------------------
// apply a natural box layout model to all elements
// --------------------------------------------------

@use 'sass:math';

*,
*::before,
*::after {
    box-sizing: border-box;
}

// --------------------------------------------------
//  Container setup
// --------------------------------------------------

%container {
    position: relative;
    max-width: toRem($global--container-content-width);
    margin-left: toRem($global--grid-quartergutter);
    margin-right: toRem($global--grid-quartergutter);

    .grid {
        margin-left: toRem(-$global--grid-quartergutter);
        margin-right: toRem(-$global--grid-quartergutter);
    }

    > .grid {
        .overlay & {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.has-expanded-rp-options {
        z-index: 150;
    }

    @include respond-min($m) {
        margin-left: toRem($global--grid-halfgutter);
        margin-right: toRem($global--grid-halfgutter);

        .grid {
            margin-left: toRem(-$global--grid-halfgutter);
            margin-right: toRem(-$global--grid-halfgutter);
        }
    }

    @include respond-min($xxl) {
        margin-left: auto;
        margin-right: auto;
    }
}

// default container
.container {
    @extend %container;

    @include print {
        width: 100%;
    }

    .nessie .footer & {
        max-width: $nes-container-width;
        padding-left: calc(6 * $nes-base-step);
        padding-right: calc(6 * $nes-base-step);
        margin-left: auto;
        margin-right: auto;

        @include respond-min($nes-sm) {
            padding-left: calc(8 * $nes-base-step);
            padding-right: calc(8 * $nes-base-step);
        }

        @include respond-min($nes-lg) {
            padding-left: calc(16 * $nes-base-step);
            padding-right: calc(16 * $nes-base-step);
        }
    }
}

.container--section {
    margin-bottom: toRem($global--section-spacing - $global--grid-gutter);
}

.container--extended {
    margin-left: auto;
    margin-right: auto;
    max-width: none;

    @include respond-min($m) {
        margin-left: auto;
        margin-right: auto;
    }
}

.container--header {
    // bedoeld om te gebruiken in de structuur:
    // .container.container--header + .container.cms-Article.
    //
    // de magic numbers in de margins zijn gekozen zodat om dezelfde ruimte
    // om de header onstaat als in de volgende structuur:
    // .container > .grid > .grid__unit--divider > .grid__heading
    //
    // als gebruikt moet worden in andere context (voor een normaal grid)
    // moet deze styling aangepast worden om de ruimte consistent te houden
    margin-top: toRem(44px);
    margin-bottom: toRem($global--grid-gutter + 5px);

    h2 {
        @extend %grid__heading;
    }
}

.container--padMobile {
    padding: 0 toRem(20px);

    @include respond-min($m) {
        padding: 0;
    }
}

.container--connectSides {
    margin-left: auto;
    margin-right: auto;

    .grid {
        margin: 0;
    }

    .grid__unit {
        padding-left: 0;
        padding-right: 0;
    }

    @include respond-min($m) {
        margin-left: auto;
        margin-right: auto;
    }

    // dit moest van dirk.
    // TODO: evalueren oplossing, kan dit simpeler?
    @include respond-min($xxl - $global--grid-gutter) {
        max-width: none;
    }

    @include respond-min($xxl) {
        max-width: toRem($global--container-content-width);
    }
}

.container--clearHeader {
    margin-top: toRem(20px);
}

$article-padding-top-default: 30px;
$article-padding-bottom-default: 30px;
$article-padding-top-medium: 2 * $article-padding-top-default;
$article-padding-bottom-medium: 1.5 * $article-padding-bottom-default;
$article-gradient-color: $white;
$article-gradient-start: transparentize($article-gradient-color, 0.1);
$article-gradient-end: transparentize($article-gradient-color, 0);

.container--article {
    @include ns-box-shadow(0.15);

    background: $article-gradient-color;
    background: linear-gradient(
        to bottom,
        $article-gradient-start 0,
        $article-gradient-end toRem(30px),
        $article-gradient-color 100%
    ); /* W3C */
    margin-bottom: toRem(60px - $global--grid-halfgutter);
    padding-bottom: toRem($article-padding-bottom-default);
    padding-left: toRem($tile-space);
    padding-right: toRem($tile-space);
    padding-top: toRem($article-padding-top-default);

    @include respond-min($m) {
        background: linear-gradient(
            to bottom,
            $article-gradient-start 0,
            $article-gradient-end toRem(60px),
            $article-gradient-color 100%
        ); /* W3C */
        padding-top: toRem($article-padding-top-medium);
        padding-bottom: toRem($article-padding-bottom-medium);
    }

    @include respond-min($l) {
        padding-left: 0;
        padding-right: 0;
    }
}

//Same values as tile__sticker
$tile-sticker-width: 130px;
$tile-sticker-height: 120px;
$tile-sticker-right: 15px;

$sticker-distance-l: 35px;
$sticker-distance-m: 20px;
$container-sticker-top-l: -($tile-sticker-height - $sticker-distance-l);
$container-sticker-top-m: -($tile-sticker-height - $sticker-distance-m);

.container__sticker {
    position: absolute;
    z-index: 1;
    top: toRem($container-sticker-top-m);
    height: toRem($tile-sticker-height);
    width: toRem($tile-sticker-width);
    right: toRem($tile-space);

    @include respond-min($l) {
        right: percentage(math.div(1, 12));
    }

    @include respond-min($m) {
        top: toRem($container-sticker-top-l);
    }
}

.container--slim {
    padding: toRem($tile-space);
}

.container--padded {
    padding: toRem($global--grid-halfgutter);

    @include respond-min($m) {
        padding: toRem($tile-space);
    }
}

.container--narrow {
    padding-left: toRem($global--grid-halfgutter);
    padding-right: toRem($global--grid-halfgutter);

    @include respond-min($m) {
        padding-left: toRem($tile-space);
        padding-right: toRem($tile-space);
    }
}

.container--planbar {
    padding-top: toRem($global--grid-gutter);
    padding-bottom: toRem($global--grid-gutter);

    @include respond-min($m) {
        padding-top: toRem($global--grid-gutter * 2);
        padding-bottom: toRem($global--grid-gutter * 2);
    }
}

.container--pullUp {
    padding-bottom: toRem($global--grid-quartergutter * 13);
    margin-bottom: toRem(-$global--grid-quartergutter * 11);

    @include respond-min($m) {
        padding-bottom: toRem($global--grid-quartergutter * 19);
    }
}

.container--nobg {
    background: none;
    box-shadow: none;
}

.container--gridWidth {
    max-width: 1200px;
    margin-left: 0;
    margin-right: 0;
    padding-left: toRem(21px);
    padding-right: toRem(21px);

    @include respond-min($m) {
        padding-left: toRem($tile-space);
        padding-right: toRem($tile-space);
    }

    @include respond-min($l) {
        padding-left: 0;
        padding-right: 0;
    }

    @include respond-min($xxl) {
        margin-left: auto;
        margin-right: auto;
    }
}
