/*
@name Form checkbox
@description styling for composite form html
@section Objects
@type UI
@extended false
@category form
@className checkbox

@modifier checkbox--otherValue
@modifier checkbox--filter
@modifier checkbox--filterDisabled
@modifier checkbox--inverted

@markup
<div class="formfield__inputContainer">
    <div class="checkbox formfield__checkbox {{modifier_class}}">
        <input required type="checkbox" class="checkbox__input" id="checkbox{{modifier_class}}" name="checkbox{{modifier_class}}">
        <label class="checkbox__label" for="checkbox{{modifier_class}}" tabindex="-1">Ja</label>
    </div>
</div>

*/

$checkbox-space: 28px;

.checkbox--otherValue {
    position: relative;
    margin-top: toRem($line-height-base - $input-height) * 0.5;
}

.checkbox__label {
    display: block;
    cursor: pointer;
    position: relative;
    padding-left: toRem($checkbox-space);

    &::before {
        content: '';
        width: toRem(20px);
        height: toRem(20px);
        cursor: pointer;
        position: absolute;
        top: toRem(2px);
        left: 0;
        background: transparent;
        border: $input-border;
        border-radius: toRem(2px);
        transition: $fade-link;

        .has-error & {
            border-color: $input-border-color-error;
        }
    }

    &::after {
        content: '';
        width: toRem(14px);
        height: toRem(8px);
        position: absolute;
        top: toRem(8px);
        left: toRem(5px);
        border: toRem(3px) solid $white;
        border-top: none;
        border-right: none;
        border-radius: 2px;
        transform: scale(0) rotate(-45deg);
        transition: $fade-all;
        transform-origin: 30% 70%;
    }

    .checkbox--otherValue & {
        position: absolute;
        left: 0;
        top: toRem($form-element-height - $line-height-base) * 0.5;
    }

    &:hover {
        &::before {
            border-color: $input-border-color-hover;
        }
    }

    &:focus {
        outline: none;
    }
}

.checkbox__input {
    @extend %visuallyhidden;

    &:checked + .checkbox__label {
        color: $ns-blue;
    }

    &:checked + .checkbox__label::before,
    #ecmm .panel &:checked + .checkbox__label::before {
        background-color: $ns-acc-green;
        border-color: transparent;
    }

    &:checked + .checkbox__label::after {
        transform: scale(1) rotate(-45deg);
    }

    &:focus + .checkbox__label::before {
        border-color: $input-border-color-focus;
        box-shadow: $input-box-shadow-focus;
    }
}

.checkbox__otherValue {
    margin-left: toRem($checkbox-space);
}

.checkbox__input--white {
    & + .checkbox__label::before {
        background: #fff;
    }
}

$checkbox-space-filter: 33px;

.checkbox--filter {
    position: relative;

    .checkbox__label {
        z-index: 1;
        color: $ns-grey-20;
        padding-left: toRem($checkbox-space-filter);

        &::before {
            background: #fff;
        }
    }

    &:hover {
        .checkbox__input + .checkbox__label {
            color: $ns-light-blue;
        }
    }

    .checkbox__input {
        &:checked + .checkbox__label {
            color: $ns-blue;

            .filter__amount {
                color: $ns-blue;
            }
        }
    }
}

.checkbox--filterDisabled {
    color: $ns-grey-80;

    .checkbox__label {
        color: $ns-grey-70;

        &::before {
            background: $ns-grey-70;
            border-color: $ns-grey-70;
            cursor: default;
        }

        &:hover {
            &::before {
                border-color: $ns-grey-70;
            }
        }
    }

    .checkbox__input {
        &:checked + .checkbox__label,
        &:checked + .checkbox__label .filter__amount,
        &:hover + .checkbox__label {
            cursor: default;
            color: $ns-grey-70;
        }
    }

    .filter__amount {
        color: $ns-grey-70;
        cursor: default;
    }

    &:hover {
        .filter__amount,
        .checkbox__input,
        .checkbox__label {
            cursor: default;
        }

        .checkbox__input + .checkbox__label {
            color: $ns-grey-70;
        }
    }
}

.checkbox--inverted {
    .checkbox__label {
        &::before,
        .checkbox__input:checked + &::before {
            border-color: transparent;
            background-color: $white;
        }
    }

    .checkbox__input {
        &:checked + .checkbox__label::before {
            background-color: $white;
        }

        &:checked + .checkbox__label::after {
            border-color: $ns-acc-green;
        }

        &:focus + .checkbox__label::before {
            border-color: $input-border-color-focus;
        }
    }
}
