/*
inputSpacer
*/

.inputSpacer {
    cursor: text;
    position: relative;
    width: toRem(240px);
    height: toRem(44px);
    margin-bottom: toRem(-44px);

    @include respond-min($m) {
        width: toRem(303px);
    }
}

%inputSpacer__input {
    background: transparent;
    position: relative;
    width: toRem(240px);
    height: toRem(44px);
    line-height: toRem(22px);
    padding-top: 0;
    padding-bottom: 0;
    letter-spacing: toRem(1.6px);
    transition-property: border-color, outline, box-shadow;

    @include respond-min($m) {
        width: toRem(303px);
        letter-spacing: toRem(5.6px);
    }
}

.inputSpacer__input {
    @extend %inputSpacer__input;

    //Prevent flicker with the focus transition
    border-color: $input-border-color-hover;
}

.inputSpacer__input--input {
    position: relative;
    margin-top: 0;
    -moz-appearance: textfield;
}

.inputSpacer__displayContainer {
    @extend %textfield;
    @extend %inputSpacer__input;

    padding-top: toRem(10px);
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
}

.inputSpacer__delimiter {
    display: inline-block;
    text-align: center;
}

.inputSpacer__line {
    position: absolute;
    top: 0;
    bottom: 0;
    width: toRem(1px);
    left: toRem(65px);

    @include respond-min($m) {
        left: toRem(78px);
    }

    &:nth-of-type(1) {
        left: toRem(121px);

        @include respond-min($m) {
            left: toRem(151px);
        }
    }

    &:nth-of-type(2) {
        left: toRem(176px);

        @include respond-min($m) {
            left: toRem(223px);
        }
    }

    &::before,
    &::after {
        content: '';
        width: toRem(1px);
        height: toRem(10px);
        border-left: $input-border;
        position: absolute;
        left: 0;
        transition: $fade-link;

        .has-error:not(:focus-within) & {
            border-color: $input-border-color-error;
        }
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;
    }
}

.inputSpacer:hover {
    .inputSpacer__displayContainer,
    .inputSpacer__line::before,
    .inputSpacer__line::after {
        border-color: $input-border-color-hover;
    }
}

.has-error:not(:focus-within) {
    .inputSpacer:hover {
        .inputSpacer__displayContainer,
        .inputSpacer__line::before,
        .inputSpacer__line::after {
            border-color: $input-border-color-hover;
        }
    }
}

.inputSpacer__input--input::-webkit-inner-spin-button,
.inputSpacer__input--input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
