// Temporary styling needed
// ---------------------------------------------------------------------------
$submenu-column-gap: 56px;
$submenu-column-minWidth: 182px;
$header-height: 60px;
$header-height-ml: 115px;
$header-height-xxl: 121px;

// ---------------------------------------------------------------------------

/*
 * HEADER
 */
.ns-header {
    position: relative;
    width: 100%;
    height: $global--header-height;
    overflow: visible;
    z-index: z('header');
    background-color: $ns-white;
    box-shadow: 0 1px 1px rgba($ns-black, 0.03), 0 10px 20px rgba($ns-black, 0.04);
    line-height: toRem($line-height-base);

    @include respond-min($ml) {
        height: toRem($header-height-ml);
    }

    @include respond-min($xxl) {
        height: toRem($header-height-xxl);
    }

    .hide-header & {
        display: none;
    }
}

.ns-header__wrapper {
    position: relative;
    max-width: toRem($global--container-content-width);
    padding-left: toRem($global--grid-halfgutter);
    padding-right: toRem($global--grid-halfgutter);

    @include respond-min($m) {
        padding-left: toRem($tile-space);
        padding-right: toRem($tile-space);
    }

    @include respond-min($ml) {
        margin-left: toRem($global--grid-halfgutter);
        margin-right: toRem($global--grid-halfgutter);
    }

    @include respond-min($xxl) {
        margin-left: auto;
        margin-right: auto;
    }

    .nessie & {
        max-width: $nes-container-width;
        padding-left: calc(6 * $nes-base-step);
        padding-right: calc(6 * $nes-base-step);
        margin-left: auto;
        margin-right: auto;

        @include respond-min($nes-sm) {
            padding-left: calc(8 * $nes-base-step);
            padding-right: calc(8 * $nes-base-step);
        }

        @include respond-min($nes-lg) {
            padding-left: calc(16 * $nes-base-step);
            padding-right: calc(16 * $nes-base-step);
        }
    }
}

.ns-header__containerWrapper {
    display: none;
    height: 0;
    position: absolute;
    top: $global--header-height;
    width: 100vw;
    left: 0;
    z-index: z('header');
    background-color: $ns-white;

    .is-toggled &,
    .is-toggled-mijnns & {
        display: block;
    }

    .is-toggled-search & {
        z-index: z('header') + 5;
    }

    &.is-open {
        z-index: z('header') + 3;
    }

    @include respond-min($ml) {
        display: block;
        position: relative;
        top: initial;
        left: initial;
        height: auto;
        width: auto;

        .no-cssgrid & {
            top: 0;
        }
    }
}

.ns-header__container {
    position: relative;
    height: calc(
        #{$global--full-viewport-height-fallback} - #{$global--header-height}
    ); /* Fallback for browsers that do not support Custom Properties */
    height: calc(#{$global--full-viewport-height} - #{$global--header-height});
    width: 100vw;

    @include respond-min($ml) {
        height: auto;
        width: auto;
    }
}

.ns-header__content {
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 14px 21px 60px 21px;
    height: 100%;
    width: 100%;

    .is-open > & {
        overflow-y: hidden;
    }

    .is-toggled-mijnns & {
        padding: 0;
    }

    @include respond-min($m) {
        padding: 14px 46px 60px;
    }

    @include respond-min($ml) {
        position: relative;
        display: grid;
        gap: 13px 25px;
        grid-template-columns: 64px minmax(0, 1fr);
        grid-template-rows: auto 40px;
        grid-template-areas:
            'top-nav-logo top-nav'
            'main-nav-logo main-nav';
        padding: toRem(16px) 0 toRem(20px) 0;
        height: auto;
        width: auto;
        overflow: initial;
        background-color: initial;
        left: initial;
        top: initial;

        .no-cssgrid & {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: space-between;
            top: 0;
            overflow-y: visible;
            overflow-x: visible;
        }
    }

    @include respond-min($xxl) {
        grid-template-columns: 76px minmax(0, 1fr);
        grid-template-rows: auto 45px;
        column-gap: 40px;
    }
}

.ns-header__fade {
    display: none;
    opacity: 0;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
    pointer-events: none;
    background: linear-gradient(0deg, rgba($ns-white, 1) 0%, rgba($ns-white, 0) 100%);
    z-index: z('header') + 4;

    @include respond-min($ml) {
        display: none;
    }
}

.ns-header__button-wrapper {
    display: flex;
    margin: 21px 0 28px 0;

    .is-toggled-mijnns & {
        display: none;
    }

    @include respond-min($ml) {
        display: none;
    }

    .button--buy {
        padding: toRem($button-vertical-space) toRem($button-horizontal-space);

        @include respond-min($min-site-width + 40px) {
            padding-left: toRem($button-horizontal-space * 0.5 * 2 + $icon-size-base);
        }

        &::before {
            display: none;

            @include createIconBg('cart', $ns-light-blue);

            @include respond-min($min-site-width + 40px) {
                display: inline-block;
            }
        }
    }
}

.ns-header__skiplink {
    @include visuallyhidden;

    font-weight: normal;
    box-shadow: none;
    transition: none;

    &:focus {
        @include show(absolute);
        @include blueBox;

        top: toRem(17px);
        left: toRem($global--grid-halfgutter);
        outline: none;
        border: none;
        z-index: z('header') + 3;

        &::before {
            background: $ns-white;
            z-index: -1;
        }

        @include respond-min($ml) {
            top: toRem(22px);
            left: toRem(122px);
        }

        @include respond-min($xxl) {
            left: 2rem;
        }

        .nessie & {
            left: calc(6 * $nes-base-step);

            @include respond-min($nes-sm) {
                left: calc(8 * $nes-base-step);
            }

            @include respond-min($nes-lg) {
                left: calc(16 * $nes-base-step);
            }
        }
    }

    &:hover {
        box-shadow: none;
    }
}

.ns-header__logo {
    position: absolute;
    width: 64px;
    height: 30px;
    display: block;
    left: 21px;
    top: 15px;
    z-index: z('header') + 2;
    box-shadow: none;

    @include respond-min($m) {
        left: 46px;
    }

    @include respond-min($ml) {
        position: absolute;
        left: 2rem;
        top: 20px;
    }

    @include respond-min($xxl) {
        top: 65px;
        width: 76px;
    }

    &:focus {
        @include blueBox;

        outline: none;
    }

    .alt {
        color: $ns-blue;
    }

    .nessie & {
        left: calc(6 * $nes-base-step);

        @include respond-min($nes-sm) {
            left: calc(8 * $nes-base-step);
        }

        @include respond-min($nes-lg) {
            left: calc(16 * $nes-base-step);
        }
    }
}

.ns-header__logoImage {
    display: block;
    width: 100%;
    height: 100%;
    transition: $fade-link;
    background-image: url('../images/nslogo.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 64px 25px;

    @include respond-min($xxl) {
        background-size: 76px 30px;
    }

    @supports not (-ms-ime-align: auto) {
        // Not IE11 & Edge (All versions before Chromium)
        @supports (mask-image: url('')) {
            mask-image: url('../images/nslogo.svg');
            mask-repeat: no-repeat;
            mask-size: contain;
            mask-position: left center;
            background-image: none;
            background-color: $ns-blue;
            color: $ns-white;

            &:hover {
                background-color: $ns-yellow;
            }
        }
    }
}

/*
 * Mobile navigation bar
 */
.ns-header__mobile {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    padding: 0 21px;
    height: $header-height;
    position: absolute;
    top: 0;
    left: 0;
    z-index: z('header') + 1;
    width: 100%;
    background-color: $ns-white;

    @include respond-min($m) {
        padding: 0 46px;
    }

    @include respond-min($ml) {
        display: none;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        transition: all 0.2s cubic-bezier(0.7, 0.27, 0.27, 1);
        opacity: 0;
        box-shadow: 0 1px 1px rgba($ns-black, 0.03), 0 10px 20px rgba($ns-black, 0.04);
        pointer-events: none;
    }

    &.is-active {
        &::before {
            opacity: 1;
        }
    }

    .nessie & {
        padding-left: calc(6 * $nes-base-step);
        padding-right: calc(6 * $nes-base-step);

        @include respond-min($nes-sm) {
            padding-left: calc(8 * $nes-base-step);
            padding-right: calc(8 * $nes-base-step);
        }
    }
}

.ns-header__mobile-list {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.ns-header__mobile-list-item {
    margin: 0;
    padding: 0;
}

.ns-header__mobile-list-item--miniCart {
    padding: 0 toRem(5px);

    .ns-menu__menu-button {
        &::after {
            display: none;
        }
    }
}

.ns-header__toggle-buttonLine {
    position: relative;
    top: 0.5px;
    display: block;
    width: 100%;
    height: 1px;
    background: #003082;
    opacity: 1;
    transform-origin: 0 0;
    transition: all 0.3s cubic-bezier(0.7, 0.27, 0.27, 1);

    &:not(:last-child) {
        margin-bottom: 5px;
    }

    &:first-child {
        transform-origin: 0 0;
    }

    &:last-child {
        width: 60%;
        transform-origin: 0 100%;
    }
}

.ns-header__toggle-button {
    position: relative;
    width: 40px;
    margin: 0 -10px 0 3px;
    padding: 10px;
    line-height: 0;
    cursor: pointer;

    &:focus {
        @include blueBox('::after', false);

        outline: none;
    }

    &.is-active {
        .ns-header__toggle-buttonLine {
            width: 0;
            opacity: 0;
        }

        .ns-header__toggle-buttonLine:first-child {
            transform: rotate(45deg) translateY(-2px);
            width: 22px;
            opacity: 1;
        }

        .ns-header__toggle-buttonLine:last-child {
            transform: rotate(-45deg) translateY(2px);
            width: 22px;
            opacity: 1;
        }
    }
}

.ns-header__mijnns-button {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:focus {
        @include blueBox('::after', false);

        outline: none;
    }

    [data-email='short'] {
        text-transform: uppercase;
        font-weight: bold;
        color: $ns-blue;
    }
}

.ns-header__mijnns-icon {
    height: toRem(33px);
    width: toRem(40px);

    &::before {
        content: '';
        height: toRem($icon-size-base);
        width: toRem($icon-size-base);
        display: inline-block;

        @include createIconBg('user', $ns-blue);
    }
}

.ns-menu__mijnns-close-text {
    display: none;
    opacity: 0;
    color: $ns-light-blue;
    margin-top: 1px;
}

.ns-menu__mijnns-open-text {
    .is-toggled-mijnns & {
        display: none;
    }
}

.ns-header__previous-button {
    position: relative;
    height: 40px;
    width: 40px;
    left: -14px;
    cursor: pointer;

    &:focus {
        @include blueBox('::after', false);

        outline: none;
    }

    &::before {
        content: '';
        height: toRem($icon-size-base);
        width: toRem($icon-size-base);
        position: absolute;
        left: 2px;
        top: 3px;
        transform: rotate(180deg);

        @include createIconBg('chevron-large', $ns-blue);
    }
}

/*
 * MENU
 */
.ns-menu {
    .is-open,
    .is-toggled & {
        display: block;
    }

    .is-toggled-mijnns & {
        display: none;
    }

    @include respond-min($ml) {
        display: block;
        grid-area: main-nav-logo / main-nav-logo / main-nav-logo / main-nav;
        align-self: center;
        justify-self: start;
    }

    &:first-of-type {
        @include respond-min($ml) {
            padding-bottom: 30px;
            margin-top: 30px;
        }
    }

    @include respond-min($xxl) {
        grid-area: main-nav;
    }
}

.ns-menu--mainmenu {
    @include respond-min($ml) {
        padding-bottom: 30px;
        margin-top: 30px;
    }

    .no-cssgrid & {
        padding-bottom: 0;
        margin-top: 0;
        order: 2;
        width: 80%;

        @include respond-min($xxl) {
            width: 66%;
            margin-left: 110px;
        }
    }
}

.ns-menu__list {
    list-style: none;
    margin: 0;
    padding: 0;

    @include respond-min($ml) {
        display: flex;
        flex-grow: 1;
        flex-basis: auto;
        flex-direction: row;
        align-items: center;
    }
}

.ns-menu__list-item {
    font-weight: bold;
    font-size: toRem(18px);
    color: $ns-blue;
    padding: 8px 0;
    margin: 0;

    @include respond-min($ml) {
        font-size: toRem($font-size-base);
        padding: 0;
        margin: 0 0 0 toRem(28px);

        &.is-active > .ns-menu__menu-button::after {
            @include yellowUnderline;
        }

        &:focus-within > .ns-menu__menu-button::after {
            transition: none;
        }

        &:hover > .ns-menu__menu-link::after {
            opacity: 1;
            transform: scaleX(1);
        }

        &:hover:not(:focus-within) > .ns-menu__menu-button::after {
            opacity: 1;
            transform: scaleX(1);
        }

        &.is-active > .ns-menu__menu-button:not(:focus)::after {
            transform: none;
            opacity: 1;
            transition: none;
        }
    }

    @include respond-min($xxl) {
        font-size: toRem(18px);
        margin-left: toRem(40px);
    }

    &:first-child {
        margin-left: 0;
    }
}

.ns-menu__menu-button {
    display: flex;
    align-items: center;
    position: relative;
    color: $ns-blue;
    cursor: pointer;
    font-weight: bold;
    line-height: toRem($line-height-base);
    width: 100%;
    text-align: left;

    @include respond-min($ml) {
        display: inline-block;

        > .icon:not(.ns-menu__miniCart-icon) {
            display: none;
        }
    }

    &:focus {
        @include blueBox;

        outline: none;
    }

    &::after {
        content: '';
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 11" fill="#{str-replace(inspect($ns-blue), '#', '%23')}"> <polygon points="5.5 5.5 0 11 1.6 11 7.1 5.5 1.6 0 0 0 5.5 5.5"></polygon> </svg>');
        width: toRem(7px);
        height: toRem(11px);
        margin-right: 8px;

        @include respond-min($ml) {
            @include yellowUnderline;

            background-size: initial;
            background-repeat: initial;
            background-position: initial;
            background-image: initial;
            margin-right: 0;
        }
    }
}

.ns-menu__menu-button-text {
    flex-grow: 1;
    margin-right: 14px;

    .icon + & {
        margin-left: 14px;

        @include respond-min($ml) {
            margin-left: 0;
        }
    }

    @include respond-min($ml) {
        margin-right: 0;
    }
}

.ns-menu__menu-link {
    font-size: toRem($font-size-base);
    font-weight: normal;
    display: inline-block;
    width: 100%;

    &:focus {
        @include blueBox;

        outline: none;
    }

    @include respond-min($ml) {
        font-size: toRem(14px);
        width: auto;

        &:focus {
            &::before {
                top: 0;
                bottom: 0;
            }
        }

        &::after {
            @include yellowUnderline;
        }
    }
}

/*
 * SUBMENU
 */
.ns-submenu {
    display: none;
    background-color: $ns-white;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: $global--full-viewport-height-fallback; /* Fallback for browsers that do not support Custom Properties */
    height: $global--full-viewport-height;
    box-shadow: -5px 0 20px rgba(0, 0, 0, 0.15);

    @include respond-min($ml) {
        position: absolute;
        top: calc(100% - 2px); // -2px to hide the focus outline of the content container
        transform-origin: left top;
        width: 100%;
        height: auto;
        box-shadow: none;

        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100vw;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
            background-color: $ns-white;
            pointer-events: none;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            height: 50%;
            width: 100%;
            box-shadow: 0 0 8px $ns-grey-alpha-10, 0 0 64px $ns-grey-alpha-15;
            z-index: -1;
        }
    }

    &.is-open {
        display: grid;
    }

    &:focus {
        outline: 0; // remove black outline on focus
    }
}

.ns-submenu__header {
    @extend .ns-header__mobile;

    justify-content: space-between;

    @include respond-min($ml) {
        display: none;
    }
}

.ns-submenu__headerTitle {
    margin: 0;
    font-weight: bold;
}

.ns-submenu__content {
    position: absolute;
    top: $global--header-height;
    left: 0;
    height: calc(
        #{$global--full-viewport-height-fallback} - #{$global--header-height}
    ); /* Fallback for browsers that do not support Custom Properties */
    height: calc(#{$global--full-viewport-height} - #{$global--header-height});
    width: 100%;
    padding: 14px 21px;
    overflow-y: auto;
    overflow-x: hidden;

    .is-toggled-mijnns & {
        padding-top: 40px;
        top: 0;
        height: 100%;
    }

    @include respond-min($m) {
        padding: 14px 46px 14px;
    }

    @include respond-min($ml) {
        position: relative;
        top: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax($submenu-column-minWidth, 1fr));
        column-gap: $submenu-column-gap;
        padding: 0;
        overflow: visible;
        height: auto;

        .no-cssgrid & {
            display: flex;
            justify-content: space-between;
        }
    }
}

.ns-submenu__list {
    margin-bottom: toRem(35px);

    @include respond-min($ml) {
        margin-bottom: toRem(42px);
    }

    > li {
        list-style: none;
        margin: 0;
        padding: 12px 0;
        line-height: toRem(27px);

        @include respond-min($ml) {
            line-height: inherit;
            margin-bottom: toRem(16px);
            padding: 0;
        }
    }
}

.ns-submenu__separator {
    margin-top: toRem(28px);
    margin-bottom: toRem(21px);
    border: 0;
    border-top: 1px solid #e6e6e9;
    height: 1px;
}

.ns-submenu__title {
    display: none;
    font-size: toRem(28px);
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: -0.016em;
    padding-bottom: 10px;
    border-bottom: 1px solid $ns-blue;
    margin-bottom: toRem(40px);

    @include respond-min($ml) {
        display: block;
    }
}

.ns-submenu__columnizr {
    columns: 1;

    @include respond-min($ml) {
        &--max-two {
            columns: 2;
        }
    }
}

.ns-submenu__mijnnsStatus {
    margin-bottom: 0;
    color: $ns-grey-40;
    font-weight: normal;
}

.ns-submenu__mijnnsName {
    color: $ns-blue;
    word-break: break-all;
    font-weight: normal;
    margin-bottom: 0;
}

.ns-submenu__column {
    font-weight: normal;

    @include respond-min($ml) {
        margin: toRem(28px) 0 toRem(21px);

        .no-cssgrid & {
            flex-grow: 1;
        }
    }
}

.ns-submenu__column--span {
    display: grid;
    column-gap: $submenu-column-gap;
    width: 100%;

    & .ns-submenu__title {
        grid-column: 1 / -1;
    }

    .ns-submenu__list {
        margin-top: 0;
    }

    @include respond-min($ml) {
        .no-cssgrid & {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}

.ns-submenu__column--2 {
    @include respond-min($ml) {
        grid-column: span 2;
        grid-template-columns: repeat(2, minmax($submenu-column-minWidth, 1fr));

        .no-cssgrid & {
            flex-grow: 2;
        }
    }
}

.ns-submenu__column--3 {
    @include respond-min($ml) {
        grid-column: span 3;
        grid-template-columns: repeat(3, minmax($submenu-column-minWidth, 1fr));

        .no-cssgrid & {
            flex-grow: 3;

            .ns-submenu__title {
                width: 100%;
            }
        }
    }
}

.ns-submenu__list-title {
    font-weight: 700;
    line-height: toRem(27px);
    font-size: toRem(18px);
    padding: 12px 0;
    margin: 0;

    @include respond-min($ml) {
        line-height: inherit;
        font-size: toRem($font-size-base);
        padding: 0;
        margin-bottom: toRem(16px);
    }
}

.ns-submenu__link {
    display: inline-block;
    color: $ns-blue;
    box-shadow: none;
    font-size: toRem(18px);
    font-weight: 400;
    transition: all 0.2s cubic-bezier(0.7, 0.27, 0.27, 1);

    @include respond-min($ml) {
        font-size: toRem($font-size-base);
    }

    &:hover {
        text-decoration: underline;
        text-underline-position: under;
        box-shadow: none;
    }

    &:focus {
        @include blueBox;

        outline: none;

        &:not(.ns-submenu__link--arrowLink)::before {
            top: -1px;
            bottom: 0;
        }
    }
}

.ns-submenu__link--light-blue {
    color: $ns-light-blue;

    &:hover {
        color: $ns-blue;
    }
}

.ns-submenu__link--external {
    &::after {
        content: '';
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 15" fill="#{str-replace(inspect($ns-light-blue), '#', '%23')}"> <polygon points="9 14 1 14 1 1 9 1 9 4.5 10 4.5 10 0 0 0 0 15 10 15 10 10.5 9 10.5 9 14"></polygon> <polygon points="14.708 3.146 14.011 3.854 17.113 7 4.5 7 4.5 8 17.113 8 14.011 11.146 14.708 11.854 19 7.5 14.708 3.146"></polygon> </svg>');
        width: toRem(19px);
        height: toRem(15px);
        margin-left: 14px;
        position: inherit;
        top: toRem(2px);
    }

    &:hover {
        &::after {
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 15" fill="#{str-replace(inspect($ns-blue), '#', '%23')}"> <polygon points="9 14 1 14 1 1 9 1 9 4.5 10 4.5 10 0 0 0 0 15 10 15 10 10.5 9 10.5 9 14"></polygon> <polygon points="14.708 3.146 14.011 3.854 17.113 7 4.5 7 4.5 8 17.113 8 14.011 11.146 14.708 11.854 19 7.5 14.708 3.146"></polygon> </svg>');
        }
    }
}

.ns-submenu__link--arrowLink {
    @extend .arrowLink;

    // use after pseudo element i.s.o. before when focus because of arrow icon
    &:focus {
        @include blueBox('::after');

        outline: none;

        &::after {
            top: -1px;
            bottom: 0;
        }

        &::before {
            border: none;
            box-shadow: none;
        }
    }
}

/*
 * secondary MENU ....
 */
.ns-menu--secondary {
    display: none;
    align-self: center;
    justify-self: end;
    margin-right: toRem(25px);

    .is-toggled & {
        display: none;
    }

    .is-toggled-mijnns & {
        display: block;
        margin-right: 0;
    }

    .is-toggled-search & {
        display: block;
    }

    @include respond-min($ml) {
        grid-area: main-nav;
        margin-right: initial;
        display: block;

        .no-cssgrid & {
            order: 3;
            margin-right: 0;
        }
    }

    .ns-menu__list {
        flex-direction: row;
    }

    .ns-menu__list-item:not(:first-child) {
        margin-left: toRem(5px);

        @include respond-min($l) {
            margin-left: toRem(15px);
        }
    }

    .ns-menu__list-item--search {
        .is-toggled-search & {
            display: block;
            width: 0;
            height: 0;
            margin: 0;
            padding: 0;
        }
    }

    .ns-menu__menu-button--search {
        .is-toggled-search & {
            display: none;
        }
    }
}

.ns-menu__list-item--dropdown {
    position: relative;

    .ns-submenu {
        &.is-open {
            display: block;
        }

        @include respond-min($ml) {
            width: toRem(320px);
            position: absolute;
            box-shadow: 0 2px 35px rgba(0, 0, 0, 0.15);
            top: toRem(43px);
            left: 50%;
            transform: translateX(-50%);
            border-radius: 4px;

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: toRem(-12px);
                left: 50%;
                border-left: toRem(8px) solid transparent;
                border-right: toRem(8px) solid transparent;
                border-bottom: toRem(14px) solid #fff;
                width: auto;
                height: auto;
                background-color: transparent;
                transform: translateX(-50%);
            }
        }

        &::after {
            display: none;
        }
    }

    .ns-submenu__content {
        @include respond-min($ml) {
            padding: toRem(21px);
        }
    }

    .ns-submenu__column {
        margin: 0;
    }

    .ns-submenu__list {
        margin: 0;

        > li {
            padding: 0;
        }

        &.ns-submenu__list--mijnnsLoggedIn {
            // must be hier for specificity
            margin-top: toRem(14px);
        }
    }

    .ns-submenu__link {
        font-size: toRem(16px);
    }
}

.ns-menu__list-item--nsinternational {
    .ns-submenu__list {
        > li {
            line-height: toRem(24px);
        }
    }
}

.ns-menu__list-item--mijnns {
    .is-toggled-search & {
        display: none;
    }

    .ns-menu__menu-button {
        display: none;
        padding-left: toRem(25px);

        @include respond-min($ml) {
            display: inline-block;
        }

        &:not(:focus)::after {
            // must be here because of css specificity
            width: calc(100% - #{toRem(25px)}); // because of Mijn NS icon
        }
    }

    .ns-menu__menu-button-text {
        display: inline-block;
        min-height: toRem(15px); // fix when mijn ns icon is only visible

        &--full {
            [data-email='long'] {
                display: inline-block;
                width: toRem(68px);
                overflow: hidden;
                vertical-align: top;
            }

            [data-email='short'] {
                text-transform: uppercase;
            }
        }

        &::before {
            content: '';
            height: toRem($icon-size-base);
            width: toRem($icon-size-base);
            position: absolute;
            left: -3px;
            top: -4px;

            @include createIconBg('user', $ns-blue);
        }

        [data-email='long']::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: toRem(48px);
            height: 100%;
            z-index: 1;
            opacity: 1;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, #fff 90%);
        }

        @include respond-min($l) {
            &::before {
                left: -8px;
            }
        }
    }

    &.ns-menu__list-item--dropdown .ns-submenu {
        background-color: transparent;

        @include respond-min($ml) {
            left: -223px;
            transform: translateX(0);
            background-color: $ns-white;
        }

        @include respond-min($l) {
            left: -158px;
        }

        @include respond-min($xxl) {
            left: -150px;
        }

        .is-toggled-mijnns & {
            top: 60px;
            box-shadow: none;
            height: calc(
                #{$global--full-viewport-height-fallback} - #{$global--header-height}
            ); /* Fallback for browsers that do not support Custom Properties */
            height: calc(#{$global--full-viewport-height} - #{$global--header-height});
        }

        &::before {
            left: auto;
            right: 75px;
            transform: translateX(0);

            @include respond-min($l) {
                right: 99px;
            }

            @include respond-min($xxl) {
                right: 104px;
            }
        }
    }

    .ns-submenu__link {
        font-size: toRem($font-size-base);
    }

    .ns-submenu__list {
        > li {
            padding: 0;
            margin-bottom: toRem(14px);
        }
    }
}

.ns-menu__list-item--search {
    height: 33px;
    width: 30px;
    display: none;

    &.is-active > .ns-menu__menu-button:not(:focus)::after,
    &:hover:not(:focus-within) > .ns-menu__menu-button::after {
        opacity: 0;
    }

    .ns-menu__menu-button {
        height: toRem(30px);

        &:focus {
            &::before {
                top: 2px;
                left: 2px;
                right: -2px;
                bottom: -2px;
            }
        }
    }

    .ns-menu__menu-button-text {
        &::before {
            content: '';

            @extend %icon;

            @include createIconBg('magnifier', $ns-blue);
        }

        &::after {
            display: none;
        }
    }

    @include respond-min($ml) {
        display: block;
    }
}

/* MiniCart */
.ns-menu__list-item--miniCart {
    display: none;
    height: toRem(30px);
    padding: 0 toRem(5px);

    &:hover:not(:focus-within) > .ns-menu__menu-button::after {
        opacity: 0;
    }

    @include respond-min($ml) {
        display: block;
    }

    .ns-menu__menu-button {
        height: toRem(30px);

        &:focus {
            &::before {
                top: -6px;
                left: 0;
                right: -6px;
                bottom: -2px;
            }
        }
    }
}

.ns-menu__miniCart-amount {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: toRem(18px);
    height: toRem(18px);
    top: toRem(-4px);
    right: toRem(-1px);
    background-color: $ns-yellow;
    color: $ns-blue;
    border-radius: 50%;
    font-size: toRem(12px);
    font-weight: normal;
    line-height: toRem(18px);
    letter-spacing: -0.05em;
}

.ns-search {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    z-index: z('header') + 5;
    background: $ns-white;

    @include respond-min($ml) {
        background: inherit;
        position: absolute;
        right: 0;
        left: inherit;
        font-weight: normal;
        width: auto;
        height: auto;
        grid-area: main-nav-logo / main-nav-logo / main-nav-logo / main-nav;

        .no-cssgrid & {
            top: 60px;
        }
    }

    @include respond-min($xxl) {
        grid-area: main-nav;

        .no-cssgrid & {
            max-width: calc(100% - 110px);
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 12px;
        width: calc(100% - 15px);
        border-bottom: 1px solid $ns-grey-80;
    }

    .autosuggest__list {
        border: none;
        margin-top: 0;
        transform: scaleY(0);
        opacity: 0;
        transform-origin: left top;
        border-radius: 0;
        box-shadow: none;
        font-weight: normal;
        width: 100vw;

        @include respond-min($ml) {
            border-radius: $input-border-radius;
            box-shadow: 0 toRem(2px) toRem(25px) rgba($ns-black, 0.15);
            font-weight: inherit;
            width: 100%;
        }

        &.autosuggest__open {
            animation-name: slideDown;
            animation-duration: 200ms;
            animation-fill-mode: forwards;
            animation-timing-function: $ease;
        }

        &.autosuggest__empty {
            animation-name: slideUp;
            animation-duration: 100ms;
            animation-fill-mode: forwards;
            animation-timing-function: $ease;
        }
    }

    .autosuggest__link {
        .is-toggled-search & {
            // fix autosuggest alignment on mobile
            padding-left: toRem(($icon-size-base + ($tile-space * 0.5) - 1px) + 10px); // 10px = .ns-search__searchfield margin
        }
    }

    // because of specificty must be here
    .ns-search__searchfield {
        padding-right: 0;
        margin: 10px;
        width: calc(100% - 20px);

        @include respond-min($ml) {
            margin: 0;
            width: 100%;
        }

        &:focus {
            width: calc(100% - 20px);
            border: 1px solid $ns-light-blue;
            box-shadow: 0 0 0 1px $ns-light-blue;

            @include respond-min($ml) {
                width: 100%;
                border: none;
                outline: none;
                box-shadow: none;
            }
        }
    }

    .searchForm {
        background: $ns-yellow;

        @include respond-min($ml) {
            background: inherit;
        }
    }

    .searchForm__field {
        &.searchForm__field--full {
            display: flex;
            align-items: center;

            @include respond-min($ml) {
                display: block;
            }
        }
    }
}

.ns-search__submit {
    @extend %visuallyhidden;
}

.ns-search__close-button {
    position: relative;
    height: 40px;
    width: 40px;
    cursor: pointer;
    margin-right: 10px;

    @include respond-min($ml) {
        display: none;
    }

    &:focus {
        @include blueBox('::after', false);

        outline: none;
    }

    &::before {
        content: '';
        height: toRem($icon-size-base);
        width: toRem($icon-size-base);
        position: absolute;
        left: 0;
        top: 3px;
        transform: rotate(90deg);

        @include createIconBg('chevron-large', $ns-blue);
    }
}

/*
 * Top MENU ....
 */
.ns-menu--topmenu {
    @include respond-min($ml) {
        grid-area: top-nav;
        align-self: start;
        justify-self: end;

        .no-cssgrid & {
            order: 1;
            margin-left: auto;
            margin-bottom: 20px;
        }
    }

    .ns-menu__list-item {
        padding: 8px 0;

        @include respond-min($ml) {
            padding: 0;
            margin-left: toRem(24px);
        }
    }

    .ns-menu__menu-button {
        font-weight: normal;
        font-size: toRem($font-size-base);
        margin-left: 0;

        &::after {
            bottom: -1px;
        }

        @include respond-min($ml) {
            font-size: toRem(14px);

            &:focus {
                &::before {
                    top: 0;
                    bottom: 0;
                }
            }
        }
    }

    .ns-menu__menu-button--search {
        &::after {
            display: none;
        }
    }
}
