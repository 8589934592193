/*
@name Autosuggest
@description
An input type with autosuggest function. Autosuggest must be activated on the form tag in order
to automatically submit the form when clicking or pressing enter button after selecting a suggestion.
Needed to adjust the autosuggest function to your needs, with `data-config` attribute you will be able
to override some settings.

* `suggestUrl` is needed to point the autosuggest to feed itself

The following properties are optional:
* `limit`, defaults to `5`
* `minLength`, defaults to `1`
* `hint`, defaults to `true`
* `highlight`, defaults to `true`

@section Components
@type UI
@extended false

@markup
<form class="searchForm autosuggest"
        action="/zoeken/search"
        method="GET"
        data-controller="generic/ui/Autosuggest"
        data-config="{
            &quot;suggestUrl&quot;: &quot;https://gateway.apiportaltst.ns.nl/zoeken-mock/nl/suggest?q=&quot;,
            &quot;suggestHeaders&quot;: {&quot;Ocp-Apim-Subscription-Key&quot;: &quot;30d51d78af8b410faf42fa3ea2439a42&quot;},
            &quot;apiType&quot;: &quot;searchv2&quot;,
            &quot;displayValue&quot;:&quot;text&quot;,
            &quot;submitValue&quot;:&quot;text&quot;,
            &quot;highlight&quot;:&quot;true&quot;,
            &quot;limit&quot;: 5,
            &quot;minLength&quot;: 1,
            &quot;extraEventData&quot;: {
                &quot;interaction_location&quot;: &quot;identifier_for_analytics&quot;
            }
        }">
    <label for="searchField" class="alt">Zoeken</label>
    <div class="searchForm__field">
        <input id="searchField" name="q" type="search" class="searchField" placeholder="Waar kunnen we u mee helpen?" autocomplete="off" />
        <div class="searchForm__clear">
            <a href="javascript:void(0)" class="clearButton clearButton--grey">
                <span class="icon">
                    <span class="alt">Veld leegmaken</span>
                </span>
            </a>
        </div>
    </div>
    <div class="searchForm__submit">
        <button type="submit" class="button">
            Zoeken
        </button>
    </div>
</form>

*/

.autosuggest {
    display: inline-block;
    font-size: toRem($font-size-base);
    width: 100%;
    vertical-align: top;
}

/*  autosuggest dropdown */
//  The dropdown-menu class is used by the angular-bootstrap typeahead plugin
.autosuggest__list,
.dropdown-menu {
    position: absolute;
    display: none; // standard the list must not be shown
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: $white;
    border: $input-border;
    border-radius: $input-border-radius;
    margin-top: toRem(2px);
    left: 0;
    box-shadow: 0 toRem(2px) toRem(25px) rgba($ns-black, 0.15);
    z-index: z(overlay) + 1;
    overflow: hidden;
}

// style the Item in the list
.autosuggest__listItem,
.dropdown-menu li {
    margin: 0;
    padding-left: 0;
}

// style the link in the item list and position it
.autosuggest__link {
    @include un-anchorlink;
    // add padding to anchor, so that the whole area is clickable
    padding: toRem($global--grid-halfgutter - 2px);
    padding-left: toRem($icon-size-base + ($tile-space * 0.5) - 1px);
    display: block;

    // styles required for .autosuggest__link elements which are no links.
    color: $ns-light-blue;
    cursor: pointer;
}

.autosuggest__link--station {
    @include createIconBg('train', $ns-blue);

    background-repeat: no-repeat;
    background-position: toRem(10px);

    li.active & {
        @include createIconBg('train', $ns-white);
    }
}

.autosuggest__link--adres {
    @include createIconBg('street', $ns-blue);

    background-repeat: no-repeat;
    background-position: toRem(10px);

    li.active & {
        @include createIconBg('street', $ns-white);
    }
}

.autosuggest__link--poi {
    @include createIconBg('poi', $ns-blue);

    background-repeat: no-repeat;
    background-position: toRem(10px);

    li.active & {
        @include createIconBg('poi', $ns-white);
    }
}

.autosuggest__link--halte {
    @include createIconBg('halte', $ns-blue);

    background-repeat: no-repeat;
    background-position: toRem(10px);

    li.active & {
        @include createIconBg('halte', $ns-white);
    }
}

// match the input parent to its parent field
.twitter-typeahead {
    width: 100%;
}

.autosuggest__input {
    // class for future styling
    z-index: z('autosuggest') - 1;
}

// this is the hint aka placeholder text that gets a grey color
.autosuggest__hint {
    color: $ns-grey-60;
    background: none; // we have to set this, or else typeahead will copy our icon into inline-styles
    z-index: z('autosuggest') - 2;
}

.autosuggest__status {
    @include visuallyhidden;
}

.autosuggest__menu {
    // class for future styling
}

.autosuggest__dataset {
    // class for future styling
}

.autosuggest__suggestion {
    // class for future styling
}

.autosuggest__empty {
    // class for future styling
}

.autosuggest__open {
    // class for future styling
}

//  when autosuggest is selected with keyboard, it will get this class
//  The dropdown-menu class is used by the angular-bootstrap typeahead plugin
.autosuggest__cursor,
.dropdown-menu li.active {
    background-color: $ns-light-blue;
    color: $white;

    .autosuggest__link {
        color: $white;
    }
}

.autosuggest__highlight {
    // class for future styling
}

.autosuggest__selectable {
    // class for future styling
}
