// ---------------------------------------------------------------------------
//  Wrapper for a group of buttons. Adds the correct margins around the
//  buttons, which in themselves should define no margins.
// ---------------------------------------------------------------------------

.group {
    display: table;
}

.group__item {
    display: table-cell;
}

.group--fullWidth {
    width: 100%;
}

.group--verticalMiddle > .group__item,
.group__item--middle {
    vertical-align: middle;
}

.group--flex {
    // TODO: Make better when we get a use-case for this
    width: 100%;

    > .group__item {
        width: toRem(1px); // faux min-width for table-cell
        > * {
            white-space: nowrap;
        }

        &.group__item-20 {
            width: 20%;
        }

        &.group__item-40 {
            width: 40%;
        }

        &.group__item-60 {
            width: 60%;
        }

        &.group__item-80 {
            width: 80%;
        }
    }
}

.group--spaceAround {
    > .group__item {
        padding: toRem($font-size-base * 0.5);
    }
}

.group--spreadItems {
    .group__item {
        text-align: center;

        &:first-child {
            text-align: left;
        }

        &:last-child {
            text-align: right;
        }
    }
}

.group--spaceBetween {
    > .group__item {
        padding: toRem($font-size-base * 0.5);

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}
