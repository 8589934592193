$display-values: (
    'n': 'none',
    'i': 'inline',
    'b': 'block',
    'ib': 'inline-block'
);


// We don't need all breakpoints
$display-breakpoints: (
    'sm': $sm,
    'm': $m,
    'ml': $ml,
    'l': $l,
    'xl': $xl
);

@mixin display-line($name, $property) {
    .h-display-#{$name} {
        display: #{$property} !important;
    }
}

@mixin render-display-helpers() {
    // Generate default helpers
    @each $display-name, $display-property in $display-values {
        @include display-line(#{$display-name}, $display-property);
    }

    // Generate responsive helpers
    @each $breakpoint-name, $breakpoint in $display-breakpoints {
        @include respond-min(#{$breakpoint}) {
            @each $display-name, $display-property in $display-values {
                @include display-line(#{$display-name}-#{$breakpoint-name}, $display-property);
            }
        }
    }
}

@include render-display-helpers();
