.notificationGroup {
    background: none;
    color: $ns-blue;
    padding: 0 toRem($tile-space);

    @include respond-min($m) {
        margin-right: toRem(265px);
        display: inline-block;
    }

    @include respond-min($l) {
        margin-right: toRem(480px);
    }
}

.notificationGroup__label {
    margin-right: toRem($icon-padding * 2); //creates the spacing between the first item
    display: inline-block;
}

.notificationGroup__list {
    display: inline-block;
    vertical-align: bottom;
}

.notificationGroup__item {
    margin-right: toRem($icon-padding * 3); //creates the spacing between disruptions.
    float: left;
    list-style: none;
    padding-bottom: 0;
    margin-bottom: 0;

    .icon {
        margin-bottom: toRem(-12px); //get the icon right in place vertically
        margin-left: toRem(- $icon-padding); //pushes the icon to the side
    }
}

// container modifier for notificationGroup
.container--notification {
    @include respond-min($m) {
        margin-top: toRem(-$tile-space * 2);
    }
}
