/*
@name Body Item
@description
Body item blocks are components that can contain text (e.g.
headings and paragraphs) and media (e.g. pictures and videos).
This example contains text with a left/right aligned image.

* For a right image: use `bodyItem__text--left` and `bodyItem__image--right`
* For a left image: use `bodyItem__text--right` and `bodyItem__image--left`
* To center the text (use only without images): use `bodyItem__text--centered`

@section Components
@type UI
@extended false
@className bodyItem

@modifier .bodyItem__image--useBorder - Variant that makes the image overlap the container border. Also use the appropiate classes on bodyItemImage (bodyItemImage--right or bodyItemImage--useBorder)

@markup
<section class="bodyItem bodyItem--textAndMedia">
    <div class="bodyItem__text bodyItem__text--right">
        <div class="richText" data-controller="generic/ui/RichText" data-type="cms-Tekst">
            <h2>Winteractie: eenmalig € 5 korting op uw ritprijs</h2>
            <p>Deze winter liever niet op de fiets door de kou en regen? NS Zonetaxi brengt u comfortabel en warm van én naar meer dan 130 stations.</p>
        </div>
    </div>
    <div class="bodyItem__image bodyItem__image--left">
        <div class="bodyItemImage {{modifier_class}}">
            <picture class="bodyItemImage__holder">
                <source srcset="<%= path.styleguideStatic %>/images/reisinfo/reisinformatie_detail_iphone_notificaties_372x360.png" media="(min-width: <%= breakpoints.large %>px)">
                <source srcset="<%= path.styleguideStatic %>/images/reisinfo/reisinformatie_detail_iphone_notificaties_250x242.png" media="(min-width: <%= breakpoints.medium %>px)">
                <source srcset="<%= path.styleguideStatic %>/images/reisinfo/reisinformatie_detail_iphone_notificaties_388x376.png" media="(min-width: <%= breakpoints.small %>px)">
                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" class="bodyItemImage__img" alt="iPhone met NS notificaties">
            </picture>
        </div>
    </div>
</section>
*/

@use 'sass:math';

$bodyItem-element-spacing: $global--grid-gutter;
$bodyItem-header-bottom: 20px;

$bodyItem-header-top: 60px; //H2 top-margin & tabel bottom-margin
$bodyItem-header-top-mobile: $bodyItem-header-top - 20px;

@mixin addPaddingTo($side) {
    @include respond-min($m) {
        padding-#{$side}: toRem($global--grid-halfgutter);
    }
}

.bodyItem {
    margin-bottom: toRem($bodyItem-element-spacing);

    @include respond-min($m) {
        margin-bottom: toRem(2 * $bodyItem-element-spacing);
    }

    &:last-child {
        margin-bottom: 0;

        @include respond-min($m) {
            margin-bottom: 0;
        }
    }

    .buttonGroup:only-child {
        @include respond-min($m) {
            margin-top: toRem(($font-size-base * 2) - ($bodyItem-element-spacing * 2));
        }
    }

    &:not(.h-mb-0):not(.bodyItem--articleIntro) + .articleSeparator {
        @include respond-min($m) {
            margin-top: toRem((-$bodyItem-element-spacing));
        }
    }
}

// space between button group and separator
.bodyItem--articleIntro {
    margin-bottom: 0;
    padding-bottom: toRem($global--grid-quartergutter);

    @include respond-min($m) {
        margin-bottom: 0;
        padding-bottom: toRem($global--grid-halfgutter);
    }

    .buttonGroup:last-child {
        margin-bottom: 0;
    }
}

.bodyItem--video {
    .container--gridWidth & {
        @include respond-min($m) {
            padding-left: toRem($global--grid-halfgutter);
            padding-right: toRem($global--grid-halfgutter);
        }
    }

    @include respond-min($l) {
        padding-left: percentage(math.div(1, 12));
        padding-right: percentage(math.div(1, 12));
    }
}

.bodyItem--textAndMedia {
    @include clearfix;
}

.bodyItem--textAndMediaVideo {
    display: flex;
    flex-direction: column-reverse;

    @include respond-min($m) {
        @include clearfix;

        display: block;
    }
}

.bodyItem__text {
    clear: both;

    .container--gridWidth & {
        @include respond-min($m) {
            padding-left: toRem($global--grid-halfgutter);
            padding-right: toRem($global--grid-halfgutter);

            .tableContainer--fade {
                margin-right: toRem(-($tile-space + $global--grid-halfgutter));

                .tableContainer__content {
                    padding-right: toRem($tile-space + $global--grid-halfgutter);
                }

                .tableContainer__fadeElement {
                    width: toRem($tile-space + $global--grid-halfgutter);
                }
            }
        }
    }

    @include respond-min($l) {
        padding-left: percentage(math.div(1, 12));
        padding-right: toRem($tile-space + $global--grid-halfgutter);
        width: percentage(math.div(8, 12));

        .tableContainer--fade {
            margin-right: toRem(-($tile-space + $global--grid-halfgutter));

            .tableContainer__content {
                padding-right: toRem($tile-space + $global--grid-halfgutter);
            }

            .tableContainer__fadeElement {
                width: toRem($tile-space + $global--grid-halfgutter);
            }
        }
        .container--gridWidth & {
            padding-left: toRem($tile-space + $global--grid-halfgutter);
            padding-right: toRem($tile-space + $global--grid-halfgutter);
        }
    }

    &.bodyItem__text--centered {
        text-align: center;
        margin: 0 auto;
        padding-right: 0;
        padding-left: 0;

        @include respond-min($m) {
            .container--gridWidth & {
                .tableContainer--fade {
                    margin-right: toRem(-$tile-space);

                    .tableContainer__content {
                        padding-right: toRem($tile-space);
                    }

                    .tableContainer__fadeElement {
                        width: toRem($tile-space);
                    }
                }
            }
        }
        @include respond-min($l) {
            width: percentage(math.div(7, 12));
            padding-right: toRem(($tile-space * 0.5) + $global--grid-quartergutter);
            padding-left: toRem(($tile-space * 0.5) + $global--grid-quartergutter);

            .tableContainer--fade {
                margin-right: toRem(-(($tile-space * 0.5) + $global--grid-quartergutter));

                .tableContainer__content {
                    padding-right: toRem(($tile-space * 0.5) + $global--grid-quartergutter);
                }

                .tableContainer__fadeElement {
                    width: toRem(($tile-space * 0.5) + $global--grid-quartergutter);
                }
            }
            .container--gridWidth & {
                width: percentage(math.div(8, 12));
                padding-left: toRem($tile-space + $global--grid-halfgutter);
                padding-right: toRem($tile-space + $global--grid-halfgutter);

                .tableContainer--fade {
                    margin-right: toRem(-($tile-space + $global--grid-halfgutter));

                    .tableContainer__content {
                        padding-right: toRem($tile-space + $global--grid-halfgutter);
                    }

                    .tableContainer__fadeElement {
                        width: toRem($tile-space + $global--grid-halfgutter);
                    }
                }
            }
        }
    }

    > h2 {
        margin-bottom: toRem($bodyItem-header-bottom); // 20px
        margin-top: toRem($bodyItem-header-top-mobile);

        @include respond-min($m) {
            margin-top: toRem($bodyItem-header-top);
        }
    }
}

// Targets first h2/h3 in the first bodyItem
// that is not an article intro
// and after an articleSeparator
.bodyItem {
    .articleSeparator + &,
    &:first-child:not(.bodyItem--articleIntro) {
        .bodyItem__text {
            h3:first-child,
            h2:first-child {
                margin-top: 0;
            }
        }
    }
}

.bodyItem__text--left {
    @include respond-min($m) {
        float: left;

        @include addPaddingTo('right');
    }

    .tableContainer--fade,
    .container--gridWidth & .tableContainer--fade {
        @include respond-min($m) {
            margin-right: toRem(-$global--grid-halfgutter);
        }

        @include respond-min($l) {
            margin-right: toRem(-($tile-space + $global--grid-halfgutter));
        }

        .tableContainer__content {
            @include respond-min($m) {
                padding-right: toRem($global--grid-halfgutter);
            }

            @include respond-min($l) {
                padding-right: toRem($tile-space + $global--grid-halfgutter);
            }
        }

        .tableContainer__fadeElement {
            @include respond-min($m) {
                width: toRem($global--grid-halfgutter);
            }

            @include respond-min($l) {
                width: toRem($tile-space + $global--grid-halfgutter);
            }
        }
    }

    @include respond-min($l) {
        padding-left: percentage(math.div(1, 12));
        padding-right: toRem($tile-space + $global--grid-halfgutter);
    }
}

.bodyItem__text--right {
    @include respond-min($m) {
        float: right;

        @include addPaddingTo('left');
    }

    @include respond-min($l) {
        padding-right: percentage(math.div(1, 12));
        padding-left: toRem($tile-space + $global--grid-halfgutter);
    }

    .tableContainer--fade {
        @include respond-min($m) {
            .container--gridWidth & {
                margin-right: toRem(-($tile-space + $global--grid-halfgutter));
            }
        }

        @include respond-min($l) {
            // We know the parent width = 5/12 - ($tile-space + $global--grid-halfgutter).
            // Using that knowledge we can calculate the 1/12 used in the padding-right on the bodyItem__text.
            margin-right: calc(-1 * (100% + #{toRem($tile-space + $global--grid-halfgutter)}) / 5);

            .container--gridWidth & {
                margin-right: toRem(-($tile-space + $global--grid-halfgutter));
            }
        }

        .tableContainer__content {
            @include respond-min($m) {
                .container--gridWidth & {
                    padding-right: toRem($tile-space + $global--grid-halfgutter);
                }
            }

            @include respond-min($l) {
                // We know the parent width = 6/12 - ($tile-space + $global--grid-halfgutter).
                padding-right: calc((100% + #{toRem($tile-space + $global--grid-halfgutter)}) / 6);

                .container--gridWidth & {
                    padding-right: toRem($tile-space + $global--grid-halfgutter);
                }
            }
        }

        .tableContainer__fadeElement {
            @include respond-min($m) {
                .container--gridWidth & {
                    width: toRem($tile-space + $global--grid-halfgutter);
                }
            }

            @include respond-min($l) {
                // We know the parent width = 6/12 - ($tile-space + $global--grid-halfgutter).
                width: calc((100% + #{toRem(($tile-space + $global--grid-halfgutter))}) / 6);

                .container--gridWidth & {
                    width: toRem($tile-space + $global--grid-halfgutter);
                }
            }
        }
    }
}

.bodyItem__text--left,
.bodyItem__text--right {
    @include respond-min($m) {
        width: percentage(math.div(8, 12));
    }

    &.bodyItem__text--1-2 {
        @include respond-min($m) {
            width: percentage(math.div(6, 12));
        }
    }

    &.bodyItem__text--1-3 {
        @include respond-min($m) {
            width: percentage(math.div(4, 12));
        }
    }
}

.bodyItem__text--aside {
    clear: none;

    @include addPaddingTo('left');

    margin-bottom: toRem($bodyItem-element-spacing);

    @include respond-min($m) {
        float: right;
        width: percentage(math.div(4, 12));
    }

    @include respond-min($l) {
        margin-left: 0;
        margin-right: 0;
        padding-right: percentage(math.div(1, 12));
    }
}

//Created to push content on the same height as the breadcrumb & header
.bodyItem__text--headerPush {
    @include respond-min($m) {
        padding-top: toRem(44.8px);
    }
}

.bodyItem__image {
    margin-bottom: toRem($bodyItem-element-spacing);

    @include respond-min($m) {
        width: percentage(math.div(4, 12));
        margin-bottom: 0;
    }

    &.bodyItem__image--useBorder {
        @include respond-min($m) {
            width: calc(#{percentage(math.div(4, 12))} + #{toRem($tile-space)});
        }

        @include respond-min($l) {
            width: percentage(math.div(4, 12));
        }
    }

    &.bodyItem__image--1-2 {
        @include respond-min($m) {
            width: percentage(math.div(6, 12));
        }

        &.bodyItem__image--useBorder {
            @include respond-min($m) {
                width: calc(#{percentage(math.div(6, 12))} + #{toRem($tile-space)});
            }

            @include respond-min($l) {
                width: percentage(math.div(6, 12));
            }
        }
    }

    &.bodyItem__image--2-3 {
        @include respond-min($m) {
            width: percentage(math.div(8, 12));
        }

        &.bodyItem__image--useBorder {
            @include respond-min($m) {
                width: calc(#{percentage(math.div(8, 12))} + #{toRem($tile-space)});
            }

            @include respond-min($l) {
                width: percentage(math.div(8, 12));
            }
        }
    }
}

.bodyItem__image--left {
    .container--gridWidth & {
        @include respond-min($m) {
            padding-left: toRem($global--grid-halfgutter);
            padding-right: toRem($global--grid-halfgutter);
        }
    }

    @include addPaddingTo('right');

    @include respond-min($m) {
        float: left;
    }

    &:not(.bodyItem__image--useBorder) {
        @include respond-min($l) {
            padding-left: percentage(math.div(1, 12));

            .container--gridWidth & {
                padding-left: toRem($global--grid-halfgutter);
            }
        }
    }

    &.bodyItem__image--useBorder {
        margin-left: toRem(-$tile-space);

        @include respond-min($l) {
            margin-left: 0;
        }
    }
}

.bodyItem__image--right {
    .container--gridWidth & {
        @include respond-min($m) {
            padding-left: toRem($global--grid-halfgutter);
            padding-right: toRem($global--grid-halfgutter);
        }
    }

    @include addPaddingTo('left');

    @include respond-min($m) {
        float: right;
    }

    &:not(.bodyItem__image--useBorder) {
        @include respond-min($l) {
            padding-right: percentage(math.div(1, 12));

            .container--gridWidth & {
                padding-right: toRem($global--grid-halfgutter);
            }
        }
    }

    &.bodyItem__image--useBorder {
        margin-right: toRem(-$tile-space);

        @include respond-min($l) {
            margin-right: 0;
        }
    }
}

.bodyItem__image--video {
    position: relative;
    // fill to 100% on small breakpoints
    float: none;

    &.bodyItem__image--left {
        @include respond-min($m) {
            float: left;
        }
    }

    &.bodyItem__image--right {
        @include respond-min($m) {
            float: right;
        }
    }
}

.bodyItem__button-right {
    @include respond-min($m) {
        text-align: right;
    }
}

.bodyItem__textIntro {
    @include font-size('intro-text');
}

.bodyItem--mediaAndMedia {
    display: grid;
    grid-template-rows: repeat(2, auto);
    gap: toRem($global--grid-halfgutter) 0;

    @include respond-min($m) {
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: auto;
        gap: 0 toRem($global--grid-gutter);
    }

    @include respond-min($l) {
        padding-right: calc(#{percentage(math.div(1, 12))} - #{toRem($global--grid-halfgutter)});
        padding-left: calc(#{percentage(math.div(1, 12))} - #{toRem($global--grid-halfgutter)});
    }

    .bodyItem__media {
        &.bodyItem__media--1-2 {
            @include respond-min($m) {
                grid-column: auto / span 6;
            }
        }

        &.bodyItem__media--2-3 {
            @include respond-min($m) {
                grid-column: auto / span 6;
            }

            @include respond-min($l) {
                grid-column: auto / span 8;
            }
        }

        @include respond-min($m) {
            grid-column: auto / span 6;
        }

        @include respond-min($l) {
            grid-column: auto / span 4;
        }
    }

    .container--gridWidth & {
        @include respond-min($m) {
            padding-right: toRem($global--grid-halfgutter);
            padding-left: toRem($global--grid-halfgutter);
        }
    }
}

.bodyItem--threeMedia {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: toRem($global--grid-halfgutter);

    @include respond-min($m) {
        gap: toRem($global--grid-gutter);
    }

    @include respond-min($l) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        gap: 0 toRem($global--grid-gutter);
        padding-right: calc(#{percentage(math.div(1, 12))} - #{toRem($global--grid-halfgutter)});
        padding-left: calc(#{percentage(math.div(1, 12))} - #{toRem($global--grid-halfgutter)});
    }

    .bodyItem__media {
        &:first-child {
            grid-column: auto / span 2;

            @include respond-min($l) {
                grid-column: auto;
            }
        }
    }

    .container--gridWidth & {
        @include respond-min($m) {
            padding-right: toRem($global--grid-halfgutter);
            padding-left: toRem($global--grid-halfgutter);
        }
    }
}

.bodyItem--usps {
    display: grid;
    grid-template-rows: repeat(3, auto);
    gap: toRem($tile-space) 0;

    @include respond-min($m) {
    }

    @include respond-min($ml) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, auto);
        gap: 0 toRem($global--grid-gutter);
    }

    @include respond-min($l) {
        padding-right: calc(#{percentage(math.div(1, 12))} - #{toRem($global--grid-halfgutter)});
        padding-left: calc(#{percentage(math.div(1, 12))} - #{toRem($global--grid-halfgutter)});
    }

    .container--gridWidth & {
        @include respond-min($m) {
            padding-left: toRem($global--grid-halfgutter);
            padding-right: toRem($global--grid-halfgutter);
        }
    }
}

.bodyItem--quote {
    @include respond-min($ml) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, auto);
        gap: 0 toRem($global--grid-gutter);
    }

    @include respond-min($l) {
        padding-right: calc(#{percentage(math.div(1, 12))} - #{toRem($global--grid-halfgutter)});
        padding-left: calc(#{percentage(math.div(1, 12))} - #{toRem($global--grid-halfgutter)});
    }

    .container--gridWidth & {
        @include respond-min($m) {
            padding-left: toRem($global--grid-halfgutter);
            padding-right: toRem($global--grid-halfgutter);
        }
    }
}
