/* ---------------------------------------------------------------------------

  ICONS - TABLE OF CONTENT
  A lot happens here....

 ---------------------------------------------------------------------------

 1. Defining the types of icons
 2. Defining colors of icons
 3. Defining which icons get extended
 4. Create classes for icons defined in list in 3.
 5. Icons with 'use'
 6. Icons using background-image
 7. Positioning of icons
 8. Sizing of icons
 9. Relative positioning of icons
 10. Create some extra classes to output all icons (in styleguide only)
 11. Display all icons
 12. Display inline-icons

*/

/* 1. --------------------------------------------------------------------------- */

/*
Icons as background images

The primary method of including icons is to use an SVG image as background of a span. This is meant for purely decorative uses of icon graphics, which is
the case in well near 90% of all uses in applications like CMS, e-commerce etc.

All icons have two main characteristics: Their *graphic shapes* and their *color*. Both can be specified as modifier-classes to your `.icon`. For example
modifier classes for graphic shapes could be: `.icon--file` or `.icon--cart`, while modifiers for color could be `.icon--nsBlue` or `.icon--nsGreen`.

From sass, an icon graphic+color combination must be explictly made available in the `_icons.scss` file in NSDesign as any combination will add to the
downloaded filesize.

Some examples below. Note in the HTML example how fallback-text for screenreaders should be implemented.

@name Icon
@description
Icon class to render icons based on CSS `background-image` property. To see a full list of all available icons, check our [legacy page](../fundamentals/icons-legacy.html) on the fundamentals icon section. Not all icons are available in all colors.

@section Objects
@type UI
@extended false

@markup
<div class="icon icon--alert {{modifier_class}}">
    <span class="alt">This is screenreader text</span>
</div>

@modifier .icon--nsBlue         - A blue icon
@modifier .icon--nsRed          - A red icon
@modifier .icon--nsWhite        - A white icon

/*

    Generating icons in different colors will add substantially to filesize, please be careful.
    Only introduce a new color after careful delibaration.
    To generate an icon without color, add 'false' to the color list.

    Currently acceptable colors are: nsWhite, nsLightBlue, nsBlue, nsRed, nsGrey7 (and false).
*/

/* 2. --------------------------------------------------------------------------- */

/*
    Generating icons in different colors will add substantially to filesize, please be careful.
    Only introduce a new color after careful delibaration.
    To generate an icon without color, add 'false' to the color list.

    Currently acceptable colors are: nsWhite, nsLightBlue, nsBlue, nsRed, nsGrey7 (and false).
*/
$bg-icons: (
    'alert': (
        'ns-white',
        'ns-yellow',
        'ns-blue',
        'ns-red-alert',
        'nsWhite',
        'nsRed',
        'nsBlue'
    ),
    'arrow': (
        'nsBlue'
    ),
    'audience-large': (
        'nsBlue'
    ),
    'bike': (
        'nsBlue',
        'nsWhite'
    ),
    'bus': (
        'nsBlue',
        'nsWhite'
    ),
    'calendar': (
        'nsLightBlue',
        'nsBlue'
    ),
    'calendar-medium': (
        'nsBlue'
    ),
    'car': (
        'nsBlue',
        'nsWhite'
    ),
    'cart': (
        'ns-blue',
        'nsLightBlue',
        'nsBlue',
        'nsGrey5'
    ),
    'cartFilled': (
        'nsLightBlue',
        'nsBlue'
    ),
    'cartFilledMedium': (
        'nsLightBlue',
        'nsBlue'
    ),
    'cartFilledLarge': (
        'nsLightBlue',
        'nsBlue'
    ),
    'chat': (
        'ns-white',
        'nsWhite'
    ),
    'checkmark': (
        'nsGreen'
    ),
    'checkmark-circle': (
        'nsGreen'
    ),
    'chevron-large': (
        'ns-white',
        'ns-light-blue',
        'ns-blue',
        'nsWhite',
        'nsLightBlue',
        'nsBlue'
    ),
    'chevron-medium': (
        'ns-white',
        'ns-light-blue',
        'ns-blue',
        'nsWhite',
        'nsLightBlue',
        'nsBlue',
        'nsGrey4',
        'nsGrey5',
        'nsRed'
    ),
    'chevron-down-medium': (
        'nsWhite',
        'nsLightBlue',
        'nsBlue'
    ),
    'chevron-down-medium-mask': (
        'nsLightBlue',
        'nsBlue'
    ),
    'chevron-mini': (
        'ns-white',
        'ns-light-blue',
        'ns-blue',
        'nsWhite',
        'nsLightBlue',
        'nsBlue',
        'nsGrey4',
        'nsGrey7'
    ),
    'children': (
        'nsBlue'
    ),
    'clock': (
        'ns-white',
        'ns-blue',
        'nsWhite',
        'nsBlue',
        'nsRed',
        'nsGrey7'
    ),
    'clock-small': (
        'nsBlue',
        'nsGrey4',
        'nsGrey5',
        'nsRed'
    ),
    'close': (
        'nsWhite',
        'nsBlue'
    ),
    'crossborder': (
        'nsBlue'
    ),
    'customerservice-large': (
        'ns-blue',
        'nswhite',
        'nsBlue',
        'nsWhite'
    ),
    'discount': (
        'nsBlue'
    ),
    'discount-dates': (
        'nsBlue'
    ),
    'durability': (
        'nsGreen'
    ),
    'elephant': (
        'nsBlue'
    ),
    'ferry': (
        'ns-white',
        'nsWhite',
        'nsBlue'
    ),
    'file': (
        'ns-blue',
        'nsBlue'
    ),
    'file-pdf': (
        'nsLightBlue',
        'nsBlue'
    ),
    'filter': (
        'nsLightBlue',
        'nsBlue'
    ),
    'footnote-arrow': (
        'nsLightBlue'
    ),
    'group': (
        'nsBlue'
    ),
    'halte': (
        'nsBlue',
        'nsWhite'
    ),
    'hike-large': (
        'nsBlue'
    ),
    'icdirect': (
        'nsBlue'
    ),
    'info': (
        'ns-white',
        'nsWhite',
        'nsLightBlue',
        'nsBlue',
        'nsGrey4',
        'nsGrey5'
    ),
    'magnifier': (
        'ns-white',
        'ns-blue',
        'ns-light-blue',
        'nsWhite',
        'nsLightBlue',
        'nsBlue'
    ),
    'maintenance': (
        'ns-red-alert',
        'nsRed'
    ),
    'menu-dots': (
        'nsLightBlue',
        'nsGrey5'
    ),
    'minus': (
        'nsBlue'
    ),
    'negen': (
        false
    ),
    'netherlands': (
        'nsBlue'
    ),
    'ovchipkaart': (
        false
    ),
    'overcheck': (
        'nsGrey4'
    ),
    'play': (
        'nsWhite'
    ),
    'play-solid': (
        'nsWhite'
    ),
    'plus': (
        'nsBlue'
    ),
    'poi': (
        'nsBlue',
        'nsWhite'
    ),
    'print': (
        'nsBlue',
        'nsLightBlue',
        'nsRed'
    ),
    'products-large': (
        'ns-blue',
        'nsBlue'
    ),
    'public-transport': (
        'nsBlue'
    ),
    'refresh': (
        'nsLightBlue',
        'nsBlue'
    ),
    'route': (
        'nsBlue'
    ),
    'share': (
        'nsLightBlue'
    ),
    'stopwatch': (
        'nsBlue',
        'nsGrey7',
        'nsGrey5'
    ),
    'student': (
        'nsBlue'
    ),
    'street': (
        'nsBlue',
        'nsWhite'
    ),
    'subscription': (
        'nsBlue'
    ),
    'subscription-2': (
        'nsBlue'
    ),
    'subscription-3': (
        'nsBlue'
    ),
    'switch': (
        'nsLightBlue',
        'nsBlue'
    ),
    'ticket': (
        'nsBlue'
    ),
    'train': (
        'ns-blue',
        'nsBlue',
        'nsRed',
        'nsGrey6',
        'nsWhite'
    ),
    'train-medium': (
        'nsBlue'
    ),
    'transfer-small': (
        'nsBlue',
        'nsGrey4',
        'nsGrey5'
    ),
    'user': (
        'ns-blue',
        'nsRed'
    ),
    'volume_level2': (
        'nsBlue'
    ),
    'voucher': (
        'nsBlue'
    ),
    'windmill': (
        'nsGreenOnGrey7'
    ),
    'walk': (
        'nsBlue',
        'nsWhite'
    )
);

/* 3. --------------------------------------------------------------------------- */

@each $name, $colors in $bg-icons {
    @each $color in $colors {
        @include createIconClass($name, $color);
    }
}

/* 6. --------------------------------------------------------------------------- */

%icon {
    @include icon();
}

.icon {
    @extend %icon;
    //text-indent: 100%;
    text-align: left; // overrule default centered alignment of <button>
    overflow: hidden;
    white-space: nowrap;
}

.icon--rotate180 {
    transform: rotate(180deg);
}

.icon--rotate270 {
    transform: rotate(270deg);
}

/* 7. --------------------------------------------------------------------------- */

/*
Icon - Sizing

We have (roughly) four sizes of icon: 'default', 'small', 'large' and 'xlarge'.

- Default: 25x25 pixels (15x15 primary area)
- Small:   35x35 pixels (21x21 primary area)
- Large:   50x50 pixels (30x30 primary area)
- xLarge:  70x70 pixels (42x42 primary area)

Markup:
<div class="icon {{modifier_class}} icon--file icon--ns-blue">Icon</div>

.icon--small                 - Small icon
.icon--large                 - Large icon
.icon--xlarge                - Extra large icon

// Styleguide 1.4.1
*/

.icon--small {
    width: toRem($icon-size-small);
    height: toRem($icon-size-small);
}

.icon--large {
    width: toRem($icon-size-large);
    height: toRem($icon-size-large);
}

.icon--xlarge {
    width: toRem($icon-size-xlarge);
    height: toRem($icon-size-xlarge);
}

/* 8. --------------------------------------------------------------------------- */

/*
Icons and text

Markup:
<p>
    <span class="icon {{modifier_class}} icon--file icon--nsBlue">
        <span class="alt">File</span>
    </span>
    This might be a download-link for a generic file
</p>
<p>
    <span class="icon {{modifier_class}} icon--alert icon--nsRed">
        <span class="alt">File</span>
    </span>
    This is a warning - watch out behind you!!
</p>
<p>
    <span class="icon {{modifier_class}} icon--magnifier icon--nsBlue">
        <span class="alt">File</span>
    </span>
    Would you like to search this site?
</p>

.icon--inline   - The icon lines up with the text surrounding it

// Styleguide 1.4.2
*/

%icon-inlineBase {
    vertical-align: middle;
    margin-top: toRem(($line-height-base - $icon-size-base) * 0.5 - 1px);
    margin-bottom: toRem(($line-height-base - $icon-size-base) * 0.5 + 1px);
    margin-left: toRem(-1 * $icon-padding);
}

.icon--inline {
    @extend %icon-inlineBase;

    margin-right: toRem(-1 * ($icon-padding - 4px));

    &.icon--inline-closer {
        margin-right: toRem(-1 * ($icon-padding));
    }
}

.icon--closeInline {
    @extend %icon-inlineBase;

    margin-right: toRem(-1 * ($icon-padding + 1));
}

.icon--inlineHeader {
    @extend %icon-inlineBase;

    display: inline-block;

    &.icon--xlarge {
        margin-top: toRem(-1 * ($icon-padding-xlarge + 4px));
        margin-bottom: toRem(-1 * $icon-padding-xlarge);
        margin-left: toRem(-1 * $icon-padding-xlarge);
        margin-right: toRem(-0.5 * $icon-padding-xlarge);
    }
}

/*
1. __As an SVG with the `use`-tag:__ do this for icons that would be informative in your markup (eg for screenreaders).
This compares to the third option as an 'oldskool' inline image `<img />` would compare to a background-sprite.
We store the use-svgs in a subfolder inside the SVG-folder named 'use'. The SVG's stored in this folder will be gathered and parsed into svg-defs.svg.
2. __As an SVG background image:__ use this for purely decorative icons (eg the chevron on a button). Another reason to use this solution would be to prevent unnecessary code-duplication.
SVG's that require an `.icon--XXXX`-class will live in a list (below) that will extend the silent class into a 'loud' class.

__All SVG's__ stored in the svg-folder (so including the ones in 'use') __will 'live' as a silent class__ in our SASS library, and can be extended from any app.

Markup:
<svg style="width:50px;height:50px;">
    <use xlink:href="<%= path.static %>/svg/svg-defs.svg#icon-chat"></use>
</svg><br>
<div class="icon--b icon--clock--ns-blue">icon--clock--ns-blue</div><br>
<div class="icon--b icon--chat">icon--chat</div>
<div style="width:50px;height:50px;">
    <svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
        <g>
            <path d="M39.7,25c0,8.1-6.6,14.7-14.7,14.7c-8.1,0-14.7-6.6-14.7-14.7c0-8.1,6.6-14.7,14.7-14.7C33.1,10.3,39.7,16.9,39.7,25z
         M36.5,25c0-6.4-5.1-11.5-11.5-11.5c-6.3,0-11.5,5.1-11.5,11.5c0,6.3,5.2,11.5,11.5,11.5C31.4,36.5,36.5,31.3,36.5,25z M26.2,24.5
        l4.8,4.8l-1.6,1.6l-5.4-5.4v-8.8h2.2V24.5z"/>
        </g>
    </svg>
</div>
*/

.icon__svg {
    width: 100%;
    height: 100%;
}

.icon__svg--fillCurrent {
    fill: currentColor;
}

/* 9. --------------------------------------------------------------------------- */

/*
Icon and text on a single line.

Used as single information line with an icon on the left side
This is reused by inlineNotifications

Markup:
    <p class="iconizedDetail iconizedDetail--blue">
        <span class="icon icon--inline icon--walk icon--nsBlue iconizedDetail_icon"></span>
        <span class="iconizedDetail__text">10 of 8,6 km</span>
    </p>

*/

%iconizedTextHolder {
    padding-left: toRem($tile-space);
    position: relative;
}

%iconizedTextHolder--right {
    padding-right: toRem($tile-space);
    position: relative;
}

%iconizedTextIconPosition {
    position: absolute;
    top: 0;
    left: 0;
}

.iconizedText {
    @extend %iconizedTextHolder;

    .iconizedText__icon {
        @extend %iconizedTextIconPosition;
    }

    &.iconizedText--blue {
        color: $ns-blue;
    }
}
