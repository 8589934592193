/*
@name Toggle button
@description styling for composite form html
@section Objects
@type UI
@extended false
@className toggleButton

@markup
 <div class="grid">
    <div class="toggleButton">
        <input type="radio"
               class="toggleButton__input"
               id="input3"
               name="looptijd1"
               value="maand"
      `         checked>
        <label class="toggleButton__label" for="input3" tabindex="-1">1 maand</label>
    </div>
    <div class="toggleButton">
        <input type="radio" class="toggleButton__input" id="input4" name="looptijd1" value="jaar">
        <label class="toggleButton__label" for="input4" tabindex="-1">1 jaar</label>
    </div>
</div>

*/

$toggleButton-width: 76px;
$toggleButton-radius: 4px;

.toggleButton {
    width: 33.33%;
    float: left;
    user-select: none;

    @include respond-min($m) {
        width: auto;
    }
}

.toggleButton__label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding: toRem($button-vertical-space) 0;
    vertical-align: top;
    width: 100%;
    font-weight: normal;
    white-space: nowrap;
    background-color: $button__secondary__background;
    color: $ns-grey;
    box-shadow: 0 toRem(-2px) 0 $button__secondary__rollover inset;
    text-align: center;
    line-height: toRem($button-line-height);
    font-size: toRem($button-font-size);
    border: none;
    text-decoration: none;
    transition: box-shadow 0.15s ease-in-out;

    &:hover {
        text-decoration: none;
        cursor: pointer;
        box-shadow: 0 toRem(-50px) 0 $button__secondary__rollover inset;
    }

    &:focus {
        outline: toRem(1px) dotted $ns-grey-60;
        outline-offset: toRem(0);
    }

    @include respond-min($m) {
        width: toRem($toggleButton-width);
    }

    .toggleButton:first-of-type & {
        border-top-left-radius: $toggleButton-radius;
        border-bottom-left-radius: $toggleButton-radius;
    }

    .toggleButton:last-of-type & {
        border-top-right-radius: $toggleButton-radius;
        border-bottom-right-radius: $toggleButton-radius;
    }
}

.toggleButton__input {
    @extend %visuallyhidden;

    &:focus {
        & + .toggleButton__label {
            outline: toRem(1px) dotted $ns-grey-60;
            outline-offset: toRem(0);
        }
    }

    &:checked {
        & + .toggleButton__label {
            background-color: $button__primary__background;
            color: $button__primary__color;
            box-shadow: 0 toRem(-2px) 0 $button__primary__rollover inset;

            &:hover {
                box-shadow: 0 toRem(-50px) 0 $button__primary__rollover inset;
            }
        }
    }
}
