/*
@name Basic loader
@description A loader/spinner. Uses an embedded SVG as a `background-image`.
@section Objects
@type UI
@extended false
@background transparent
@className basicLoader

@markup
<div class="basicLoader" role="alert" aria-live="assertive">
  <p class="alt">Wordt geladen...</p>
</div>

*/

// #### BASIC LOADER ####
// ======================

// backwards compatible version

// SETTINGS
// --------

// VARS

$basicLoader-bg-size: 40px;

// BASE
// ----

.basicLoader {
    display: flex;
    justify-content: center;

    &::before {
        display: block;
        content: '';

        @include createIconBg('loader', $ns-grey-70);

        background-size: toRem($basicLoader-bg-size);
        background-repeat: no-repeat;
        background-position: center;
        width: toRem($basicLoader-bg-size);
        height: toRem($basicLoader-bg-size);
        animation: rotating 800ms linear infinite;
    }
}
