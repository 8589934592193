// Override classes. Will be imported last so they will have the most chance of overriding
// without too much specificity.
// ---------------------------------------------------------------------------------------
.is-hidden {
    display: none;
}

// specific for legend with an "alt" class
legend.alt {
    display: block;
}
