// FADE-IN

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

// FADE-IN RIGHT

@keyframes fade-in-right {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

// ROTATING

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

// SLIDEUP
@keyframes slideUp {
    from {
        transform: scaleY(1);
        opacity: 1;
    }

    to {
        transform: scaleY(0);
        opacity: 0;
    }
}

// SLIDEDOWN

@keyframes slideDown {
    from {
        transform: scaleY(0);
        opacity: 0;
    }

    to {
        transform: scaleY(1);
        opacity: 1;
    }
}
