/*
@name Collapsible Tile
@description
An element that contains a clickable header and a body element that is toggled visible/invisible upon clicking
For Javascript functionality, this element requires `data-controller="generic/ui/Collapsible"` to be set on the parent container.
This will cause the first child component to become the header and the second to become the body element.

@section Components
@type UI
@extended false
@className collapsibleTile

@markup
<div class="collapsibleTile" data-controller="generic/ui/Collapsible" data-config="{&quot;selectors&quot;: {&quot;headerSelector&quot;: &quot;.collapsibleTile__title&quot;}}">
    <div class="collapsibleTile__title">Title</div>
    <div class="collapsibleTile__body">
        <div class="collapsibleTile__content">Content</div>
    </div>
</div>
*/

.collapsibleTile {
    background-color: $white;
    padding: toRem(15px) 0 toRem(15px);
    position: relative;
    border-bottom: toRem(1px) solid $ns-grey-70;
}

.collapsibleTile__title {
    outline: none; // Outline on whole header creates weird shape, instead outline icon below
    user-select: none; // Prevent doubleclick from "selecting" whitespace

    &:focus {
        &::after {
            @extend %outline;
        }
    }

    .is-collapsible & {
        cursor: pointer;

        &::after {
            background-repeat: no-repeat;
            background-color: $ns-white;
            content: "";
            position: absolute;
            top: toRem(30px);
            right: toRem(-11px);
            height: toRem(35px);
            width: toRem(35px);

            @include createIconBg('chevron-down-medium', $ns-light-blue);
        }

        &.is-active {
            &::after {
                transform: rotate(180deg); // instead of chevron-UP-medium for now, just flip the icon
            }
        }
    }
}

.collapsibleTile__body {
    // No default styling
}

// Content defines the area that's responsible for the real content presentation.
.collapsibleTile__content {
    margin-bottom: toRem(14px);

    .is-collapsible & {
        padding-right: toRem(35px); // Width of the collapse icon
    }
}

.collapsibleTile__content--alignWithIcon {
    padding-left: toRem(55px);
}

/**
 * Padded variant for use outside a grid__unit item
 */
.collapsibleTile--padded {
    padding-left: toRem(15px);
    padding-right: toRem(15px);

    &.is-collapsible {
        .collapsibleTile__title {
            &::after {
                right: toRem(15px);
            }
        }
    }
}

/**
 * Specific styling for USP presentation for /abonnementen/ page
 */
.collapsibleTile--usp {
    .collapsibleTile__title {
        margin-bottom: toRem(3px);

        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 0;

            .icon--inlineHeader {
                vertical-align: top;
                margin-top: toRem(-12px); // Align top of icon with the title
                margin-bottom: toRem(-32px); // Pull up body to be same height as line-height;
            }
        }

        &.is-active {
            margin-bottom: toRem(12px);
        }
    }

    .collapsibleTile__body {
        min-height: toRem(38px); // Height of single line price

        .pricedetails__priceContainer {
            min-height: toRem(38px); // Height of single line price
            padding-left: toRem(55px);
        }
    }

    // After JS initialization
    &.is-collapsible {
        .collapsibleTile__body {
            .collapsibleTile__content {
                display: none;
            }

            .pricedetails {
                margin-bottom: 0;
            }

            .button {
                display: none;
            }

            &.is-expanded {
                .collapsibleTile__content {
                    display: block;
                }

                .pricedetails {
                    margin-bottom: toRem(12px);
                }

                .button {
                    display: block;
                }
            }
        }
    }

    // Specific styling for a USP tile outside a grid item
    .grid__unit--s-noTopBottomPush:first-child &.collapsibleTile--padded,
    .grid__unit--divider + .grid__unit--s-noTopBottomPush &.collapsibleTile--padded {
        border-top: none;
    }
}
