/*
@name Form Select
@description The selectbox for a form. The selectable items are a native implementation. This is not to be changed.
In order for the select element to take the proper styling it is important that it has the `.select` class.
<br/> The width of the select element is 100% but it may be appear shorter due to the options that it contains.
@section Objects
@type UI
@extended false
@className select

@markup
<select class="select">
    <option>Optie 1</option>
    <option>Optie 2</option>
    <option>Optie 3</option>
</select>

*/

%selectfield {
    @extend %textfield;

    height: toRem(45px);
    padding: 0;
    padding-right: toRem($input-horizontal-space * 2.5);
    padding-left: toRem($input-horizontal-space);
    display: block;

    &::-ms-expand {
        display: none;
    }

    &:focus::-ms-value {
        background: transparent;
        color: $ns-blue;
        outline: toRem(1px) dotted;
    }

    /* Option styling for Firefox */
    option {
        padding-left: toRem($tile-space * 0.5);
    }

    /* exclude Android and exclude IE-9 by selecting on the csstransitions class set by modernizr */
    .no-android-stock-browser.csstransitions & {
        appearance: none;
        background-repeat: no-repeat;
        background-position: right center;
        background-color: $ns-white;

        @include createIconBg('chevron-down-medium', $ns-blue);

        &:hover,
        &:focus,
        &:active {
            cursor: pointer;
        }
    }
}

.select {
    @extend %selectfield;
}
