/*
@name Collapsible (deprecated)
@description
<p>This component is deprecated. Please use <a href="/platform/components/collapse.html">Collapse</a> component. This  is more accessible version of this component.</p>
<p>An element that contains a clickable header and a body element that is toggled visible/invisible upon clicking
For Javascript functionality, this element requires `data-controller="generic/ui/Collapsible"` to be set on the parent container.
This will cause the first child component to become the header and the second to become the body element.</p>

@section Components
@type UI
@extended false

@markup
<div class="collapsible" data-controller="generic/ui/Collapsible">
    <div class="collapsible__header">Header</div>
    <div class="collapsible__body">Body</div>
</div>
*/

%collapsibleHeader {
    cursor: pointer;
}

%collapsibleBody {
    display: none;

    &.is-expanded {
        display: block;
    }
}

.is-collapsible {
    .collapsible__header {
        @extend %iconizedTextHolder;
        @extend %iconLink--collapsible;
        @extend %collapsibleHeader;

        @include iconLink__iconAndColor('plus', $ns-blue);

        &.is-active {
            @include iconLink__iconAndColor('minus', $ns-blue);
        }
    }

    .collapsible__body {
        @extend %collapsibleBody;
    }
}
