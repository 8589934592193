/*
@name Columnizer
@description
The columnizer component takes any number of child components
and splits them into one, two or three columns depending on the
viewport width.

@section Components
@type UI
@extended false

@markup
<div class="columnizer">
    <div class="columnizer__block pf-util-border">Block 1</div>
    <div class="columnizer__block pf-util-border">Block 2</div>
    <div class="columnizer__block pf-util-border">Block 3</div>
    <div class="columnizer__block pf-util-border">Block 4</div>
    <div class="columnizer__block pf-util-border">Block 5</div>
    <div class="columnizer__block pf-util-border">Block 6</div>
    <div class="columnizer__block pf-util-border">Block 7</div>
    <div class="columnizer__block pf-util-border">Block 8</div>
</div>
*/
.columnizer {

    $columnizer-bottom-distance : toRem($line-height-step * 3 + $default-margin-bottom);

    margin-bottom: - $columnizer-bottom-distance;

    .columnizer__block {
        padding-bottom: $columnizer-bottom-distance;

        ul {
            margin-bottom: 0;
        }
    }

    @include respond-min($m) {
        column-count: 2;

        .columnizer__block {
            break-inside: avoid;
            column-break-inside: avoid;
            page-break-inside: avoid;

            .list__item {
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    //fix for IE to prevent bottom items from disappearing. Applied only for IE to prevent regression
                    break-inside: avoid;
                    column-break-inside: avoid;
                    page-break-inside: avoid;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    @include respond-min($l) {
        column-count: 3;
    }
}
