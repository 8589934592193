/*
@name Notification list

@description
A list of notifications, with a box shadow beneath.

@section Components
@type UI
@extended false
@className notificationList

@markup
<ul class="notificationList">
    <li class="notificationList__item">
        <div class="notificationBar notificationBar--alert notificationBar--ellipsis notificationBar--slim">
            <a href="#" class="notificationBar__link">
                <span class="notificationBar__icon icon icon--alert icon--nsWhite">
                    <span class="alt">Let op:</span>
                </span>
                <span class="notificationBar__text">
                    De klantenservice op dit moment slecht bereikbaar op dit moment slecht bereikbaar door een stroomstoring.
                </span>
                <span class="notificationBar__arrow icon icon--chevron-medium icon--nsWhite"></span>
            </a>
        </div>
    </li>
    <li class="notificationList__item">
        <div class="notificationBar notificationBar--alert notificationBar--ellipsis notificationBar--slim">
            <a href="#" class="notificationBar__link">
                <span class="notificationBar__icon icon icon--alert icon--nsWhite">
                    <span class="alt">Let op:</span>
                </span>
                <span class="notificationBar__text">
                    De stationshal van Etten-Leur staat in de fik. Houd rekening met minder treinen.
                </span>
                <span class="notificationBar__arrow icon icon--chevron-medium icon--nsWhite"></span>
            </a>
        </div>
    </li>
</ul>
*/

.notificationList {
    position: relative;
    list-style: none;
    margin: 0;

    @include ns-box-shadow(0.15);

    z-index: 1; /* zodat de shadow boven de De actuele situatie op het spoor map komt  */
}

.notificationList__item {
    position: relative;
    margin: 0;
    padding: 0;

    &:last-of-type {

        // Remove 1px box-shadow here, in favor of 2px box-shadow on .notificationList
        .generalNotification,
        .notificationBar {
            box-shadow: none;
        }
    }
}
