/*
@name body Item Usp
@description body Item Usp. Link and button are optional.

@section Objects
@type UI
@extended false

@markup

<div class="bodyItem bodyItem--usps">
    <div class="bodyItemUsp">
        <div class="bodyItemUsp__icon">
            <picture class="bodyItemUsp__picture">
                <source srcset="<%= path.styleguideStatic %>/images/templates/usp-icon-1.svg"
                        media="(min-width: <%= breakpoints.small %>px)">
                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                     class="bodyItemUsp__img" alt="Description of image" loading="lazy" />
            </picture>
        </div>
        <div class="bodyItemUsp__title">
            <h3 class="bodyItemUsp__heading headingM">Reizen in daluren</h3>
        </div>
        <div class="bodyItemUsp__content">
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean
                massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec
                quam felis, ultricies nec.</p>
        </div>
        <div class="bodyItemUsp__footer">
            <a href="#" class="arrowLink">External link</a>
        </div>
    </div>

    <div class="bodyItemUsp">
        <div class="bodyItemUsp__icon">
            <picture class="bodyItemUsp__picture">
                <source srcset="<%= path.styleguideStatic %>/images/templates/usp-icon-2.svg"
                        media="(min-width: <%= breakpoints.small %>px)">
                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                     class="bodyItemUsp__img" alt="Description of image" loading="lazy" />
            </picture>
        </div>
        <div class="bodyItemUsp__title">
            <h3 class="bodyItemUsp__heading headingM">Meer korting</h3>
        </div>
        <div class="bodyItemUsp__content">
            <p>Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient
                montes, nascetur ridiculus mus.</p>
        </div>
        <div class="bodyItemUsp__footer">
            <a href="javascript:void(0)" class="button button--nsYellow" title="Titel">
                Call to Action
            </a>
        </div>
    </div>

    <div class="bodyItemUsp">
        <div class="bodyItemUsp__icon">
            <picture class="bodyItemUsp__picture">
                <source srcset="<%= path.styleguideStatic %>/images/templates/usp-icon-3.svg"
                        media="(min-width: <%= breakpoints.small %>px)">
                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                     class="bodyItemUsp__img" alt="Description of image" loading="lazy" />
            </picture>
        </div>
        <div class="bodyItemUsp__title">
            <h3 class="bodyItemUsp__heading headingM">OV Fiets</h3>
        </div>
        <div class="bodyItemUsp__content">
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
        </div>
    </div>
</div>
*/

.bodyItemUsp {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content auto max-content;
    padding: 0 toRem($tile-space);
    text-align: center;

    @include respond-min($ml) {
        grid-row: auto / span 4;
        grid-template-columns: subgrid;
        grid-template-rows: subgrid;
    }

    .bodyItemUsp__icon {
        background-color: $ns-grey-94;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: toRem(25px);

        .bodyItemUsp__picture {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .bodyItemUsp__img {
            max-width: 95px;
            max-height: 95px;
        }
    }

    .bodyItemUsp__footer {
        a.arrowLink {
            margin-top: toRem(10px);
            margin-bottom: toRem(10px);
        }
    }

    .bodyItemUsp__heading {
        margin-bottom: toRem(10px);
    }
}
