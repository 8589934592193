/*
@name Contact options
@description
Overview of some ways a customer can use to contact NS. For the livechat contact option we have a javascript component: 'generic/ui/LiveChat'

@section Components
@type UI
@extended false
@className contactOptions

@markup
<div class="container container--section">
    <div class="grid" data-controller="generic/ui/ResponsiveGrid">
        <div class="grid__unit s-4-4">
            <div class="contactOptions">
                <div class="grid contactOptions__list">
                    <div class="grid__unit s-4-4 m-6-12 l-3-12">
                        <a href="javascript:void(0)" class="contactOption">
                            <span class="icon icon--large contactOption__icon contactOption__icon--x"></span>
                            <h3 class="contactOption__header">X</h3>
                            <p class="contactOption__text">Antwoord binnen 2 uur</p>
                        </a>
                    </div>
                    <div class="grid__unit s-4-4 m-6-12 l-3-12 livechat" data-config="{
                            &quot;disabledClass&quot; :  &quot;contactOption--disabled&quot;,
                            &quot;chatStatusMessageClass&quot; :  &quot;contactOption__status&quot;,
                            &quot;chatStatusClosed&quot; : &quot;nu gesloten&quot;
                            }" data-controller="generic/ui/LiveChat">
                        <a href="javascript:void(0)" class="contactOption contactOption--disabled">
                            <span class="icon icon--large contactOption__icon contactOption__icon--chat"></span>
                            <h3 class="contactOption__header" data-chat-status-container="">Chat <span class="contactOption__status" data-chat-status="">niet beschikbaar</span></h3>
                            <p class="contactOption__text">Elke dag van 7.00 uur - 23.00 uur</p>
                        </a>
                    </div>
                    <div class="grid__unit s-4-4 m-6-12 l-3-12">
                        <a href="javascript:void(0)" class="contactOption">
                            <span class="icon icon--large contactOption__icon contactOption__icon--phone"></span>
                            <h3 class="contactOption__header">Telefoon</h3>
                            <p class="contactOption__text">12 minuten wachttijd</p>
                        </a>
                    </div>
                    <div class="grid__unit s-4-4 m-6-12 l-3-12">
                        <a href="javascript:void(0)" class="contactOption">
                            <span class="icon icon--large contactOption__icon contactOption__icon--mail"></span>
                            <h3 class="contactOption__header">E-mail, Post</h3>
                            <p class="contactOption__text">Lorem ipsum</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Contact options with notification bar
@parent Contact options
@description
The contact options component can be combined with a notificationBar whenever a situation arises in any communication channel.

@type UI
@extended false

@markup
<div class="container container--section">
    <div class="grid" data-controller="generic/ui/ResponsiveGrid">
        <div class="grid__unit s-4-4">
            <div class="contactOptions">
                <div class="notificationBar notificationBar--alert">
                    <span class="notificationBar__icon icon icon--alert icon--nsWhite"><span class="alt">Let op:</span></span>
                    <span class="notificationBar__text">De klantenservice is op dit momenteel niet bereikbaar.</span>
                </div>
                <div class="grid">
                    <div class="grid__unit s-4-4 m-6-12 l-3-12">
                        <a href="javascript:void(0)" class="contactOption">
                            <span class="icon icon--large contactOption__icon contactOption__icon--x"></span>
                            <h3 class="contactOption__header">X</h3>
                            <p class="contactOption__text">Antwoord binnen 2 uur</p>
                        </a>
                    </div>
                    <div class="grid__unit s-4-4 m-6-12 l-3-12 livechat" data-config="{
                            &quot;disabledClass&quot; :  &quot;contactOption--disabled&quot;,
                            &quot;chatStatusMessageClass&quot; :  &quot;contactOption__status&quot;,
                            &quot;chatStatusClosed&quot; : &quot;nu gesloten&quot;
                            }" data-controller="generic/ui/LiveChat">
                        <a href="javascript:void(0)" class="contactOption contactOption--disabled">
                            <span class="icon icon--large contactOption__icon contactOption__icon--chat"></span>
                            <h3 class="contactOption__header" data-chat-status-container="">Chat <span class="contactOption__status" data-chat-status="">niet beschikbaar</span></h3>
                            <p class="contactOption__text">Elke dag van 7.00 uur - 23.00 uur</p>
                        </a>
                    </div>
                    <div class="grid__unit s-4-4 m-6-12 l-3-12">
                        <a href="javascript:void(0)" class="contactOption contactOption--disabled">
                            <span class="icon icon--large contactOption__icon contactOption__icon--phone"></span>
                            <h3 class="contactOption__header">Telefoon</h3>
                            <p class="contactOption__text">12 minuten wachttijd</p>
                        </a>
                    </div>
                    <div class="grid__unit s-4-4 m-6-12 l-3-12">
                        <a href="javascript:void(0)" class="contactOption">
                            <span class="icon icon--large contactOption__icon contactOption__icon--mail"></span>
                            <h3 class="contactOption__header">E-mail, Post</h3>
                            <p class="contactOption__text">Lorem ipsum</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
*/

.contactOptions {
    margin-bottom: 0;
    //nullifies negative margin of nested grid. Needs to be nested for specificity reasons
    .contactOptions__list {
        @include ns-box-shadow(0.15);

        background: $ns-white;
        margin: 0;
    }
}

.contactOption {
    display: inline-block;
    position: relative;

    @include un-anchorlink();

    padding-left: toRem(50px);
    margin-bottom: toRem(20px);
    margin-left: toRem(20px);
    margin-top: toRem(20px);

    &.contactOption--disabled {
        &:hover {
            cursor: default;
        }

        .contactOption__header {
            color: $ns-grey-40;
        }
    }
}

$contactOptionIcon__color: $ns-grey-70;
$contactOptionIcon__color--hover: $ns-light-blue;

.contactOption__icon {
    position: absolute;
    left: 0;
    top: 0;
}

//contactOption__icon variants
.contactOption__icon--twitter, .contactOption__icon--x {
    @include createIconBg('x', $contactOptionIcon__color);

    .contactOption:not(.contactOption--disabled):hover & {
        @include createIconBg('x', $contactOptionIcon__color--hover);
    }
}

.contactOption__icon--mail {
    @include createIconBg('mail', $contactOptionIcon__color);

    .contactOption:not(.contactOption--disabled):hover & {
        @include createIconBg('mail', $contactOptionIcon__color--hover);
    }
}

.contactOption__icon--phone {
    @include createIconBg('phone', $contactOptionIcon__color);

    .contactOption:not(.contactOption--disabled):hover & {
        @include createIconBg('phone', $contactOptionIcon__color--hover);
    }
}

.contactOption__icon--chat {
    @include createIconBg('forum', $contactOptionIcon__color);

    .contactOption:not(.contactOption--disabled):hover & {
        @include createIconBg('forum', $contactOptionIcon__color--hover);
    }
}

.contactOption__header {
    color: $ns-light-blue;

    @include font-size('XXS');

    font-weight: bold;
    margin: 0;
}

.contactOption__text {
    color: $ns-grey-40;
    margin: 0;
}

.contactOption__status {
    font-weight: normal;
    font-style: italic;

    @include fontsize('body-small');

    &::before {
        content: '\02013';
        margin-right: toRem(5px);
    }
}
