.section {

}

.section--nsYellow {
    background-color: $ns-yellow;
}

.section--nsWhite {
    background-color: $ns-white;
}
