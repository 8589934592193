/**
 * The month and year labels.
 */
.picker__month,
.picker__year {
    font-weight: 500;
    display: inline-block;
    margin-left: toRem(4px);
    margin-right: toRem(4px);
}

.picker__year {
    color: $year-weekday-label;
    font-size: toRem(12.8px);
    font-style: italic;
}

/**
 * The weekday labels
 */
.picker__weekday {
    width: 14.285714286%; // 100/7
    font-weight: normal;
    padding-top: toRem(4px);
    padding-bottom: toRem(11px);
}
