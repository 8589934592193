.content {
    display: flow-root;
    min-height: 100%;

    html:not(.nessie) & {
        background: $body-background-color;

        @include print {
            background: none;
        }
    }
}
