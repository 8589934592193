.dev-GridReference {
    position: absolute;
    z-index: 1000;
    pointer-events: none;
}

.dev-GridReference__unit {
    height: 100%;
}

.dev-GridReference__content {
    //border-left: 1px solid aqua;
    //border-right: 1px solid aqua;
    background: aqua;
    height: 100%;
    opacity: 0.2;
}
