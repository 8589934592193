/**
 *        _  _     ___     ___     ___     ___     ___     ___    _  _
 *     | \| |   / __|   |   \   | __|   / __|   |_ _|   / __|  | \| |
 *     | .` |   \__ \   | |) |  | _|    \__ \    | |   | (_ |  | .` |
 *     |_|\_|   |___/   |___/   |___|   |___/   |___|   \___|  |_|\_|
 *    _|"""""|_|"""""|_|"""""|_|"""""|_|"""""|_|"""""|_|"""""|_|"""""|
 *    "`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'
 *
 *    GENERIC STYLING
*/

$generic : true;
$styleguide : false !default;

@import "base/reset";

// SASS SETUP
// Files that lay the groundwork without defining actual styles go here.
// ie: responsive mixins, variables, extendable placeholders, etc.
@import "../../shared/source/scss/setup/all_setup";

// TOOLS
@import "tools/animations";

// GENERATED ICONS
@import "../../shared/source/scss/icons/icons_data_svg";

// GENERIC STYLES
// All styling that should be global for the entire website goes here.
// ie: normalize, fonts, typography, grid system, form elements, etc.
@import "base/fonts";
@import "base/icons";
@import "base/buttons";
@import "base/container";
@import "base/section";
@import "base/grid";
@import "base/tiles_all";
@import "base/links";
@import "base/forms";
@import "base/images";
@import "base/separators";
@import "base/lists";
@import "base/typography";
@import "base/svg";
@import "base/group";
@import "base/tiles_graphics";
@import "base/scrollbar_measure";

//// COMPONENTS
@import "components/nsheader";
@import "components/footer";
@import "components/content";
@import "components/overlay";
@import "components/overlay_content";
@import "components/notificationBar";
@import "components/notificationList";
@import "components/inlineNotification";
@import "components/generalNotification";
@import "components/hero";
@import "components/heroflow";
@import "components/columnizer";
@import "components/table";
@import "components/darkener";
@import "components/nslogo";
@import "components/languageswitch";
@import "components/pricedetails";
@import "components/promosticker";
@import "components/searchform";
@import "components/autosuggest";
@import "components/notifications_group";
@import "components/form_form";
@import "components/form_formfield";
@import "components/form_radiobutton";
@import "components/toggleButton";
@import "components/form_checkbox";
@import "components/form_select";
@import "components/form_datepicker";
@import "components/form_conditionalPages";
@import "components/form_refactor";
@import "components/form_inputspacer";
@import "components/form_errorsummary";
@import "components/stepIndicator";
@import "components/richtext";
@import "components/actionbar";
@import "components/tooltip";
@import "components/breadcrumb";
@import "components/calamity";
@import "components/minicart";
@import "components/contactoptions";
@import "components/bodyItem";
@import "components/bodyItemImage";
@import "components/bodyItemQuote";
@import "components/bodyItemVideo";
@import "components/bodyItemUsp";
@import "components/collapse";
@import "components/collapseList";
@import "components/collapsible";
@import "components/collapsibleList";
@import "components/collapsibleTile";
@import "components/filter";
@import "components/pickadate/__picker";
@import "components/basicloader";
@import "components/sortItems";
@import "components/footNote";
@import "components/footnoteAnchor";
@import "components/chatLink";

// HELPERS
// helpers for non-component related
@import 'helpers/print';
@import 'helpers/spacing';
@import 'helpers/visibility';
@import 'helpers/display';
@import 'helpers/color';
@import 'helpers/text';
@import 'helpers/utils';
@import 'helpers/size';

// DEV helpers
@import "dev/dev_gridreference";

// Overrides, purposefully imported last.
@import "trumps/overrides";

// Deprecated CMS stuff
@import "_cms/all";
