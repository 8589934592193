$formfield__labelWidth: 180px;
$formfield__labelInputSpacing: 20px;
$formfield__bottomSpaceMobile: 35px;
$formfield__bottomSpace: 20px;
$tooltip-width: 45px;

.formPageContainer {
    //overflow: hidden;
    //position: relative;
}

.formfield {
    position: relative;
    margin-bottom: toRem($formfield__bottomSpaceMobile);

    @include respond-min($m) {
        margin-bottom: toRem($formfield__bottomSpace);
    }

    @extend %clearfix;
}

.formfield--summary {
    margin-bottom: toRem($formfield__bottomSpaceMobile * 0.5);

    @include respond-min($m) {
        margin-bottom: 0;
    }
}

.formfield__toolTipWrapper {
    z-index: z('autosuggest') - 1;

    &.is-active {
        z-index: z('autosuggest') + 1;
    }
}

.formfield__toolTip {
    right: toRem(-10px);
    top: toRem(-34px);
    position: absolute;
    background-position: toRem(7px);
    width: toRem($tooltip-width);
    z-index: z('autosuggest') - 1;

    @include respond-min($m) {
        top: toRem(5px);
        right: toRem(-45px);
    }

    &.is-active {
        z-index: z('autosuggest') + 1;
    }
}

.formfield__inputContainer {
    display: block;
    position: relative;

    @include respond-min($m) {
        padding-left: toRem($formfield__labelWidth + $formfield__labelInputSpacing);
        padding-right: 0;
        margin-right: toRem($icon-size-base);
    }
}

.formfield__toolTipWrapper {
    right: toRem(-10px);
    top: toRem(-34px);
    position: absolute;
    width: toRem($tooltip-width);
    z-index: z('autosuggest') - 1;

    @include respond-min($m) {
        top: toRem(5px);
        right: toRem(-45px);
    }

    &.is-active {
        z-index: z('autosuggest') + 1;
    }

    .formfield__toolTip {
        right: auto;
        top: auto;
        width: 100%;

        @include respond-min($m) {
            top: auto;
            right: auto;
        }
    }

    &--afterLabel {
        top: toRem(5px);
        left: toRem(-5px);
    }
}

.formfield--labelless {
    .formfield__inputContainer {
        padding-left: 0;
    }

    .formfield__toolTip {
        top: toRem(5px);
    }

    .formfield__toolTipWrapper {
        top: toRem(5px);

        .formfield__toolTip {
            top: auto;
        }
    }
}

.formfield__label {
    display: block;
    vertical-align: top;
    color: $ns-blue;
    margin-bottom: toRem(5px);
    position: relative;
    z-index: 1;

    @include respond-min($m) {
        @include textfield__lineheightfix();

        width: toRem($formfield__labelWidth);
        margin-bottom: 0;
        float: left;
        padding-bottom: 0;
        text-align: right;

        //Temporary workaround for breaking Abo pages
        .form--configurator & {
            text-align: left;
        }

        //Temporary workaround for breaking NS Flex webshop
        .form-field-wrapper &,
        nsflex-read-only-field & {
            text-align: left;
        }
    }
}

//Temporary workaround for breaking Mijn NS
.formfield__label--fullWidth {
    text-align: left;
}

.formfield__textValue {
    @include respond-min($m) {
        @include textfield__lineheightfix();
    }
}

.formfield__required {
    @include fontsize('body-small');

    color: $ns-grey-40;

    @include respond-min($m) {
        display: block;
    }
}

.formfield__toolTip--afterText {
    position: absolute;
    background-position: toRem(7px);
    width: toRem($tooltip-width);
    top: toRem(5px);
}

.formfield__inputGroup {
    padding-top: toRem(10px);

    .formfield &.formfield__inputGroup {
        // beat .bodyItem .list :(
        margin-bottom: 0;
    }

    @include respond-min($m) {
        padding-top: toRem(11px);
    }
}

.formfield__inputGroupItem {
    margin-bottom: toRem(15px);

    &:last-child {
        margin-bottom: 0;
    }
}

// checkbox
.formfield__checkbox {
    padding-top: toRem(10px);

    @include respond-min($m) {
        padding-top: toRem(11px);
    }
}

.formfield__errorMessage {
    @include fontsize('body-small');

    display: none;
    padding-top: toRem(4px);
    color: $ns-red-alert;

    .has-error & {
        display: block;
    }
}

.formfield__checkbox--indyTooltip {
    .formfield__inputGroup {
        padding-top: 0;
    }

    .formfield__inputGroupItem {
        position: relative;
    }

    .checkbox__label {
        width: calc(100% - #{toRem($tooltip-width)});

        @include respond-min($m) {
            width: auto;
        }
    }

    .checkbox {
        .formfield__toolTip {
            top: toRem(-6px);

            @include respond-min($m) {
                right: toRem(-45px);
            }
        }

        .formfield__toolTipWrapper {
            top: toRem(-6px);

            @include respond-min($m) {
                right: toRem(-45px);
            }

            .formfield__toolTip {
                top: auto;

                @include respond-min($m) {
                    right: auto;
                }
            }
        }
    }

    .has-error & .checkbox__input:checked ~ .formfield__errorMessage {
        display: none;
    }
}

.formfield__autocomplete {
    @extend %textfield__lineheightfix;

    line-height: toRem($input-line-height);
    position: absolute;
    top: 0;
    left: 0;
    color: $ns-grey-70;
    white-space: nowrap;
    overflow: hidden;
}

// .form__text needs to be placed for adding text elements such as h3 and p
.form__text {
    margin-top: toRem($formfield__bottomSpaceMobile);
    margin-bottom: toRem(15px + $formfield__bottomSpace);

    &:first-child {
        margin-top: 0;
    }

    h3 {
        margin-bottom: toRem($formfield__bottomSpace);
    }

    position: relative;

    .formfield__toolTip {
        top: toRem(-5px);
        right: toRem(-10px);
    }

    .formfield__toolTipWrapper {
        top: toRem(-5px);
        right: toRem(-10px);

        .formfield__toolTip {
            top: auto;
            right: auto;
        }
    }
}

.form__text--hasToolTip {
    > *:not(.tooltipWrapper):not(.toolTip) {
        width: calc(100% - #{toRem($tooltip-width)});
    }
}

.formfield__captcha {
    padding-top: 0;

    @include respond-min($m) {
        padding-top: toRem(10px);
    }

    .toolTip {
        right: toRem(30px);

        @include respond-min($m) {
            top: toRem($icon-size-base + 10px);
            right: toRem(-45px);
        }
    }

    .tooltipWrapper {
        right: toRem(30px);

        @include respond-min($m) {
            top: toRem($icon-size-base + 10px);
            right: toRem(-45px);
        }

        .toolTip {
            right: auto;

            @include respond-min($m) {
                top: auto;
                right: auto;
            }
        }
    }
}

.formfield__captchaButton {
    top: toRem(-34px);
    right: toRem(-10px);
    position: absolute;
    z-index: 1;

    @include respond-min($m) {
        position: absolute;
        top: toRem(5px);
        right: toRem(-45px);
        vertical-align: top;
    }
}

.formfield__captchaImage {
    width: 100%;
    margin-bottom: toRem(10px);
}

// new element selector because the old captcha must be supported for now
.formfield__reCaptcha {
    padding-top: 0;

    @include respond-min($m) {
        padding-top: toRem(10px);
    }

    .g-recaptcha {
        transform: scale(0.8);
        transform-origin: 0 0;

        @include respond-min(350px) {
            transform: scale(0.9);
        }

        @include respond-min(380px) {
            transform: none;
        }
    }
}

//.

/*
Form objects in a fieldset

.formfieldSets wraps a group of .formfields. Legend should always be the first child.

Markup:
<fieldset class="formfieldSet">
    <legend class="formfieldSet__legend">Fieldset 1 - Persoonlijke gegevens</legend>
    <div class="formfield">
        ...
    </div>
</fieldset>

// Styleguide 1.3.1.3
*/

$fieldset-margin: 48px;
$visual-offset: 13px; //to make it visually closer

.formfieldSet {
    position: relative;
    border-bottom: toRem(1px) solid $ns-grey-alpha-10;
    padding-bottom: toRem(5px);
    margin-top: toRem(-1px); //collapses top and bottom border
    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    /* Creates extra top margin for on either a non-fieldset after a fieldset, or on an fieldset after a non-fieldset
    fieldset borders would otherwise touch the non-fieldsets */

    *:not(&) + &,
    &:not(.is-hidden) + *:not(&) {
        margin-top: toRem($fieldset-margin);
    }

    @include respond-min($m) {
        padding-bottom: toRem(28px);
    }

    .formPage &:first-of-type {
        margin-top: 0;
    }
}

.formfieldSet__legend {
    @include fontsize('M');

    color: $heading-color;
    padding-top: toRem($fieldset-margin - $visual-offset);
    margin-bottom: toRem($fieldset-margin - $visual-offset);
    border-top: toRem(1px) solid $ns-grey-90;
    width: 100%;

    /* No top border if the formfieldSet (type) is the first child of formPage */
    .formfieldSet:first-child &,
    .formfieldSet:first-of-type & {
        padding-top: toRem($global--grid-quartergutter); //compensate grid padding change on mobile
        border-top: none;
    }

    + * {
        margin-top: 0; // the bottom margin of the legend won't collapse with the top margin of the next element. This will strip the margin-top of the latter.
    }

    @include respond-min($m) {
        .formfieldSet:first-of-type & {
            padding-top: 0;
        }
    }
}

// .formfieldGroup is non-fieldset variant. It contains no legend but a .formfieldGroup__title

.formfieldGroup {
    position: relative;
    border-top: toRem(1px) solid $ns-grey-alpha-10;
    border-bottom: toRem(1px) solid $ns-grey-alpha-10;
    padding-top: toRem($fieldset-margin - $visual-offset);
    padding-bottom: toRem(5px);
    margin-top: toRem(-1px); //collapses top and bottom border

    /* No top border if the formfieldSet (type) is the first child of formPage */
    &:first-child {
        border-top: none;
    }

    &:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    /* Creates extra top margin for on either an element after a formfieldGroup, or on an fieldset after an element
    formFieldgroup borders would otherwise touch the element */
    *:not(&) + &,
    & + *:not(&) {
        margin-top: toRem($fieldset-margin);
    }

    @include respond-min($m) {
        padding-bottom: toRem(20px);
    }
}

.formfieldGroup__title {
    @include fontsize('M');

    margin-bottom: toRem($fieldset-margin - $visual-offset);
}

$radioButton-width: 76px;
$legendMargin: toRem($radioButton-width * 2);

.formfield__description {
    @include fontsize('body-small');

    color: $ns-grey-60;
    margin-bottom: toRem($global--grid-halfgutter);
}

.formfield__value {
    display: inline-block;
    line-height: toRem(45px);

    @include respond-min($m) {
        float: none;
    }
}

.formfield__autosuggest {
    $icon-space: toRem($icon-size-base + ($tile-space * 0.5) - 1px); // we have to move the icon 1px, requirement from Jerry

    &.has-input {
        input[type='text'],
        input[type='search'] {
            padding-right: $icon-space;
        }

        .searchForm__clear {
            display: inline-block;
        }
    }
}

.formfield--toggleButton {
    margin-bottom: toRem(10px);

    .formfield__label {
        float: left; // Needed to reset/clear to behave as a normal block object
        margin-top: toRem(10px);
        margin-bottom: 0;
        width: 33.33%;

        @include respond-min($m) {
            margin-top: 0;
            width: calc(100% - #{($radioButton-width * 2)});
        }
    }

    .formfield__toolTipWrapper {
        display: none;
    }

    .formfield__toolTip {
        display: none;
    }

    @include respond-min($m) {
        padding-bottom: toRem(10px);
        border-bottom: toRem(1px) solid $ns-grey-alpha-10;

        .formfield__label {
            padding-right: toRem(35px);
        }

        .formfield__toolTip {
            display: block;
            background-position: center;
        }

        .formfield__toolTipWrapper {
            display: block;
            right: toRem(($radioButton-width * 2) + 5px);

            .formfield__toolTip {
                right: auto;
            }
        }
    }
}
