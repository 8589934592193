/**
 * Color helpers
 */
.h-color-inherit {
    color: inherit !important;
}

.h-color-unset {
    color: unset !important;
}
