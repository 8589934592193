.formPageHolder {
    &.is-overflowing {
        position: relative;
        overflow: hidden;
    }
}

.formPage {
    display: none;
    padding: toRem(0.1px);

    &.is-active {
        display: block;
    }

    @include focusOutline('&');
}
