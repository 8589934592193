/*

@name Links
@description All types of links.
@section Objects
@type UI
@extended false
@hideClassName true

@modifier .link                                                - Undoing default anchor styling
@modifier .unlink                                              - Undoing default anchor styling (better implementation)
@modifier .arrowLink                                           - A chevron pointing to the right in front of the link. The arrow and text indent should align with a list-item dot (and therefore its positioning can differ slightly from a regular iconLink)
@modifier .arrowLink.arrowLink--back                           - A chevron pointing to the left in front of the link.
@modifier .arrowLink.arrowLink--button                         - Give the arrowLink the same height as a .button as to equalize their vertical alignment even when they have separate containers.
@modifier .arrowLink.arrowLink--down                           - A chevron pointing to the bottom in front of the link.
@modifier .arrowLink.arrowLink--up                             - A chevron pointing to the top in front of the link.
@modifier .arrowLink.arrowLink--white                          - A chevron pointing to the right in front of the link. Link color is $ns-white.
@modifier .arrowLink.arrowLink--blue                           - A chevron pointing to the right in front of the link. Link color is $ns-blue.
@modifier .arrowLink.arrowLink--grey                           - A chevron pointing to the right in front of the link. Link color is $ns-grey-40.
@modifier .arrowLink.arrowLink--small                          - A chevron pointing to the right in front of the link.
@modifier .arrowLink--right                                    - A chevron pointing to the right  after the link.
@modifier .arrowLink--right.arrowLink--down                    - A chevron pointing to the down after the link.
@modifier .arrowLink--right.arrowLink--up                      - A chevron pointing to the top after the link.
@modifier .arrowLink--rightBlue                                - A chevron pointing to the right after the link. Link color is $ns-blue.
@modifier .arrowLink--rightWhite                               - A chevron pointing to the right after the link. Link color is $ns-white.
@modifier .iconLink.iconLink--placeholder                      - Reference iconLink containing a placeholder icon.
@modifier .iconLink.iconLink--x                                - A link with an X (Former Twitter) logo prepended
@modifier .iconLink.iconLink--twitter                          - A link with an X (Former Twitter) logo prepended
@modifier .iconLink.iconLink--facebook                         - A link with a Facebook logo prepended
@modifier .iconLink.iconLink--youtube                          - A link with a Youtube logo prepended
@modifier .iconLink.iconLink--linkedin                         - A link with a LinkedIn logo prepended
@modifier .iconLink.iconLink--pinterest                        - A link with a Pinterest logo prepended
@modifier .iconLink.iconLink--instagram                        - A link with a Instagram logo prepended
@modifier .iconLink.iconLink--external                         - A link to an external location
@modifier .iconLink.iconLink--scroll-down                      - A link to an anchor lower on the page
@modifier .fileLink                                            - A link to a generic file
@modifier .fileLink.fileLink--pdf                              - A link to a pdf-file

@markup
<!-- An anchor -->
<div>
    <a href="javascript:void(0)" class="{{modifier_class}}">A proper link using an anchor tag</a>
</div>
<!-- And a span -->
<div>
    <span class="{{modifier_class}}">A span that could be disguised as a link</span>
</div>

*/

a {
    @include anchorlink;

    .nessie .content & {
        box-shadow: none;
    }

    &:focus {
        @extend %outline;

        .nessie .content & {
            outline: none;
        }
    }
}

%link,
.link,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    @include un-anchorlink;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: $heading-color; // reset link color within a heading to the default color.
}

.unlink {
    @extend %link-reset;
}

%iconLinkToButtonHeight {
    // link with a height of 45px
    padding-top: toRem($form-element-vertical-space);
    padding-bottom: toRem($form-element-vertical-space);

    &::before {
        // base-position: -5px; then adjust for new height
        top: toRem(-5px) + toRem($form-element-vertical-space);
    }
}

.iconLink {
    @extend %iconLink--default;
}

.iconLink--listItem {
    @extend %iconLink--listItem;
}

.iconLink--listItem--small {
    @extend %iconLink--listItem--small;
}

.iconLink--placeholder {
    @include iconLink__iconAndColor('placeholder');
}

.iconLink--twitter, .iconLink--x {
    @include iconLink__iconAndColor('social-x', $ns-light-blue, $ns-blue, ':before', false);
}

.iconLink--facebook {
    @include iconLink__iconAndColor('social-facebook', $ns-light-blue, $ns-blue, ':before', false);
}

.iconLink--youtube {
    @include iconLink__iconAndColor('social-youtube', $ns-light-blue, $ns-blue, ':before', false);
}

.iconLink--linkedin {
    @include iconLink__iconAndColor('social-linkedin', $ns-light-blue, $ns-blue, ':before', false);
}

.iconLink--pinterest {
    @include iconLink__iconAndColor('social-pinterest', $ns-light-blue, $ns-blue, ':before', false);
}

.iconLink--instagram {
    @include iconLink__iconAndColor('social-instagram', $ns-light-blue, $ns-blue, ':before', false);
}

.iconLink--external {
    @include iconLink__iconAndColor('external');
}

.iconLink--scroll-down {
    @include iconLink__iconAndColor('chevron-down-medium');
}

.arrowLink {
    @extend %iconLink--listItem;

    @include iconLink__iconAndColor('chevron-mini');

    //Increase specificity
    &.arrowLink--white {
        @include iconLink__iconAndColor('chevron-mini', $ns-white, $ns-grey-90);
    }

    &.arrowLink--blue {
        @include iconLink__iconAndColor('chevron-mini', $ns-blue, $ns-blue);
    }

    &.arrowLink--grey {
        @include iconLink__iconAndColor('chevron-mini', $ns-grey-40, $ns-blue);
    }

    &.arrowLink--small {
        @extend %iconLink--listItem--small;
    }
}

.arrowLink--right {
    @include iconLink(
            (
                'icon-name': 'chevron-mini',
                'padding-left': 0,
                'icon-x': -$icon-padding,
                'icon-y': -13px,
                'icon-selector': ':after'
            )
    );
}

.arrowLink--down {
    &::before,
    &::after {
        transform: rotate(90deg);
    }
}

.arrowLink--up {
    &::before,
    &::after {
        transform: rotate(-90deg);
    }
}

.arrowLink--rightBlue {
    @include iconLink(
            (
                'icon-name': 'chevron-mini',
                'padding-left': 0,
                'icon-x': -$icon-padding,
                'color': $ns-blue,
                'icon-y': -13px,
                'icon-selector': ':after'
            )
    );
}

.arrowLink--rightWhite {
    @include iconLink(
            (
                'icon-name': 'chevron-mini',
                'padding-left': 0,
                'icon-x': 0,
                'color': $ns-white,
                'color-hover': $ns-white,
                'icon-y': -13px,
                'icon-selector': ':after'
            )
    );
}

.arrowLink--button {
    @extend %iconLinkToButtonHeight;
}

.arrowLink--back {
    @include iconLink(
            (
                'icon-hover-x': -12px,
                'padding-left': 25px
            )
    );

    &::before {
        transform: rotate(180deg);
    }
}

$arrowlink-mobile-size: $form-element-height; //45px; //needs to be $button-height
$icon-offset: ($arrowlink-mobile-size - $icon-size-base) * 0.5;

.backArrowLink {
    @extend %iconLink--chevronMedium;

    &::before {
        transform: rotate(180deg);
    }
}

.backArrowLink--compact {
    display: block;
    width: toRem($arrowlink-mobile-size);
    height: toRem($arrowlink-mobile-size);
    //places icon in the middle
    &::before {
        left: toRem($icon-offset);
    }

    &:hover {
        &::before {
            left: toRem(-8px + $icon-offset);
        }
    }

    @include respond-min($m) {
        width: auto;
        height: initial;
        display: inline-block;

        &::before {
            left: toRem(-4px);
        }

        &:hover {
            &::before {
                left: toRem(-8px);
            }
        }
    }
    //Hides anchor text
    .backArrowLink__text {
        @extend %visuallyhidden;

        @include respond-min($m) {
            @include show;
        }
    }
}

.backArrowLink--button {
    @extend %iconLinkToButtonHeight;
}

//Places the chevron on the border of the element
.backArrowLink--verge {
    margin-left: toRem(-9px);

    &.backArrowLink--compact {
        margin-left: toRem(-18px);
    }

    @include respond-min($m) {
        &.backArrowLink--compact {
            margin-left: toRem(-9px);
        }
    }
}

.fileLink {
    @extend %iconLink--default;

    @include iconLink__iconAndColor('file');

    &.fileLink--pdf {
        @include iconLink__iconAndColor('file-pdf');
        @include iconLink(
                (
                    'icon-x': -7px
                )
        );
    }
}

.blockLink {
    @extend %list-reset;

    @include un-anchorlink();
    @include tileArrowRight(false);

    width: 100%;
    height: toRem(44px);
    line-height: toRem(44px);
    padding-left: toRem($tile-space);
    white-space: nowrap;
    display: inline-block;

    @include respond-min($l) {
        height: toRem(68px);
        line-height: toRem(68px);
    }
}

.link--basic:hover {
    color: $ns-blue;
}

.link--icon {
    background-color: transparent;
    box-shadow: none;
    border: 0;
    padding: 0;
    width: toRem($icon-size-base);
    height: toRem($icon-size-base);
    display: inline-block;

    &:hover {
        box-shadow: none;
    }
}
