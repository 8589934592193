.content {
    counter-reset: footnotes;
}

.footnoteAnchor {
    counter-increment: footnotes;
    font-weight: normal;
    color: $ns-black;
    box-shadow: 0 toRem(-1px) $ns-light-blue inset;

    &:hover,
    &:focus {
        color: $ns-blue;
        box-shadow: 0 toRem(-1px) $ns-blue inset;
    }
}

.footnoteAnchor::after {
    content: counter(footnotes);
    vertical-align: super;
    font-weight: bold;
    color: $ns-light-blue;
    font-size: toRem(10px);
    padding-left: toRem(3px);
    box-shadow: 0 toRem(-1px) $ns-white inset;
}
