/*
@name General notification
@description
A component which is used as a placeholder for a notificationBar component.

@section Components
@type UI
@extended false
@className generalNotification

@modifier .generalNotification--alert     - A message with high urgency, use in combination with notificationBar--alert
@modifier .generalNotification--info      - An information message, use in combination with notificationBar--info

@markup
<div class="generalNotification {{modifier_class}}">
    <div class="notificationBar">
        <span class="notificationBar__icon icon icon--info icon--nsWhite ">
            <span class="alt">Let op:</span>
        </span>
        <span class="notificationBar__text">Dit is een mededeling die belangrijk en ernstig van aard kan zijn maar ook slechts informatief, soms overbodig maar meestal super-interessant.</span>
    </div>
</div>

*/

.generalNotification {
    position: relative;
    background-color: $ns-grey-20;
    box-shadow: inset 0 toRem(-1px) 0 0 rgba(0, 0, 0, 0.1);
}

.generalNotification--alert {
    background-color: $ns-red-alert;
}

.generalNotification--info {
    background-color: $white;
}
