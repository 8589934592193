// bodyItemImage

.bodyItemImage {
    height: 100%;
    position: relative;

    @include respond-min($m) {
        width: 100%;
    }

    .bodyItemImage__holder {
        // reset for global width/height: 100% rule introduced to fix picture for use with img--contain.
        width: auto;
        height: auto;
        display: block;
        position: relative;
    }

    // Center image
    .bodyItemImage__img {
        height: auto;
        max-width: 100%;
        margin: 0 auto;

        @include respond-min($m) {
            width: 100%;
        }
    }

    &.bodyItemImage--useBorder {
        .bodyItemImage__holder {
            display: inline-block;

            @include respond-min($m) {
                display: block;
            }
        }

        // align image to the left
        .bodyItemImage__img {
            margin-left: 0;
        }

        // align image to the right
        &.bodyItemImage--right {
            text-align: right;

            .bodyItemImage__img {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }
}
