/*
@name Overlay
@description Overlay component. Contains close button.
@section Components
@type UI
@extended false

@markup
<button class="button"
    data-controller="generic/ui/ContentOpener"
    data-config="{&quot;type&quot;: &quot;Overlay&quot;, &quot;contentId&quot;: &quot;overlay-content&quot;}"
    title="Overlay example">
Click for overlay
</button>
<article class="richText richText--overlay is-hidden" data-controller="generic/ui/RichText" id="overlay-content">
    <p>Overlay content</p>
</article>

<!-- Actual Overlay HTML  -->
<!--
<div class="overlay is-in-dom is-visible" aria-live="polite" role="dialog">
    <div class="overlay__scrollContainer" tabindex="">
        <div class="overlay__contentPositioner">
            <div class="overlay__content">
                <div class="container">
                    <div class="grid">
                       <div class="grid__unit s-4-4 m-10-12 m-push-left-1-12 xl-8-12 xl-push-left-1-12">
                            <div class="overlay__body" data-contentcontainer="">
                                <article class="richText richText--overlay" data-controller="generic/ui/RichText" id="overlay-content">
                                 Overlay content
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:void(0)" class="closeButton overlay__close">
                <span class="icon">
                    <span class="alt">Sluiten</span>
                </span>
            </a>
        </div>
    </div>
    <div class="darkener"></div>
</div>
-->
*/
$sidebar_width: 17px;
$max-overlay-width: 975px;
$overlayHeading-icon-size: 52px;
$close-icon-hit-area: 45px * 0.5;

$overlay__verticalSpace--small: $global--grid-quartergutter;
$overlay__verticalSpace--medium: 70px;

body.is-showingOverlay {
    overflow-y: hidden;

    @include respond-min($min-site-width) {
        position: fixed;
    }

    width: 100%;
}

.overlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.3s;
    z-index: z('overlay');

    &.is-in-dom,
    &.ng-enter {
        opacity: 0;

        &.ng-enter-active,
        &.is-visible {
            opacity: 1;
        }
    }

    &.ng-leave {
        opacity: 1;

        &.ng-leave-active {
            opacity: 0;
        }
    }
}

.overlay__scrollContainer {
    -webkit-overflow-scrolling: touch;
    bottom: 0;
    color: $ns-grey-20;
    left: 0;
    overflow: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;

    @include respond-min($m) {
        &::before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            margin-right: toRem(-4px); /* Adjusts for spacing */
        }
    }
}

.overlay__contentPositioner {
    display: inline-block;
    vertical-align: middle;
    max-width: toRem($max-overlay-width);
    position: relative;
    min-width: toRem(320px - $global--grid-quartergutter * 2);
    width: 95%; // Fallback for devices that don't support CSS calc
    width: calc(100% - #{toRem($global--grid-quartergutter * 2)});
    margin: toRem($overlay__verticalSpace--small) auto;
    cursor: auto;
    z-index: 500;

    @include respond-min($m) {
        margin: toRem($overlay__verticalSpace--medium) auto;
        width: calc(100% - #{toRem($global--grid-halfgutter * 2)});
    }

    @include respond-min($l) {
        width: 80%;
    }

    &:focus {
        //outline: thin dotted;
        //outline: 0;
    }
}

//initiate full height for sticky button
.overlay__contentPositioner--fullHeight {
    height: 100%;
    background-color: $white;
    padding-bottom: toRem(2 * $tile-space);
    overflow: visible;
}

.overlay__close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.overlay__content {
    padding: toRem(43px) toRem($global--grid-quartergutter);
    background-color: #fff;
    text-align: left;
    //adds extra padding on bottom when full height
    .overlay__contentPositioner--fullHeight & {
        padding-bottom: toRem(4 * $tile-space);
    }
}

.overlayHeading {
    word-break: break-word;

    p {
        font-size: toRem(20px); // veel kleiner dan default font-grootte voor mobile
        margin-top: toRem(-4px);
        color: $ns-blue;
    }

    .overlayHeading__lastUpdate {
        color: $ns-blue;
        margin-bottom: toRem($default-margin-bottom);
    }
}

.overlayHeading__icon {
    margin-left: toRem(-5px);

    @include respond-min($m) {
        margin-left: toRem(-8px);
    }

    .icon {
        position: absolute;

        @include respond-min($m) {
            width: toRem($overlayHeading-icon-size);
            height: toRem($overlayHeading-icon-size);
        }
    }

    + .overlayHeading__title {
        line-height: toRem($icon-size-base);
        margin-left: toRem($icon-size-base);

        @include respond-min($m) {
            line-height: toRem($overlayHeading-icon-size);
            margin-left: toRem($overlayHeading-icon-size);
        }
    }
}

.overlayContent {
    margin-top: toRem(25px);

    &--secondary {
        margin-top: 0;
    }
}

.overlayContent__header {
    color: $ns-grey-20;
    font-weight: bold;
    //overrides richText heading margin-bottom
    .richText & {
        margin-top: toRem(25px);
        margin-bottom: 0;
    }
}

.overlayContent__header--bullitIndent {
    margin-left: toRem($list-bullit-text-space + $list-bullit-icon-width);
}

.overlay__headerInner {
    margin: 0 auto;
    width: calc(100% - #{toRem($global--grid-quartergutter * 2)});

    @include respond-min($m) {
        width: calc(100% - #{toRem($global--grid-halfgutter * 2)});
    }

    @include respond-min($l) {
        width: 80%;
        max-width: toRem($max-overlay-width);
    }
}

.overlay__body {
    b {
        display: inline-block;
        margin-bottom: toRem($font-size-base * 0.5);
    }
}

.overlayContent__group {
    margin-bottom: toRem(35px);

    &:last-child {
        margin-bottom: 0;
    }
}

.overlayContent__heading {
    @include respond-min($m) {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
    }
}

.overlayContent__headingItem {
    margin-bottom: 0;
    margin-right: toRem(10px);
}

.overlayContent__headingItem--right {
    margin-right: 0;
}

.no-flexbox {
    .overlayContent__heading {
        @extend %clearfix;
    }

    .overlayContent__headingItem {
        @include respond-min($m) {
            float: left;
            line-height: 8 * toRem($line-height-step);
            vertical-align: bottom;

            &.overlayContent__headingItem--right {
                float: right;
            }
        }
    }
}

.overlay--videoPlayer {
    $icon--size: $icon-size-base;
    $closeButton--size: $form-element-height;
    $closeButton--padding: ($closeButton--size - $icon--size) * 0.5;

    .overlay__content {
        padding: 0;
    }

    .overlay__close {
        top: -1 * toRem($closeButton--size - $closeButton--padding);
        right: -1 * toRem($closeButton--padding);
    }

    .overlay__contentPositioner {
        margin-top: toRem($icon--size);

        @include respond-min($m) {
            margin-top: toRem($overlay__verticalSpace--medium);
        }
    }
}

.overlay__focus {
    &:focus {
        outline: 0;
    }
}
