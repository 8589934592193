
/*

            ___     ___      ___    ___     ___     ___     ___    _____    ___            __  __    ___
     o O O  |   \   | __|    | _ \  | _ \   | __|   / __|   /   \  |_   _|  | __|     o O O|  \/  |  | __|
    o       | |) |  | _|     |  _/  |   /   | _|   | (__    | - |    | |    | _|     o     | |\/| |  | _|
   TS__[O]  |___/   |___|   _|_|_   |_|_\   |___|   \___|   |_|_|   _|_|_   |___|   TS__[O]|_|__|_|  |___|
 {======|_|"""""|_|"""""|_| """ |_|"""""|_|"""""|_|"""""|_|"""""|_|"""""|_|"""""| {======|_|"""""|_|"""""|
 ./o--000'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'./o--000'"`-0-0-'"`-0-0-'

*/
.g-OverlayContent__scalingHeader {
    margin-bottom: toRem(16px);

    h2 {
        font-size: toRem(20px); // veel kleiner dan default font-grootte voor mobile
        line-height: toRem(90px);
        margin-top: 0;
        margin-bottom: toRem(5px);
    }

    h3 {
        font-size: toRem(30px); // veel kleiner dan default font-grootte voor mobile
        line-height: 1.25;
        margin-top: 0;
        margin-bottom: 0;
    }

    p {
        font-size: toRem(20px); // veel kleiner dan default font-grootte voor mobile
        margin-top: toRem(-4px);
        color: $ns-blue;
    }
}

.g-OverlayContent__headerIcon {
    fill: $white;
    margin: toRem(-5px) toRem(-24px) 0;
}

.g-OverlayContent__tel {
    white-space: nowrap;
}

.g-OverlayContent__note {
    font-size: toRem($font-size-base);
    color: $ns-grey-40;
    white-space: nowrap;
}

@include respond-min($m) {
    .g-OverlayContent__scalingHeader {
        h2 {
            font-size: toRem(24px);
        }

        h3 {
            font-size: toRem(38px);
        }

        p {
            font-size: toRem(24px);
        }
    }
}
