/*
@name Price details
@description
The pricedetails div should be placed in a container. The snippet below is the minimal required elements to display the pricing.
The `.alt` classes hides the text so only the prices are shown. Use the correct `.pricedetails__*` correctly according the subject.

@section Components
@type UI
@extended false
@className pricedetails

@modifier .pricedetails--withSticker      - Used in combination with .pricedetails__sticker
@modifier .pricedetails--withButton       - Used in combination with .pricedetails__button

@markup
<div class="pricedetails {{modifier_class}}">
    <div class="pricedetails__priceContainer">
        <div class="pricedetails__from"><span class="alt">van </span> &euro; 99</div>
        <div class="pricedetails__price"><span class="alt">voor </span> &euro; 49</div>
        <div class="pricedetails__after">voor 4 personen</div>
    </div>
    <div class="pricedetails__sticker">1 jaar gratis</div>
</div>
*/
@use 'sass:math';

.pricedetail {
    margin-top: toRem(20px);
}

.pricedetails {
    display: flex;
    color: $ns-blue;

    .tile--nsBlue & {
        color: $ns-white;
    }
}

.pricedetails__priceContainer {
    display: flex;
    align-items: baseline;
    flex-flow: row wrap;
    flex: 1 1 auto;
}

.pricedetails__before {
    margin-right: toRem(10px);

    @include fontsize('body-small');
}

.pricedetails__from {
    top: toRem(-9.5px);
    margin-right: toRem(10px);

    @extend .strikethrough;

    @include respond-min($m) {
        top: toRem(-11.6px);
    }
}

.pricedetails__price {
    margin-right: toRem(10px);
    white-space: nowrap;
    flex: 0 0 auto;

    @include fontsize('L');
}

.pricedetails__after {
    @include fontsize('body-small');

    // Needed alignment for "Prijs trajectafhankelijk"
    &:only-child {
        padding-top: toRem(15px);
    }
}

.pricedetails__comment {
    display: block;
    color: $ns-grey-40;
    font-size: toRem(12px);
}

.pricedetails__button {
    padding: toRem($button-vertical-space) toRem($button-horizontal-space);
    width: 100%;
    margin-top: toRem(10px);
    text-indent: toRem($icon-size-base);
    flex: 0 0 auto;

    &::after {
        position: relative;
        right: 0;
        vertical-align: inherit;
        background-size: 100%;
    }

    @include respond-min($m) {
        padding-right: toRem(math.div($button-horizontal-space, 3) * 2 + $icon-size-base);
        width: auto;
        text-indent: 0;
        margin-top: 0;
        height: 100%;

        &::after {
            position: absolute;
            height: 100%;
            top: 0;
            left: auto;
            vertical-align: top;
            background-size: initial;
        }
    }
}

.pricedetails__sticker {
    width: 100%;
    margin-top: toRem(5px);

    @include fontsize('body-small');

    background-color: $ns-yellow;
    padding: toRem(2px) toRem($button-horizontal-space);
    text-align: center;
}

/**
 * Stickerholder
 */
.pricedetails--withSticker {
    flex-wrap: wrap;

    @include respond-min($m) {
        min-height: toRem(69px);
    }
}

/**
 * X-large variant
 */
.pricedetails--xlarge {
    .pricedetails__from {
        top: toRem(-12px);

        @include respond-min($m) {
            top: toRem(-16px);
        }
    }

    .pricedetails__price {
        @include fontsize('XL');
    }
}

/**
 * Buttongroup variant
 */
.pricedetails--withButton {
    margin-bottom: toRem(30px);
    flex-wrap: wrap;

    @include respond-min($m) {
        flex-wrap: nowrap;
    }

    .pricedetails__priceContainer {
        margin-right: toRem(10px);
    }
}

//This pushes the pricedetails up 15px to be closer to product description
p + .pricedetails {
    .tile__content & {
        @include respond-min($m) {
            margin-top: toRem(-15px);
        }
    }
}

// Use when pricedetails block is used in combination with other content above it
.pricedetails--summary {
    &.formfield {
        margin-bottom: 0;
    }

    &:only-child {
        padding-top: toRem($global--grid-halfgutter);
        border-top: 1px solid $ns-grey-alpha-10;
    }
}
