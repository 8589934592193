.chatLink__statusText {
    font-style: italic;
    font-weight: normal;
    margin-left: 5px;
}

.chatLink--disabled {
    @extend %link-reset;

    font-weight: bold;
    color: $ns-grey-40;

    &:hover {
        cursor: text;
    }
}
