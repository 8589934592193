/*
@name Heroflow
@description New and updated 2018 version of the classic hero. Replaces the old HeroVisual component, nested inside a `.hero` component.

@section Components
@extended true

@markup
<div class="container">
    <div class="hero hero--homepage hero--noOverlap pf-util-mb0">
        <div class="heroflow" data-controller="generic/ui/ClickableTile">
            <div class="container heroflow__container">
                <div class="grid">
                    <div class="grid__unit s-4-4 m-10-12 ml-7-12 l-6-12">
                        <div class="heroflow__content">
                            <h3 class="heroflow__heading">
                                NS Extra: check-uit alert service
                            </h3>
                            <p class="heroflow__subheading headingS">
                                Beschikbaar met of zonder abonnement
                            </p>
                            <div class="pricedetails heroflow__pricedetails">
                                <p>
                                    <span class="pricedetails__before">vanaf</span>
                                    <span class="pricedetails__from"><span class="alt">van </span>40</span>
                                    <span class="pricedetails__price"><span class="alt">voor </span>35</span>
                                    <span class="pricedetails__after">per maand</span>
                                </p>
                            </div>
                            <a href="thema.shtml" class="button button--nsYellow heroflow__button" data-eventdata="{
                          &quot;data&quot; : {
                                    &quot;link_location&quot; : &quot;body&quot;,
                                    &quot;link_type&quot; : &quot;internal&quot;,
                                    &quot;link_name&quot; : &quot;NS Flex&quot;,
                                    &quot;link_value&quot; : &quot;/klantenservice/ov-chipkaart&quot;,
                                    &quot;link_data&quot; : {
                                        &quot;path&quot; : &quot;/content/documents/ns-nl/componenten/hero-actieblokken/actie-weekend-vrij/actie-weekend-vrij&quot;,
                                        &quot;targetdocumentname&quot; : &quot;index&quot;,
                                        &quot;targetdocumentpath&quot; : &quot;/content/documents/ns-nl/content/klantenservice/ov-chipkaart/index/index&quot;
                                    }
                                },
                                &quot;event&quot; : &quot;link_click&quot;}">
                            Direct bestellen</a>
                        </div>
                    </div>
                </div>
            </div>
            <picture class="heroflow__picture">
                <source srcset="<%= path.styleguideStatic %>/images/homepagina/hero-happy-1400x480.jpg" media="(min-width: <%= breakpoints.large %>px)">
                <source srcset="<%= path.styleguideStatic %>/images/homepagina/hero-happy-912x480.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                <source srcset="<%= path.styleguideStatic %>/images/homepagina/hero-happy-640x300.jpg" media="(min-width: <%= breakpoints.small %>px)">
                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
            </picture>

            <!-- Toggle heroflow__gradient based on light/dark gradient -->
            <div class="heroflow__gradient"></div>

            <div class="heroflow__overlay heroflow__overlay--solidBlue">
                <svg class="heroflow__overlay-svg" preserveAspectRatio="xMinYMin meet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 767 423">
                    <defs>
                        <style>
                            .cls-1-1 {
                                fill: none;
                            }
                            .cls-1-2 {
                                clip-path: url(#clip-path-1);
                            }
                            .cls-1-3 {
                                fill: url(#linear-gradient-1);
                            }
                        </style>
                        <clipPath id="clip-path-1" transform="translate(-0.331)">
                            <path class="cls-1-1" d="M0,423.5H4.523c111.477,0,212.584-43.107,289.315-119.838L477.662,119.838C554.393,43.107,655.954,0,766.977,0H0Z"></path>
                        </clipPath>
                        <linearGradient id="linear-gradient-1" y1="211.5" x2="767" y2="211.5" gradientUnits="userSpaceOnUse">
                            <stop class="heroflow__overlay-start" offset="0" stop-color="currentColor"></stop>
                            <stop class="heroflow__overlay-stop" offset="1" stop-color="currentColor"></stop>
                        </linearGradient>
                    </defs>
                    <g class="cls-1-2">
                        <rect class="cls-1-3 heroflow__overlay-gradient" width="767" height="423"></rect>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</div>
*/

/*
@name White flow + white text + price
@parent Heroflow
@description Uses white text on a white flow that has a gradient from 60 to 0. Useful for illustration backgrounds.

@markup
<div class="container">
    <div class="hero hero--homepage hero--noOverlap pf-util-mb0">
        <div class="heroflow" data-controller="generic/ui/ClickableTile">
            <div class="container heroflow__container">
                <div class="grid">
                    <div class="grid__unit s-4-4 m-10-12 ml-7-12 l-6-12">
                        <div class="heroflow__content">
                            <h3 class="heroflow__heading">
                                NS Extra: check-uit alert service
                            </h3>
                            <p class="heroflow__subheading headingS">
                                Beschikbaar met of zonder abonnement
                            </p>
                            <div class="pricedetails heroflow__pricedetails">
                                <p>
                                    <span class="pricedetails__before">vanaf</span>
                                    <span class="pricedetails__from"><span class="alt">van </span>40</span>
                                    <span class="pricedetails__price"><span class="alt">voor </span>35</span>
                                    <span class="pricedetails__after">per maand</span>
                                </p>
                            </div>
                            <a href="thema.shtml" class="button button--nsYellow heroflow__button" data-eventdata="{
                          &quot;data&quot; : {
                                    &quot;link_location&quot; : &quot;body&quot;,
                                    &quot;link_type&quot; : &quot;internal&quot;,
                                    &quot;link_name&quot; : &quot;NS Flex&quot;,
                                    &quot;link_value&quot; : &quot;/klantenservice/ov-chipkaart&quot;,
                                    &quot;link_data&quot; : {
                                        &quot;path&quot; : &quot;/content/documents/ns-nl/componenten/hero-actieblokken/actie-weekend-vrij/actie-weekend-vrij&quot;,
                                        &quot;targetdocumentname&quot; : &quot;index&quot;,
                                        &quot;targetdocumentpath&quot; : &quot;/content/documents/ns-nl/content/klantenservice/ov-chipkaart/index/index&quot;
                                    }
                                },
                                &quot;event&quot; : &quot;link_click&quot;}">
                            Direct bestellen</a>
                        </div>
                    </div>
                </div>
            </div>
            <picture class="heroflow__picture">
                <source srcset="<%= path.styleguideStatic %>/images/homepagina/hero-extra-1400x480.jpg" media="(min-width: <%= breakpoints.large %>px)">
                <source srcset="<%= path.styleguideStatic %>/images/homepagina/hero-extra-912x480.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                <source srcset="<%= path.styleguideStatic %>/images/homepagina/hero-extra-640x300.jpg" media="(min-width: <%= breakpoints.small %>px)">
                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
            </picture>

            <!-- Toggle heroflow__gradient based on light/dark gradient -->
            <div class="heroflow__gradient"></div>

            <div class="heroflow__overlay heroflow__overlay--white">
                <svg class="heroflow__overlay-svg" preserveAspectRatio="xMinYMin meet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 767 423">
                    <defs>
                        <style>
                            .cls-2-1 {
                                fill: none;
                            }
                            .cls-2-2 {
                                clip-path: url(#clip-path-2);
                            }
                            .cls-2-3 {
                                fill: url(#linear-gradient-2);
                            }
                        </style>
                        <clipPath id="clip-path-2" transform="translate(-0.331)">
                            <path class="cls-2-1" d="M0,423.5H4.523c111.477,0,212.584-43.107,289.315-119.838L477.662,119.838C554.393,43.107,655.954,0,766.977,0H0Z"></path>
                        </clipPath>
                        <linearGradient id="linear-gradient-2" y1="211.5" x2="767" y2="211.5" gradientUnits="userSpaceOnUse">
                            <stop class="heroflow__overlay-start" offset="0" stop-color="currentColor"></stop>
                            <stop class="heroflow__overlay-stop" offset="1" stop-color="currentColor"></stop>
                        </linearGradient>
                    </defs>
                    <g class="cls-2-2">
                        <rect class="cls-2-3 heroflow__overlay-gradient" width="767" height="423"></rect>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</div>
*/

/*
@name White flow + blue tekst
@parent Heroflow
@description A solid white flow with a transparency from 100 to 30 with blue text on top of it. Without darkener over the picture.

@markup
<div class="container">
    <div class="hero hero--homepage hero--noOverlap pf-util-mb0">
        <div class="heroflow" data-controller="generic/ui/ClickableTile">
            <div class="container heroflow__container">
                <div class="grid">
                    <div class="grid__unit s-4-4 m-10-12 ml-7-12 l-6-12">
                        <div class="heroflow__content heroflow__content--blue">
                            <h3 class="heroflow__heading">
                                NS Extra: check-uit alert service
                            </h3>
                            <p class="heroflow__subheading headingS">
                                Beschikbaar met of zonder abonnement
                            </p>
                            <div class="pricedetails heroflow__pricedetails">
                                <p>
                                    <span class="pricedetails__before">vanaf</span>
                                    <span class="pricedetails__from"><span class="alt">van </span>40</span>
                                    <span class="pricedetails__price"><span class="alt">voor </span>35</span>
                                    <span class="pricedetails__after">per maand</span>
                                </p>
                            </div>
                            <a href="thema.shtml" class="button button--nsYellow heroflow__button" data-eventdata="{
                          &quot;data&quot; : {
                                    &quot;link_location&quot; : &quot;body&quot;,
                                    &quot;link_type&quot; : &quot;internal&quot;,
                                    &quot;link_name&quot; : &quot;NS Flex&quot;,
                                    &quot;link_value&quot; : &quot;/klantenservice/ov-chipkaart&quot;,
                                    &quot;link_data&quot; : {
                                        &quot;path&quot; : &quot;/content/documents/ns-nl/componenten/hero-actieblokken/actie-weekend-vrij/actie-weekend-vrij&quot;,
                                        &quot;targetdocumentname&quot; : &quot;index&quot;,
                                        &quot;targetdocumentpath&quot; : &quot;/content/documents/ns-nl/content/klantenservice/ov-chipkaart/index/index&quot;
                                    }
                                },
                                &quot;event&quot; : &quot;link_click&quot;}">
                            Direct bestellen</a>
                        </div>
                    </div>
                </div>
            </div>
            <picture class="heroflow__picture">
                <source srcset="<%= path.styleguideStatic %>/images/homepagina/hero-hipster-1400x480.jpg" media="(min-width: <%= breakpoints.large %>px)">
                <source srcset="<%= path.styleguideStatic %>/images/homepagina/hero-hipster-912x480.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                <source srcset="<%= path.styleguideStatic %>/images/homepagina/hero-hipster-640x300.jpg" media="(min-width: <%= breakpoints.small %>px)">
                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
            </picture>

            <!-- Toggle heroflow__gradient based on light/dark gradient -->
            <!-- <div class="heroflow__gradient"></div> -->

            <div class="heroflow__overlay heroflow__overlay--solidWhite">
                <svg class="heroflow__overlay-svg" preserveAspectRatio="xMinYMin meet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 767 423">
                    <defs>
                        <style>
                            .cls-3-1 {
                                fill: none;
                            }
                            .cls-3-2 {
                                clip-path: url(#clip-path-3);
                            }
                            .cls-3-3 {
                                fill: url(#linear-gradient-3);
                            }
                        </style>
                        <clipPath id="clip-path-3" transform="translate(-0.331)">
                            <path class="cls-3-1" d="M0,423.5H4.523c111.477,0,212.584-43.107,289.315-119.838L477.662,119.838C554.393,43.107,655.954,0,766.977,0H0Z"></path>
                        </clipPath>
                        <linearGradient id="linear-gradient-3" y1="211.5" x2="767" y2="211.5" gradientUnits="userSpaceOnUse">
                            <stop class="heroflow__overlay-start" offset="0" stop-color="currentColor"></stop>
                            <stop class="heroflow__overlay-stop" offset="1" stop-color="currentColor"></stop>
                        </linearGradient>
                    </defs>
                    <g class="cls-3-2">
                        <rect class="cls-3-3 heroflow__overlay-gradient" width="767" height="423"></rect>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Heroflow without call to action
@parent Heroflow
@description The call to action is not always required

@markup
<div class="container">
    <div class="hero hero--homepage hero--noOverlap pf-util-mb0">
        <div class="heroflow" data-controller="generic/ui/ClickableTile">
            <div class="container heroflow__container">
                <div class="grid">
                    <div class="grid__unit s-4-4 m-10-12 ml-7-12 l-6-12">
                        <div class="heroflow__content">
                            <h3 class="heroflow__heading">
                                NS Extra: check-uit alert service
                            </h3>
                            <p class="heroflow__subheading headingS">
                                Beschikbaar met of zonder abonnement
                            </p>
                            <div class="pricedetails heroflow__pricedetails">
                                <p>
                                    <span class="pricedetails__before">vanaf</span>
                                    <span class="pricedetails__from"><span class="alt">van </span>40</span>
                                    <span class="pricedetails__price"><span class="alt">voor </span>35</span>
                                    <span class="pricedetails__after">per maand</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <picture class="heroflow__picture">
                <source srcset="<%= path.styleguideStatic %>/images/homepagina/hero-happy-1400x480.jpg" media="(min-width: <%= breakpoints.large %>px)">
                <source srcset="<%= path.styleguideStatic %>/images/homepagina/hero-happy-912x480.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                <source srcset="<%= path.styleguideStatic %>/images/homepagina/hero-happy-640x300.jpg" media="(min-width: <%= breakpoints.small %>px)">
                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
            </picture>

            <!-- Toggle heroflow__gradient based on light/dark gradient -->
            <div class="heroflow__gradient"></div>

            <div class="heroflow__overlay heroflow__overlay--solidBlue">
                <svg class="heroflow__overlay-svg" preserveAspectRatio="xMinYMin meet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 767 423">
                    <defs>
                        <style>
                            .cls-1-1 {
                                fill: none;
                            }
                            .cls-1-2 {
                                clip-path: url(#clip-path-1);
                            }
                            .cls-1-3 {
                                fill: url(#linear-gradient-1);
                            }
                        </style>
                        <clipPath id="clip-path-1" transform="translate(-0.331)">
                            <path class="cls-1-1" d="M0,423.5H4.523c111.477,0,212.584-43.107,289.315-119.838L477.662,119.838C554.393,43.107,655.954,0,766.977,0H0Z"></path>
                        </clipPath>
                        <linearGradient id="linear-gradient-1" y1="211.5" x2="767" y2="211.5" gradientUnits="userSpaceOnUse">
                            <stop class="heroflow__overlay-start" offset="0" stop-color="currentColor"></stop>
                            <stop class="heroflow__overlay-stop" offset="1" stop-color="currentColor"></stop>
                        </linearGradient>
                    </defs>
                    <g class="cls-1-2">
                        <rect class="cls-1-3 heroflow__overlay-gradient" width="767" height="423"></rect>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Heroflow campagne
@parent Heroflow
@description Heroflow for campagne pages

@markup
<div class="container container--extended">
    <div class="hero hero--major h-mb-0">
        <div class="heroflow" data-controller="generic/ui/ClickableTile">
            <div class="container container--narrow heroflow__container heroflow__container--center">
                <div class="grid">
                    <div class="grid__unit s-4-4 m-10-12 ml-7-12 l-6-12">
                        <div class="heroflow__content heroflow__content--bigPadding">
                            <ul class="breadcrumb breadcrumb--white">
                                <li class="breadcrumb__item"><a class="breadcrumb__link" href="#">Reisinformatie</a></li>
                                <li class="breadcrumb__item"><a class="breadcrumb__link" href="#">NS voor onderweg</a></li>
                            </ul>
                            <h1 class="heroflow__heading">
                                Kun jij ook niet wachten?
                            </h1>
                            <p class="heroflow__subheading headingS">
                                Om er weer lekker met de trein op uit te gaan?
                            </p>
                            <a href="thema.shtml" class="button button--nsYellow heroflow__button" data-eventdata="{
                &quot;data&quot; : {
                        &quot;link_location&quot; : &quot;body&quot;,
                        &quot;link_type&quot; : &quot;internal&quot;,
                        &quot;link_name&quot; : &quot;NS Flex&quot;,
                        &quot;link_value&quot; : &quot;/klantenservice/ov-chipkaart&quot;,
                        &quot;link_data&quot; : {
                            &quot;path&quot; : &quot;/content/documents/ns-nl/componenten/hero-actieblokken/actie-weekend-vrij/actie-weekend-vrij&quot;,
                            &quot;targetdocumentname&quot; : &quot;index&quot;,
                            &quot;targetdocumentpath&quot; : &quot;/content/documents/ns-nl/content/klantenservice/ov-chipkaart/index/index&quot;
                        }
                    },
                    &quot;event&quot; : &quot;link_click&quot;}">
                                Call to action</a>
                        </div>
                    </div>
                </div>
            </div>
            <picture class="heroflow__picture">
                <!-- <source srcset="<%= path.styleguideStatic %>/images/generiek/hero_perron_mensen_1920x480.jpg" media="(min-width: <%= breakpoints.xxxlarge %>px)"> -->
                <source srcset="<%= path.styleguideStatic %>/images/generiek/hero_perron_mensen_1400x480.jpg" media="(min-width: <%= breakpoints.large %>px)">
                <source srcset="<%= path.styleguideStatic %>/images/generiek/hero_perron_mensen_912x480.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                <source srcset="<%= path.styleguideStatic %>/images/generiek/hero_perron_mensen_640x300.jpg" media="(min-width: <%= breakpoints.small %>px)">
                <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain" loading="eager"/>
            </picture>
            <!-- Toggle heroflow__gradient based on light/dark gradient -->
            <div class="heroflow__gradient"></div>
            <div class="heroflow__overlay heroflow__overlay--white">
                <svg class="heroflow__overlay-svg" preserveAspectRatio="xMinYMin meet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 767 423">
                    <defs>
                        <style>
                            .cls-2-1 {
                                fill: none;
                            }
                            .cls-2-2 {
                                clip-path: url(#clip-path-2);
                            }
                            .cls-2-3 {
                                fill: url(#linear-gradient-2);
                            }
                        </style>
                        <clipPath id="clip-path-2" transform="translate(-0.331)">
                            <path class="cls-2-1" d="M0,423.5H4.523c111.477,0,212.584-43.107,289.315-119.838L477.662,119.838C554.393,43.107,655.954,0,766.977,0H0Z"></path>
                        </clipPath>
                        <linearGradient id="linear-gradient-2" y1="211.5" x2="767" y2="211.5" gradientUnits="userSpaceOnUse">
                            <stop class="heroflow__overlay-start" offset="0" stop-color="currentColor"></stop>
                            <stop class="heroflow__overlay-stop" offset="1" stop-color="currentColor"></stop>
                        </linearGradient>
                    </defs>
                    <g class="cls-2-2">
                        <rect class="cls-2-3 heroflow__overlay-gradient" width="767" height="423"></rect>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</div>
*/

@use 'sass:math';

$heroflow-svg-width: 870px;
$heroflow-svg-width-centered: 689px;

$heroflow-animation-timing-function: cubic-bezier(0.7, 0.27, 0.27, 1);
$heroflow-animation-fill-mode: both;

$heroflow-content-animation-duration: 750ms;

$heroflow-overlay-animation-duration: 1500ms;
$heroflow-overlay-animation-delay: 150ms;

$heroflow-overlay-gradient-animation-duration: 1250ms;

.heroflow {
    position: relative;
    width: 100%;
    height: 100%;
}

.heroflow__container {
    position: relative;
    z-index: 1;

    @include respond-min($m) {
        margin-left: toRem($global--grid-halfgutter);
        margin-right: toRem($global--grid-halfgutter);
    }
}

.heroflow__container--center {
    @include respond-min($xxl) {
        margin-left: auto;
        margin-right: auto;
    }

    .grid {
        @extend .grid--touch;
    }
}

.heroflow__content {
    padding: toRem(20px - $global--grid-quartergutter);
    color: $white;
    text-shadow: 1px 1px 0 rgba(7, 7, 33, 0.35), 0 0 30px rgba(7, 7, 33, 0.25);
    animation: fade-in-right $heroflow-content-animation-duration $heroflow-animation-timing-function $heroflow-animation-fill-mode;

    @include respond-min($m) {
        padding: toRem($tile-space - $global--grid-halfgutter);
    }
}

.heroflow__content--blue {
    color: $ns-blue;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5);
}

.heroflow__content--bigPadding {
    padding: $global--grid-gutter 0px;

    @include respond-min($m) {
        padding: toRem($tile-space * 2) toRem(14.4px);
    }
}

.heroflow__heading {
    margin-bottom: toRem($heading-margin-bottom * 2);
    font-weight: bold;
    font-size: toRem(24px);
    letter-spacing: -0.3px;
    line-height: 1.25;
    color: inherit;

    @include respond-min($sm) {
        font-size: toRem(28px);
    }

    @include respond-min($m) {
        font-size: toRem(38px);
    }
}

.heroflow__subheading {
    color: inherit;
    letter-spacing: -0.3px;

    @include respond-min($m) {
        margin-bottom: toRem($global--grid-quartergutter);
    }
}

.heroflow__price {
    color: inherit;
}

.heroflow__pricedetails {
    margin-top: toRem($global--grid-quartergutter);
    color: inherit;

    :last-child {
        margin-bottom: 0;
    }
}

// To override the later defined color in pricedetails.scss
.heroflow__pricedetails.heroflow__pricedetails {
    color: inherit;
}

.heroflow__button {
    text-shadow: none;
    margin-top: toRem($global--grid-halfgutter);

    &:not(:last-child) {
        margin-right: toRem(math.div($font-size-base, 2));
    }
}

.heroflow__button--link {
    background: none;
    box-shadow: none;
    height: auto;
    min-width: initial;
    // Undo button--tertiary styling
    border-radius: $button__border__radius;
    padding: toRem($button-vertical-space) toRem($button-horizontal-space);

    &:focus,
    &:hover {
        border: none;
        box-shadow: none;
    }

    &:focus {
        outline-color: $ns-white;
    }
}

.heroflow__button--white {
    color: $ns-white;
    text-shadow: 1px 1px 0 rgba(7, 7, 33, 0.35), 0 0 30px rgba(7, 7, 33, 0.25);

    &:focus,
    &:hover {
        color: $ns-grey-90;
    }

    &:focus {
        outline-color: $ns-grey-90;
    }
}

.heroflow__button--blue {
    color: $ns-blue;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5);

    &:focus,
    &:hover {
        color: #022259; /* custom color */
    }

    &:focus {
        outline-color: #022259; /* custom color */
    }
}

.heroflow__gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(80deg, $black 20%, rgba(0, 0, 0, 0.5) 100%);
    opacity: 0.35;

    @include respond-min($m) {
        background: linear-gradient(164deg, $black 20%, transparent 90%);
    }
}

.heroflow__picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.heroflow__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;

    &::before {
        position: absolute;
        top: 0;
        width: 0;
        height: 100%;
        content: '';
    }

    @include respond-min($l) {
        &::before {
            width: calc(50% - #{$heroflow-svg-width * 0.5});
        }
    }
}

.heroflow__overlay--solidBlue {
    animation: fade-in $heroflow-overlay-animation-duration $heroflow-animation-timing-function $heroflow-overlay-animation-delay
        $heroflow-animation-fill-mode;

    &::before {
        background-color: $ns-blue;
        opacity: 0.2;
    }

    .heroflow__overlay-svg {
        color: $ns-blue;
    }

    .heroflow__overlay-start {
        stop-opacity: 0.2;
    }
}

.heroflow__overlay--white {
    opacity: 0.6;

    &.heroflow__overlay {
        &::before {
            right: 0;
        }

        @include respond-min($l) {
            &::before {
                width: calc(50% - #{$heroflow-svg-width-centered * 0.5});
            }
        }
    }

    .heroflow__overlay-svg {
        color: $white;
        transform: rotate(180deg);

        @include respond-min($l) {
            transform-origin: #{$heroflow-svg-width-centered * 0.5};
            left: calc(50% - #{$heroflow-svg-width-centered * 0.5});
        }
    }

    .heroflow__overlay-start {
        stop-opacity: 0;
    }

    .heroflow__overlay-gradient {
        animation: fade-in-right $heroflow-overlay-gradient-animation-duration $heroflow-animation-timing-function
            $heroflow-animation-fill-mode;
    }
}

.heroflow__overlay--solidWhite {
    animation: fade-in $heroflow-overlay-animation-duration $heroflow-animation-timing-function $heroflow-overlay-animation-delay
        $heroflow-animation-fill-mode;

    &::before {
        background-color: $white;
        opacity: 0.3;
    }

    .heroflow__overlay-svg {
        color: $white;
    }

    .heroflow__overlay-start {
        stop-opacity: 0.3;
    }
}

.heroflow__overlay-svg {
    position: relative;
    width: 100%;
    height: 100%;

    @include respond-min($l) {
        width: auto;
        height: 100%;
        left: calc(50% - #{$heroflow-svg-width * 0.5});
    }
}
