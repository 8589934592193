.svg-icon--small {
    height: toRem(25px);
    width: toRem(25px);
    display: inline-block;
    vertical-align: middle;
    margin-top: toRem(-3.2px); //to align with the xheight of the font
}

.svg-color--alert {
    fill: $ns-alert;
}

.svg-color--warning {
    fill: $ns-warning;
}
