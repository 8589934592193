// ---------------------------------------------------------------------------
// Helpers for print behavior
// ---------------------------------------------------------------------------

.printOnly {
    @include screen {
        display: none !important;
    }
}

.screenOnly {
    @include print {
        display: none !important;
    }
}
