/**
 * The month and year selectors.
 */

.picker__select--month {
    margin-right: toRem(5px);
    width: calc(50% - #{toRem(5px)});
}

.picker__select--year {
    margin-left: toRem(5px);
    width: calc(50% - #{toRem(5px)});
}

.picker__select--month,
.picker__select--year {
    @extend %selectfield;

    display: inline-block;

    .has-error &:not(:focus):not(:hover) {
        border-color: $input-border-color;
    }
}
