/*
@name Tooltip
@description
The tooltip is being displayed along side of an input element.
This is normally the usecase but it can also function on its own.
<br/>
noticeable is that the tooltip is now an anchor (`<a>`), but can also function as an other HTML element e.g. `<div>`.
<br/>
In this example the icon is also wrapped inside a formfield__inputContainer, but this is not mandatory
@section Components
@type UI
@extended false
@className toolTip

@markup
<div class="formfield__inputContainer">
    <input type="text" class="textInput textInput--fullWidth" id="input2" name="input2"/>
    <div class="tooltipWrapper formfield__toolTipWrapper" data-controller="generic/ui/ToolTip">
        <button type="button" class="toolTip formfield__toolTip" aria-pressed="false"><span class="alt">Toon tooltip met meer informatie</span></button>
        <span aria-live="polite" class="toolTip--hidden" data-tooltip-content=""> E illis, cum volemus. Ea possunt paria non esse.
            At eum nihili facit; Nec tamen ullo modo summum pecudis bonum et hominis idem mihi videri pot
        </span>
    </div>
</div>
*/

$toolTipBalloonWidth: 251px;
$pointerHeight: 35px;
$pointerWidth: $pointerHeight;
$pointerYScale: 0.5;

.toolTip {
    @extend %icon;

    @include createIconBg('info', $ns-grey-40);
    @include un-anchorlink;

    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;

    &:hover,
    &.is-active {
        @include createIconBg('info', $ns-blue);
    }

    &:focus {
        @extend %outline;
    }

    // Removes the outline when clicked on
    // The 'hover-disabled' class is (un)set in tooltip.js
    &.hover-disabled:active,
    &.hover-disabled:focus {
        // outline:none
    }
}

.tooltipWrapper {
    @extend %icon;
}

.toolTip__container {
    position: absolute;
    left: toRem(-$toolTipBalloonWidth + 18px);
    width: toRem($toolTipBalloonWidth - 17px);
    background: $ns-white;
    border: toRem(1px) solid $ns-grey-90;
    box-shadow: 0 toRem(5px) toRem(25px) rgba(7, 7, 33, 0.2);
    cursor: text;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    z-index: z('header') - 1;

    @include respond-min($m) {
        left: toRem(-$toolTipBalloonWidth);
        width: toRem($toolTipBalloonWidth);
    }

    &::after {
        content: '';
        position: absolute;
        width: toRem($pointerHeight);
        height: toRem($pointerWidth);
        transform: scaleY($pointerYScale) rotate(45deg);
        border: toRem(1px) solid $ns-grey-90;
        background: $ns-white;
        right: toRem(-5px);
        top: 50%;
        margin-top: toRem(-$pointerHeight * 0.5 + $pointerYScale);
    }
}

.toolTip__body {
    display: block;
    background: #fff;
    padding: toRem(13px) toRem(15px);
    position: relative;
    z-index: 1;
    color: $ns-grey;
}

.toolTip__container,
.toolTip__body {
    border-radius: $input-border-radius;
}

.toolTip--hidden {
    @extend %visuallyhidden;
}
