
// thanks http://davidwalsh.name/detect-scrollbar-width

.scrollbarMeasure {
    width: toRem(100px);
    height: toRem(100px);
    overflow: scroll;
    position: absolute;
    top: -9999px;
}
