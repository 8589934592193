/*
@name Step indicator
@description
A step indicator shows the flow of a form.
In this example you see that the progressbar has a full width. You can decide to what extend it will be by wrapping am appropriate container class around it.
A container class used in the form templates is the class `.formPageContainer`.
<br/><br/>
The step indicator title should be filled accordingly. This could be e.g. the name of the user, the step in which he is, etc.
@section Components
@type UI
@extended false
@className stepsIndicator

@markup
<div class="stepsIndicator">
    <div class="stepsIndicator__header" aria-live="polite" role="status">
        <h3 class="headingM stepsIndicator__heading">A stepIndicator title</h3>
    </div>
    <div class="stepsIndicator__container">
        <div class="stepsIndicator__stepHolder">
            <span class="stepsIndicator__dot stepsIndicator__dot--done" style="margin-right: 155.5px;">1</span>
            <span class="stepsIndicator__dot" style="margin-right: 155.5px;">2</span>
            <span class="stepsIndicator__dot" style="margin-right: 155.5px;">3</span>
            <span class="stepsIndicator__dot" style="margin-right: 155.5px;">4</span>
            <span class="stepsIndicator__dot" style="margin-right: 0px;"></span>
        </div>
        <span class="stepsIndicator__progressBarHolder">
            <span class="stepsIndicator__progressBar" style="width: 0px;">
            </span>
        </span>
        <span aria-live="polite" role="status" class="stepsIndicator__statusHolder">Stap 1 van 4</span>
    </div>
</div>
*/

@use 'sass:math';

$stepIndicator_height: 30px;

.stepsIndicator {
    width: 100%;
    overflow: auto;
    margin-bottom: toRem($stepIndicator_height);

    @extend %staticSteps;
}

.stepsIndicator__container {
    position: relative;
    height: toRem($stepIndicator_height + 19);
}

.stepsIndicator__header {
    min-height: toRem(40px);
    margin-bottom: toRem($line-height-step * 2);
}

.stepsIndicator__heading {
    margin-bottom: 0;
}

.stepsIndicator__stepHolder {
    margin: 0;
    padding: 0;
    width: 100%;
    height: toRem($stepIndicator_height);
    overflow: hidden;
}

.stepsIndicator__progressBarHolder {
    display: block;
    position: absolute;
    left: 0;
    top: toRem(14px);
    width: 99%;
    height: toRem(3px);
    background: $ns-grey-90;
}

.stepsIndicator__progressBar {
    background: $ns-blue;
    height: toRem(3px);
    position: absolute;
}

.stepsIndicator__statusHolder {
    @include visuallyhidden;
}

.stepsIndicator__dot {
    position: relative;
    display: inline-block;
    float: left;
    width: toRem($stepIndicator_height);
    height: toRem($stepIndicator_height);
    line-height: toRem(32px);
    background: $ns-grey-90;
    border-radius: 50%;
    text-align: center;
    z-index: 2;

    .is-overflowing & {
        position: relative;
        float: left;
        left: auto !important;
    }
    //last step with a checkmark
    &:last-child {
        &::after {
            content: '';
            color: $ns-grey-20;
            width: toRem(20px);
            height: toRem(8px);
            position: absolute;
            top: toRem(8px);
            left: toRem(6px);
            border: toRem(1px) solid $ns-grey;
            border-top: none;
            border-right: none;
            transform: rotate(-25deg) skewY(-23deg);
            transition: opacity 0.2s ease-in-out;
        }

        margin-left: toRem(-1px);
    }
}

.stepsIndicator__dot--done {
    color: #fff;
    background: $ns-blue;

    &::after {
        border-color: $ns-white;
    }
}

.stepsIndicator__dot--error {
    background: $ns-red-alert;
    &:last-child {
        &::after {
            border: 0;
            top: toRem(8px);
            left: toRem(8px);
            width: toRem(13px);
            height: toRem(13px);
            transform: none;
            background-position: center;

            @include createIconBg('close', $ns-white);
        }
    }
}

%staticSteps {
    $minSteps: 2;
    $maxSteps: 6;
    $dotWidth: $stepIndicator_height;

    &.stepsIndicator--static {
        .stepsIndicator__dot {
            position: absolute;
            margin-left: toRem(-$dotWidth * 0.5);
            display: inline-block;

            &:last-child {
                left: 100%;
            }
        }

        .stepsIndicator__container {
            margin: 0 toRem(($dotWidth * 0.5) + 1) 0 toRem($dotWidth * 0.5); // (margin left + 1) to remove scrollbar in IE
        }

        @for $stepCount from $minSteps through $maxSteps {
            &.totalSteps_#{$stepCount} {
                .stepsIndicator__dot:nth-child(n + #{$stepCount}):not(:last-child) {
                    display: none;
                }

                @for $currentStep from 1 through $stepCount {
                    $percentage: math.div(100%, $stepCount - 1) * ($currentStep - 1);

                    .stepsIndicator__dot:nth-child(#{$currentStep}) {
                        left: $percentage;
                    }

                    &.activeStep_#{$currentStep} .stepsIndicator__progressBar {
                        width: $percentage;
                    }

                    @if ($stepCount == $currentStep) {
                        &.activeStep_#{$currentStep} .stepsIndicator__dot:last-child:not(.stepsIndicator__dot--error) {
                            @extend .stepsIndicator__dot--done;
                        }
                    }
                }
            }
        }

        @for $currentStep from 1 through $maxSteps {
            &.activeStep_#{$currentStep} {
                .stepsIndicator__dot:nth-child(-n + #{$currentStep}):not(.stepsIndicator__dot--error) {
                    @extend .stepsIndicator__dot--done;
                }
            }
        }
    }
}
