/*
@name Heading tags
@section Basics
@category typography
@type UI
@extended false
@description
The simplest way to create semantic headings ofcourse is to use `<h1>`, `<h2>`, etc.. tags. The site currently
supports heading levels 1 through 6, although levels 4, 5 and 6 all look exactly alike. Headings scale. On mobile they
are all slightly smaller than at wider breakpoints.

The style of headings are defined centrally (in scss/setup/_extenders.scss). Each heading extends the `%heading` silent
class, and additionally includes a fontsize from the range 'XXL' to 'XS'.

Note that the `XL` and `M` font sizes are not linked to any heading-tag.

@markup
<h1>Level 1 heading, using fontsize XXL</h1>
<h2>Level 2 heading, using fontsize L</h2>
<h3>Level 3 heading, using fontsize S</h3>
<h4>Level 4 heading, using fontsize XS</h4>

*/

$scroll-offset: 30px;

html,
body,
button,
select {
    font-family: $font-normal;
}

html {
    font-size: 100%;
    scroll-padding-top: toRem($scroll-offset);

    &.hide-header {
        scroll-padding-top: initial;
    }

}

body {
    background-color: #fff;
    color: $body-color;
    line-height: toRem($line-height-base);
    min-width: toRem($min-viewport-width);

    @include respond-min($m) {
        // negatieve marges op een grid binnen een container--connectSides zorgen voor een horizontale scrollbalk
        // op kleine schermen. we kunnen op dit moment echter geen overflow op de container zelf zetten, ivm
        // autocomplete en datepicker componenten in de reisplanner. Vandaar dit paardenmiddel.
        overflow-x: hidden;
    }

    @include respond-min($ml) {
        padding-top: 0;
    }

    @include print {
        background-color: $white;
        padding-top: 0;
        max-width: toRem(960px);
        margin: 0 auto;
    }

    .hide-header & {
        padding-top: 0;
    }
}

p {
    font-size: toRem($font-size-base);
    margin-top: 0;
    margin-bottom: toRem($default-margin-bottom);

    &.small {
        // TODO: use fontSize mixin after CMS-2420
        font-size: toRem(14px);
        line-height: toRem($line-height-small);
    }

    &.grey {
        color: $ns-grey-40;
    }
}

/*
@name Headings
@section Objects
@category typography
@type UI
@hideClassName true
@extended false
@description
In addition to `<h1>`, `<h2>`, etc.. tags, any element can be made to look like a heading. These are applied to any
element using the `.headingXXL`, `.headingXL`, ... etc classes. Each class extends '%heading' and includes a fontsize.
into a single class for brevity.

Compared to heading tags additional heading sizes are available: The `.headingXL` and `.headingM` classes are only
available through a heading-class, and are not linked to any heading-tag.

@modifier .headingXXL            - Extra extra large heading. Also default style of a <code>h1</code> tag.
@modifier .headingXL             - Extra large heading.
@modifier .headingL              - Large heading. Also default style of a <code>h2</code> tag.
@modifier .headingM              - Medium heading.
@modifier .headingS              - Small heading. Also default style of a <code>h3</code> tag.
@modifier .headingXS             - Extra small heading. Also default style of a <code>h4</code>, <code>h5</code> and <code>h6</code> tag.
@modifier .headingXXS            - Extra extra small heading that is the same size as body copy.

@markup
<span class="{{modifier_class}}">I am a span which looks like a heading. Check out my modifier!</span>

*/

.headingXXL {
    @extend %heading;

    @include fontsize('XXL');
}

.headingXL {
    @extend %heading;

    @include fontsize('XL');
}

.headingL {
    @extend %heading;

    @include fontsize('L');
}

.headingM {
    @extend %heading;

    @include fontsize('M');
}

.headingS {
    @extend %heading;

    @include fontsize('S');
}

.headingXS {
    @extend %heading;

    @include fontsize('XS');
}

.headingXXS {
    @extend %heading;

    @include fontsize('XXS');
}

h1 {
    @extend %heading;

    @include fontsize('XXL');
}

h2 {
    @extend %heading;

    @include fontsize('L');
}

h3 {
    @extend %heading;

    @include fontsize('S');
}

h4 {
    @extend %heading;

    @include fontsize('XS');
}

h5 {
    @extend %heading;

    @include fontsize('XS');
}

h6 {
    @extend %heading;

    @include fontsize('XS');
}

/*
@name Headinggroup
@section Objects
@category typography
@type UI
@extended false
@description
Usually a `<header>` element that contains one or more headings. There should usually only a small space between the
headings. The big space between the headers is 'dictated' by the rule that a %heading in a flow (so `* + %heading`)
will get bigger space.

@markup
<header class="headingGroup">
    <h1 class="headingS">A h1-tag with class headingXS (styling equivalent of h4)</h1>
    <p class="headingXXL">This is a p-tag with class headingXXL (styling equivalent of h1)</h2>
</header>

*/
header.headingGroup {
    margin-bottom: toRem(16px);
}

/*
@name Special text styles
@section Basics
@category typography
@type UI
@extended false
@description
A few classes which can be attached on (preferably) `<span>` elements within text blocks to achieve additional styling.

@modifier .strikethrough   - Text with a diagonal strikethrough.
@modifier .alt             - Hides the text. This accessibile text is hidden for screen users, but still visible to screenreaders.

@markup
<p>
    This text has <b><span class="{{modifier_class}}">additional</span></b> style.
</p>

*/
.strikethrough {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        right: 0;
        border-top: toRem(1px) solid;
        border-color: inherit;
        transform: rotate(-25deg);
    }
}

.small {
    @include fontsize('body-small');
}

.large {
    @include fontsize('body-large');
}

.introText {
    @include fontsize('intro-text');
}

.screen-reader-text, // DEPRECTATED
.alt {
    @extend %visuallyhidden;
}
