/**
 * The calendar table of dates
 */
.picker__table {
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    font-size: inherit;
    width: 100%;
}

// Remove browser stylings on a table cell.
.picker__table td {
    margin: 0;
    padding: 0;
    border: toRem(1px) solid $ns-grey-90;

    &:first-child {
        border-left: 0;
    }

    &:last-child {
        border-right: 0;
    }
}

/**
 * The days on the calendar
 */
.picker__day {
    padding: 0;
    width: 100%;
    height: 100%;
    line-height: toRem(44px);
    color: $ns-light-blue;


    // Today.
    &.picker__day--today {
        position: relative;
    }

    &.picker__day--today::before {
        content: " ";
        position: absolute;
        width: 0;
        height: 0;
        left: toRem(20px);
        bottom: toRem(7px);
        border: toRem(2px) solid $ns-light-blue;
        border-radius: 100%;
    }

    &.picker__day--today.picker__day--highlighted::before,
    &.picker__day--today.picker__day--selected::before,
    &.picker__day--today:hover::before {
        border-color: $ns-white;
    }

    // Disabled day.
    &.picker__day--disabled::before {
        border-top-color: $ns-grey-70;
    }

    &.picker__day.picker__day--highlighted {
        @extend %picker-item-selected;
    }

    // Out of focus days.
    &.picker__day--outfocus {
        background-color: $ns-grey-94;
        color: $ns-grey-60;
    }

    &:hover {
        @extend %picker-item-hovered;
    }

    // Selected and hovered/focused dates.
    &.picker__day--selected,
    &.picker__day--selected:hover {
        @extend %picker-item-selected;
    }

    // Disabled dates.
    &.picker__day--disabled,
    &.picker__day--disabled:hover {
        background: $disabled-things-bg;
        border-color: $disabled-things-bg;
        color: $disabled-things-text;
        cursor: default;

        &:hover {
            color: $disabled-things-text;
        }
    }

    // Disabled and highlighted dates.
    &.picker__day--highlighted.picker__day--disabled,
    &.picker__day--highlighted.picker__day--disabled:hover {
        background: $disabled-highlighted-things-bg;
    }
}
