// STYLING BELOW SHOULD BE REFACTORED!

// A formfield where the label in display inside the input field.
// ie the homepage planner form.

.formfield--innerlabel {
    position: relative;

    .formfield__label {
        position: absolute;
        left: toRem($tile-space * 0.5);
        width: toRem(55px);
        color: $ns-blue;
        text-align: left;
    }

    .formfield__textInput {
        padding-right: toRem(15px);
        padding-left: toRem(70px);
    }

    .formfield__textInput--wideLabel {
        //Specialy for inner labels that are longer
        padding-left: toRem(86px);
    }

    .form-field__optionlist {
        list-style: none;

        .formfield__optionitem input {
            background: $white;
        }
    }
}

// --------------------------------------------------
//  form fields with two buttons positioned together
// --------------------------------------------------

.formfield__button-radio > .formfield__inner-container {
    @include clearfix;

    .button--extra {
        font-size: toRem(12px);
        color: $ns-grey-40;
        position: relative;
        z-index: 3;
    }

    .formfield__label {
        width: toRem(142px);
        position: relative;
        overflow: hidden;
        z-index: 3;

        .formfield__innerlabel {
            position: relative;
            z-index: 3;
        }
    }

    .formfield__radio-button {
        vertical-align: top;
    }

    .formfield__radio-button + .formfield__label {
        @extend %button;
        @extend %button--secondary;

        display: block;
        float: left;
    }

    .formfield__radio-button[type='radio'] {
        /* HIDE RADIO */
        @extend %visuallyhidden;
    }

    .formfield__radio-button[type='radio']:checked + .formfield__label {
        background-color: $button__primary__background;
        color: $button__primary__color;
        box-shadow: 0 toRem(-2px) 0 $button__primary__rollover inset;

        &:hover {
            box-shadow: 0 toRem(-50px) 0 $button__primary__rollover inset;
        }

        .button--extra {
            color: $ns-grey-90;
        }
    }
}

// --------------------------------------------------
//  Appereance by type for text input
// --------------------------------------------------

.textInput[type='date'] {
    -webkit-appearance: textfield;
}

// --------------------------------------------------
//  This is a modifier to assign error css to the
//  invalid components
// --------------------------------------------------

.is-invalid {
    .formfield__textInput {
        border-bottom: toRem(2px) solid $ns-red-alert !important;
    }

    .formfield__error {
        display: block;
    }
}
