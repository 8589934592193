$color-heading-contact: $ns-grey-20;

.cms-ContactBlock {
    margin-bottom: toRem(32px);

    @include respond-min($m) {
        margin-bottom: 0;
    }

    @include respond-min($l) {
        min-height: toRem(195px);
    }

    p {
        &:first-of-type {
            color: $ns-grey-40;
        }
    }

    h2 {
        @include fontsize('M');
    }

    h3 {
        margin: 0;
        color: $heading-color; //$color-heading-contact;
    }

    table {
        width: 100%;

        @include fontsize('body-large');

        th {
            font-weight: normal;
            text-align: left;
            padding-right: toRem(15px);
        }

        td,
        th {
            padding-top: toRem(10px);
        }

        td {
            white-space: nowrap;
            vertical-align: top;
        }
    }

    .vcard {
        margin-bottom: toRem($font-size-base * 0.5);

        h3.fn.org {
            @include fontsize('body');
        }

        .tel {
            @extend %heading--M;

            a {
                @extend %link-reset;

                color: $ns-grey;
            }
        }
    }

    &.is-disabled {
        .cms-ContactBlock__header {
            color: $ns-grey-40;
        }
    }
}

.cms-ContactBlock__status {
    font-weight: normal;
    font-style: italic;

    @include fontsize('body-small');

    &::before {
        content: '\02013';
        margin-right: toRem(5px);
    }
}
