.languageswitch {
    color: $ns-blue;
    height: toRem($line-height-base);

    &.languageswitch--en,
    &.languageswitch--nl {
        @include iconLink((
            'padding-left': 30px
        ));
    }

    &.languageswitch--en {
        @include iconLink__iconAndColor('flag-en', $ns-blue, $ns-blue, ':before', false);
    }

    &.languageswitch--nl {
        @include iconLink__iconAndColor('flag-nl', $ns-blue, $ns-blue, ':before', false);
    }
}
