picture {
    display: block;
    height: 100%;
    width: 100%;

    img {
        display: block;
    }
}

.img--fluid {
    min-width: 100%;
    max-width: 100%;
}

.fittedImage {
    position: relative;
    overflow: hidden;
}

.fittedImage img,
.fittedImage__img,
.img--contain {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;

    .no-object-fit & {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 100%;
        height: auto;

        &.is-wider {
            height: 100%;
            width: auto;
        }
    }

    .no-csstransforms3d.no-object-fit & {
        transform: translate(-50%, -50%);
    }
}

.img--center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
