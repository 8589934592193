/*
@name bodyItemQuote
@description bodyItemQuote

@section Objects
@type UI
@extended false

@markup

<div class="bodyItem--quote">
    <div class="bodyItemQuote">
        <h2 class="alt">Title</h2>
        <div class="bodyItemQuote__container">
            <img class="bodyItemQuote__image" src="<%= path.styleguideStatic %>/images/quote/marit.jpg" alt="afbeelding van Marit van NS" />

            <div class="bodyItemQuote__content">
                <blockquote class="bodyItemQuote__blockquote" >
                    <p class="bodyItemQuote__text">“Ik reis elke maand weer anders. Soms veel in het weekend, of ik pak 's avonds na het sporten de trein. Fijn dat ik mijn NS Flex-abonnement daarop kan aanpassen en zo slim bespaar op mijn reiskosten. Waar ga jij vandaag heen?”</p>
                    <cite class="bodyItemQuote__author">Marit van NS</cite>
                </blockquote>
                <a href="#" class="arrowLink">Lees het verhaal van Marit</a>
            </div>
        </div>
    </div>
</div>
*/

.bodyItemQuote {
    padding: toRem(40px) toRem($tile-space);
    background-color: $ns-grey-94;

    @include respond-min($m) {
        padding: #{toRem($global--grid-gutter * 2)} toRem($tile-space);
    }

    @include respond-min($xl) {
        padding: #{toRem($global--grid-gutter * 2)} toRem($tile-space * 3) #{toRem($global--grid-gutter * 2)}  #{toRem($tile-space)};
    }
}

.bodyItemQuote__container {
    display: grid;
    grid-template-columns: minmax(0, auto);

    @include respond-min($ml) {
        gap: 0 49px;
        grid-template-columns: minmax(0, auto) minmax(0, 1fr);
    }
}

.bodyItemQuote__content {
    text-align: center;

    @include respond-min($ml) {
        text-align: left;
    }
}

.bodyItemQuote__image {
    width: 172px;
    height: 172px;
    border-radius: 50%;
    margin-bottom: toRem($global--grid-gutter);
    justify-self: center;

    @include respond-min($l) {
        margin-bottom: 0;
    }
}

.bodyItemQuote__blockquote {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
}

.bodyItemQuote__author {
    @include fontsize('L');

    color: $ns-blue;
    font-style: normal;
    line-height: 1.25;
    margin-bottom: 16px;
}
