/*
@name Banners
@description A transparent tile banner with a text and button. We have added `.tile--banner` to the tile and `.tile--fullyTransparent` to
  show how this would usually be used.
@section Components
@background transparent
@hideClassName true

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12">
        <div class="pf-util-border tile tile--banner tile--has-footer tile--fullyTransparent" data-controller="generic/ui/ClickableTile">
            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h3 class="tile__bannerHeading tile__bannerHeading--strong">Header tegel</h3>
                </div>
            </div>
            <div class="tile__footer">
                <a href="javascript:void(0)" class="button" title="Voorleestitel uit bannerinhoud in het CMS">
                    Lees meer
                </a>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Banner with appstore buttons
@parent Banners
@description A tile banner with two appstore buttons

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12">
        <div class="pf-util-border tile tile--banner tile--has-footer tile--fullyTransparent">
            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h3 class="tile__bannerHeading tile__bannerHeading--strong">Header tegel</h3>
                </div>
            </div>
            <div class="tile__footer">
                <div class="buttonGroup">
                    <a href="javascript:void(0)" class="button button--appStore" title="Voorleestitel uit bannerinhoud in het CMS">
                        Download in de App Store
                    </a>
                    <a href="javascript:void(0)" class="button button--playStore" title="Voorleestitel uit bannerinhoud in het CMS">
                        Ontdek het op Google Play
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Banner, Dark text, with subheading
@parent Banners
@description A tile banner with a text, button and image in picture element. We have added `.tile--banner` to the tile and `.tile--whiteText` to
  show how this would usually be used. Normally the button would be the primary button (color: blue), but for the scope of this banner
  `.button--secondary` has been added to the button.<br />
  The `width` and `height` for the following `{breakpoints}` are: <br />
  l: 480px x 480px<br />
  m: 480px x 480px<br />
  s: 660px x 660px<br />

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12">
        <div class="tile tile--banner tile--has-footer" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/videobanner/oostvaardersplassenmistslt-480.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/videobanner/oostvaardersplassenmistslt-660.jpg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
                </picture>
            </div>

            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h3 class="tile__bannerHeading tile__bannerHeading--strong">Header tegel</h3>
                    <p class="tile__bannerSubheading">en sub maximaal 90 karakters inclusief spaties voor het beste resultaat</p>
                </div>
            </div>
            <div class="tile__footer">
                <a href="javascript:void(0)" class="button" title="Voorleestitel uit bannerinhoud in het CMS">
                    Lees meer
                </a>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Banner with SVG, Dark text, with only heading
@parent Banners
@description A transparent tile banner with a text, button and a SVG in picture element. We have added `.tile--banner` to the tile and `.tile--fullyTransparent` to
  show how this would usually be used.
  <br /><br />
  The xml structure of SVG contains: a `width`, `height` and a `viewBox` attribute in main `svg` element together with `preserveAspectRatio="xMaxYMax slice"`.<br />
  The `width` and `height` for the following `{breakpoints}` are: <br />
  l: 480px x 480px<br />
  m: 480px x 480px<br />
  s: 660px x 660px<br />
@background transparent

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12">
        <div class="pf-util-border tile tile--banner tile--has-footer tile--fullyTransparent" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-enkel.svg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Description of image" class="img--contain">
                </picture>
            </div>

            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h3 class="tile__bannerHeading tile__bannerHeading--strong">Header tegel</h3>
                </div>
            </div>
            <div class="tile__footer">
                <a href="javascript:void(0)" class="button" title="Voorleestitel uit bannerinhoud in het CMS">
                    Lees meer
                </a>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Banner liggend, Light text, with only heading
@parent Banners
@description A tile banner with a text, button and image in picture element. We have added `.tile--banner` to the tile and `.tile--whiteText` to
  show how this would usually be used. Normally the button would be the primary button (color: blue), but for the scope of this banner
  `.button--secondary` has been added to the button. We have changed the `l-4-12` to `l-8-12` to get the effect of double width banner <br />
  The `width` and `height` for the following `{breakpoints}` are: <br />
  l: 680px x 480px<br />
  m: 480px x 480px<br />
  s: 660px x 660px<br />

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-8-12" data-responsivegrid-height-l="1.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--whiteText tile--bannerContentLeft tile--has-footer tile--bannerContentLeft" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/banner_horizontaal_landing_790x563.jpg" media="(min-width: <%= breakpoints.large %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/banner_horizontaal_landing_790x563.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/banner_horizontaal_landing_790x563.jpg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
                </picture>
            </div>
            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h4 class="tile__bannerHeading">Header tegel met <strong>bold</strong> teksten voor bijvoorbeeld <strong>NS Flex</strong></h4>
                </div>
            </div>
            <div class="tile__footer">
                <a href="javascript:void(0)" class="button button--secondary" title="Voorleestitel uit bannerinhoud in het CMS">
                    Lees meer
                </a>
            </div>

        </div>
    </div>
</div>
*/

/*
@name Banner liggend with SVG, Dark text, with subheading
@parent Banners
@description A tile banner with a text, button and SVG image in picture element. We have added `.tile--banner` to the tile. This ensures that the default behavior gets
  triggered when no extra classes are initiated, such as to influence the text color and button color. we have changed the `l-4-12` to `l-8-12` to
  get the effect of double width banner<br />
  The `width` and `height` for the following `{breakpoints}` are: <br />
  l: 680px x 480px<br />
  m: 480px x 480px<br />
  s: 660px x 660px<br />

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-8-12" data-responsivegrid-height-l="1.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--bannerContentLeft tile--has-footer tile--bannerContentLeft" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-liggend.svg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-enkel.svg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Description of image" class="img--contain">
                </picture>
            </div>
            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h4 class="tile__bannerHeading tile__bannerHeading--strong">Header tegel</h4>
                    <p class="tile__bannerSubheading">en sub maximaal 90 karakters inclusief spaties voor het beste resultaat</p>
                </div>
            </div>
            <div class="tile__footer">
                <a href="javascript:void(0)" class="button" title="Voorleestitel uit bannerinhoud in het CMS">
                    Lees meer
                </a>
            </div>

        </div>
    </div>
</div>
*/

/*
@name Banner liggend Large, Dark text, with subheading
@parent Banners
@description Banner liggend Large, Dark text, with subheading

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-12-12 l-12-12" data-responsivegrid-height-l="1.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--bannerContentLeft tile--has-footer tile--bannerContentLeft tile--extra-wide" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/videobanner/oostvaardersplassenmistslt-1172.jpg" media="(min-width: <%= breakpoints.large %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/videobanner/oostvaardersplassenmistslt-860.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/videobanner/oostvaardersplassenmistslt-660.jpg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
                </picture>
            </div>
            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h5 class="tile__bannerHeading tile__bannerHeading--strong">Header tegel</h5>
                    <p class="tile__bannerSubheading">en sub maximaal 90 karakters inclusief spaties voor het beste resultaat</p>
                </div>
            </div>
            <div class="tile__footer">
                <a href="javascript:void(0)" class="button" title="Voorleestitel uit bannerinhoud in het CMS">
                    Lees meer
                </a>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Banner liggend Large with SVG, Dark text, with heading
@parent Banners
@description Banner liggend Large with SVG, Dark text, with heading

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-12-12 l-12-12" data-responsivegrid-height-l="1.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--bannerContentLeft tile--has-footer tile--bannerContentLeft tile--extra-wide" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-liggend.svg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-enkel.svg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Description of image" class="img--contain">
                </picture>
            </div>
            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h5 class="tile__bannerHeading">Header tegel met <strong>bold</strong> teksten voor bijvoorbeeld <strong>NS Flex</strong></h5>
                </div>
            </div>
            <div class="tile__footer">
                <a href="javascript:void(0)" class="button" title="Voorleestitel uit bannerinhoud in het CMS">
                    Lees meer
                </a>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Banner liggend Large with no background, Dark text, with heading and subheading
@parent Banners
@description Banner liggend Large with no background, Dark text, with heading and subheading. We have added .tile--fullText to the tile. This ensures that the content until the large breakpoint is the full width of the tile, from the large breakpoint and beyond it will be the 'default' width.

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-12-12 l-12-12" data-responsivegrid-height-l="1.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--bannerContentLeft tile--has-footer tile--fullText" data-controller="generic/ui/ClickableTile">
            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h5 class="tile__bannerHeading tile__bannerHeading--strong">Header bannertegel</h5>
                    <p class="tile__bannerSubheading">en sub maximaal 90 karakters inclusief spaties voor het beste resultaat</p>
                </div>
            </div>
            <div class="tile__footer">
                <a href="javascript:void(0)" class="button" title="Voorleestitel uit bannerinhoud in het CMS">
                    Lees meer
                </a>
                <a href="javascript:void(0)" class="arrowLink tile__link" title="Voorleestitel uit bannerinhoud in het CMS">
                    Bekijk meer
                </a>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Banner liggend with no background, Dark text, with heading and subheading
@parent Banners
@description Banner liggend with no background, Dark text, with heading and subheading. We have added .tile--fullText to the tile. This ensures that the content is the full width of the tile.

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-8-12" data-responsivegrid-height-l="1.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--bannerContentLeft tile--has-footer tile--fullText" data-controller="generic/ui/ClickableTile">
            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h5 class="tile__bannerHeading tile__bannerHeading--strong">Header bannertegel</h5>
                    <p class="tile__bannerSubheading">en sub maximaal 90 karakters inclusief spaties voor het beste resultaat</p>
                </div>
            </div>
            <div class="tile__footer">
                <a href="javascript:void(0)" class="button" title="Voorleestitel uit bannerinhoud in het CMS">
                    Lees meer
                </a>
                <a href="javascript:void(0)" class="arrowLink tile__link" title="Voorleestitel uit bannerinhoud in het CMS">
                    Bekijk meer
                </a>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Banner staand, Light text, with heading
@parent Banners
@description Banner staand, Light text, with heading at bottom<br /><br />
<strong>Note: </strong><br />
If you want the text in the banner to vertically align with its neighbour(s) add <code>data-responsivegrid-height-add-to-next-row="true"</code> to the banner's <code>.grid__unit</code> element.<br />
As a result all <code>.grid__unit</code> elements in the same grid rows as the banner will have the same height.

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12" data-responsivegrid-height-l="2.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--whiteText tile--bannerContentBottom tile--has-footer tile--bannerContentBottom" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/uitgelicht_landing_ov-chipkaart_480x480.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/uitgelicht_landing_ov-chipkaart_660x660.jpg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
                </picture>
            </div>

            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h6 class="tile__bannerHeading">Header tegel met <strong>bold</strong> teksten voor bijvoorbeeld <strong>NS Flex</strong></h6>
                </div>
            </div>
            <div class="tile__footer">
                <a href="javascript:void(0)" class="button" title="Voorleestitel uit bannerinhoud in het CMS">
                    Lees meer
                </a>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Banner staand with SVG, Dark text, with subheading
@parent Banners
@description Banner staand with SVG, Dark text, with subheading at bottom<br /><br />
<strong>Note: </strong><br />
If you want the text in the banner to vertically align with its neighbour(s) add <code>data-responsivegrid-height-add-to-next-row="true"</code> to the banner's <code>.grid__unit</code> element.<br />
As a result all <code>.grid__unit</code> elements in the same grid rows as the banner will have the same height.

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12" data-responsivegrid-height-l="2.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--bannerContentBottom tile--has-footer tile--bannerContentBottom" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-liggend.svg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-enkel.svg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Description of image" class="img--contain">
                </picture>
            </div>

            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h6 class="tile__bannerHeading tile__bannerHeading--strong">Header tegel</h6>
                    <p class="tile__bannerSubheading">en sub maximaal 90 karakters inclusief spaties voor het beste resultaat</p>
                </div>
            </div>
            <div class="tile__footer">
                <a href="javascript:void(0)" class="button" title="Voorleestitel uit bannerinhoud in het CMS">
                    Lees meer
                </a>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Banner staand with SVG, Dark text top, with heading
@parent Banners
@description Banner staand with SVG, Dark text top, with heading<br /><br />
<strong>Note: </strong><br />
If you want the text in the banner to vertically align with its neighbour(s) add <code>data-responsivegrid-height-add-to-next-row="true"</code> to the banner's <code>.grid__unit</code> element.<br />
As a result all <code>.grid__unit</code> elements in the same grid rows as the banner will have the same height.

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12" data-responsivegrid-height-l="2.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--has-footer" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-liggend.svg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-enkel.svg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Description of image" class="img--contain">
                </picture>
            </div>

            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h6 class="tile__bannerHeading">Header tegel met <strong>bold</strong> teksten voor bijvoorbeeld <strong>NS Flex</strong></h6>
                </div>
            </div>
            <div class="tile__footer">
                <a href="javascript:void(0)" class="button" title="Voorleestitel uit bannerinhoud in het CMS">
                    Lees meer
                </a>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Banner staand, Light text top, with subheading
@parent Banners
@description Banner staand, Light text top, with subheading<br /><br />
<strong>Note: </strong><br />
If you want the text in the banner to vertically align with its neighbour(s) add <code>data-responsivegrid-height-add-to-next-row="true"</code> to the banner's <code>.grid__unit</code> element.<br />
As a result all <code>.grid__unit</code> elements in the same grid rows as the banner will have the same height.

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12" data-responsivegrid-height-l="2.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--whiteText tile--has-footer" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/uitgelicht_landing_ov-chipkaart_480x480.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/uitgelicht_landing_ov-chipkaart_660x660.jpg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
                </picture>
            </div>

            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h6 class="tile__bannerHeading tile__bannerHeading--strong">Header tegel</h6>
                    <p class="tile__bannerSubheading">en sub maximaal 90 karakters inclusief spaties voor het beste resultaat</p>
                </div>
            </div>
            <div class="tile__footer">
                <a href="javascript:void(0)" class="button" title="Voorleestitel uit bannerinhoud in het CMS">
                    Lees meer
                </a>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Video banner, Dark text, with subheading
@parent Banners
@description Video banner, Dark text, with subheading

@markup
<!-- Don't copy the script element -->
<script src="//players.brightcove.net/3910869727001/r1grbpyUjf_default/index.min.js"></script>
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12">
        <div class="tile tile--banner tile--has-bottom-controls tile--has-footer" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/videobanner/oostvaardersplassenmistslt-480.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/videobanner/oostvaardersplassenmistslt-660.jpg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
                </picture>
            </div>

            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h3 class="tile__bannerHeading tile__bannerHeading--strong">Header videotegel</h3>
                    <p class="tile__bannerSubheading">en sub maximaal 90 karakters inclusief spaties voor het beste resultaat</p>
                </div>
            </div>
            <div class="tile__footer">
                <button class="playButton tile__playButton"  title="Bijschrift bij de Video die hier staat" data-controller="generic/ui/video/VideoButton" data-config="{&quot;videoId&quot;: &quot;5477459690001&quot;,&quot;account&quot;: &quot;3910869727001&quot;,&quot;player&quot;: &quot;r1grbpyUjf&quot;}">
                    <span class="alt">Speel Video af</span>
                    <span class="icon icon--play-solid icon--nsWhite"></span>
                </button>
                <time aria-label="tijdsduur: 1 minuut 3 seconden" datetime="PT1M3S" class="tile__videoDuration">
                    1:03
                </time>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Video banner with SVG, Dark text, with only heading
@parent Banners
@description Video banner with SVG, Dark text, with only heading

@markup
<!-- Don't copy the script element -->
<script src="//players.brightcove.net/3910869727001/r1grbpyUjf_default/index.min.js"></script>
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12">
        <div class="tile tile--banner tile--has-bottom-controls tile--has-footer" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-liggend.svg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-enkel.svg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Description of image" class="img--contain">
                </picture>
            </div>

            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h3 class="tile__bannerHeading tile__bannerHeading--strong">Header videotegel</h3>
                </div>
            </div>
            <div class="tile__footer">
                <button class="playButton tile__playButton"  title="Bijschrift bij de Video die hier staat" data-controller="generic/ui/video/VideoButton" data-config="{&quot;videoId&quot;: &quot;5477459690001&quot;,&quot;account&quot;: &quot;3910869727001&quot;,&quot;player&quot;: &quot;r1grbpyUjf&quot;}">
                    <span class="alt">Speel Video af</span>
                    <span class="icon icon--play-solid icon--nsWhite"></span>
                </button>
                <time aria-label="tijdsduur: 1 minuut 3 seconden" datetime="PT1M3S" class="tile__videoDuration">
                    1:03
                </time>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Video banner liggend, Light text, with only heading
@parent Banners
@description Video banner liggend, Light text, with only heading

@markup
<!-- Don't copy the script element -->
<script src="//players.brightcove.net/3910869727001/r1grbpyUjf_default/index.min.js"></script>
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-8-12" data-responsivegrid-height-l="1.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--whiteText tile--has-bottom-controls tile--has-footer tile--bannerContentLeft" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/banner_horizontaal_landing_790x563.jpg" media="(min-width: <%= breakpoints.large %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/banner_horizontaal_landing_790x563.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/banner_horizontaal_landing_790x563.jpg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
                </picture>
            </div>
            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h4 class="tile__bannerHeading">Header videotegel met <strong>bold</strong> teksten voor bijvoorbeeld <strong>NS Flex</strong></h4>
                </div>
            </div>
            <div class="tile__footer">
                <button class="playButton tile__playButton"  title="Bijschrift bij de Video die hier staat" data-controller="generic/ui/video/VideoButton" data-config="{&quot;videoId&quot;: &quot;5477459690001&quot;,&quot;account&quot;: &quot;3910869727001&quot;,&quot;player&quot;: &quot;r1grbpyUjf&quot;}">
                    <span class="alt">Speel Video af</span>
                    <span class="icon icon--play-solid icon--nsWhite"></span>
                </button>
                <time aria-label="tijdsduur: 1 minuut 3 seconden" datetime="PT1M3S" class="tile__videoDuration">
                    1:03
                </time>
            </div>

        </div>
    </div>
</div>
*/

/*
@name Video banner liggend with SVG, Dark text, with subheading
@parent Banners
@description Video banner liggend with SVG, Dark text, with subheading

@markup
<!-- Don't copy the script element -->
<script src="//players.brightcove.net/3910869727001/r1grbpyUjf_default/index.min.js"></script>
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-8-12" data-responsivegrid-height-l="1.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--has-bottom-controls tile--has-footer tile--bannerContentLeft" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-liggend.svg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-enkel.svg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Description of image" class="img--contain">
                </picture>
            </div>
            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h4 class="tile__bannerHeading tile__bannerHeading--strong">Header videotegel</h4>
                    <p class="tile__bannerSubheading">en sub maximaal 90 karakters inclusief spaties voor het beste resultaat</p>
                </div>
            </div>
            <div class="tile__footer">
                <button class="playButton tile__playButton"  title="Bijschrift bij de Video die hier staat" data-controller="generic/ui/video/VideoButton" data-config="{&quot;videoId&quot;: &quot;5477459690001&quot;,&quot;account&quot;: &quot;3910869727001&quot;,&quot;player&quot;: &quot;r1grbpyUjf&quot;}">
                    <span class="alt">Speel Video af</span>
                    <span class="icon icon--play-solid icon--nsWhite"></span>
                </button>
                <time aria-label="tijdsduur: 1 minuut 3 seconden" datetime="PT1M3S" class="tile__videoDuration">
                    1:03
                </time>
            </div>

        </div>
    </div>
</div>
*/

/*
@name Video banner liggend Large, Dark text, with subheading
@parent Banners
@description Video banner liggend Large, Dark text, with subheading

@markup
<!-- Don't copy the script element -->
<script src="//players.brightcove.net/3910869727001/r1grbpyUjf_default/index.min.js"></script>
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-12-12 l-12-12" data-responsivegrid-height-l="1.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--has-bottom-controls tile--has-footer tile--bannerContentLeft tile--extra-wide" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/videobanner/oostvaardersplassenmistslt-1172.jpg" media="(min-width: <%= breakpoints.large %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/videobanner/oostvaardersplassenmistslt-860.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/videobanner/oostvaardersplassenmistslt-660.jpg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
                </picture>
            </div>
            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h5 class="tile__bannerHeading tile__bannerHeading--strong">Header videotegel</h5>
                    <p class="tile__bannerSubheading">en sub maximaal 90 karakters inclusief spaties voor het beste resultaat</p>
                </div>
            </div>
            <div class="tile__footer">
                <button class="playButton tile__playButton"  title="Bijschrift bij de Video die hier staat" data-controller="generic/ui/video/VideoButton" data-config="{&quot;videoId&quot;: &quot;5477459690001&quot;,&quot;account&quot;: &quot;3910869727001&quot;,&quot;player&quot;: &quot;r1grbpyUjf&quot;}">
                    <span class="alt">Speel Video af</span>
                    <span class="icon icon--play-solid icon--nsWhite"></span>
                </button>
                <time aria-label="tijdsduur: 1 minuut 3 seconden" datetime="PT1M3S" class="tile__videoDuration">
                    1:03
                </time>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Video banner liggend Large with SVG, Dark text, with heading
@parent Banners
@description Video banner liggend Large with SVG, Dark text, with heading

@markup
<!-- Don't copy the script element -->
<script src="//players.brightcove.net/3910869727001/r1grbpyUjf_default/index.min.js"></script>
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-12-12 l-12-12" data-responsivegrid-height-l="1.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--has-bottom-controls tile--has-footer tile--bannerContentLeft tile--extra-wide" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-liggend.svg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-enkel.svg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Description of image" class="img--contain">
                </picture>
            </div>
            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h5 class="tile__bannerHeading">Header videotegel met <strong>bold</strong> teksten voor bijvoorbeeld <strong>NS Flex</strong></h5>
                </div>
            </div>
            <div class="tile__footer">
                <button class="playButton tile__playButton"  title="Bijschrift bij de Video die hier staat" data-controller="generic/ui/video/VideoButton" data-config="{&quot;videoId&quot;: &quot;5477459690001&quot;,&quot;account&quot;: &quot;3910869727001&quot;,&quot;player&quot;: &quot;r1grbpyUjf&quot;}">
                    <span class="alt">Speel Video af</span>
                    <span class="icon icon--play-solid icon--nsWhite"></span>
                </button>
                <time aria-label="tijdsduur: 1 minuut 3 seconden" datetime="PT1M3S" class="tile__videoDuration">
                    1:03
                </time>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Video banner staand, Light text, with heading
@parent Banners
@description Video banner staand, Light text, with heading at bottom<br /><br />
<strong>Note: </strong><br />
If you want the text in the banner to vertically align with its neighbour(s) add <code>data-responsivegrid-height-add-to-next-row="true"</code> to the banner's <code>.grid__unit</code> element.<br />
As a result all <code>.grid__unit</code> elements in the same grid rows as the banner will have the same height.

@markup
<!-- Don't copy the script element -->
<script src="//players.brightcove.net/3910869727001/r1grbpyUjf_default/index.min.js"></script>
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12" data-responsivegrid-height-l="2.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--whiteText tile--has-bottom-controls tile--has-footer tile--bannerContentBottom" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/uitgelicht_landing_ov-chipkaart_480x480.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/uitgelicht_landing_ov-chipkaart_660x660.jpg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
                </picture>
            </div>

            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h6 class="tile__bannerHeading">Header videotegel met <strong>bold</strong> teksten voor bijvoorbeeld <strong>NS Flex</strong></h6>
                </div>
            </div>
            <div class="tile__footer">
                <button class="playButton tile__playButton"  title="Bijschrift bij de Video die hier staat" data-controller="generic/ui/video/VideoButton" data-config="{&quot;videoId&quot;: &quot;5477459690001&quot;,&quot;account&quot;: &quot;3910869727001&quot;,&quot;player&quot;: &quot;r1grbpyUjf&quot;}">
                    <span class="alt">Speel Video af</span>
                    <span class="icon icon--play-solid icon--nsWhite"></span>
                </button>
                <time aria-label="tijdsduur: 1 minuut 3 seconden" datetime="PT1M3S" class="tile__videoDuration">
                    1:03
                </time>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Video banner staand with SVG, Dark text, with subheading
@parent Banners
@description Video banner staand with SVG, Dark text, with subheading at bottom<br /><br />
<strong>Note: </strong><br />
If you want the text in the banner to vertically align with its neighbour(s) add <code>data-responsivegrid-height-add-to-next-row="true"</code> to the banner's <code>.grid__unit</code> element.<br />
As a result all <code>.grid__unit</code> elements in the same grid rows as the banner will have the same height.

@markup
<!-- Don't copy the script element -->
<script src="//players.brightcove.net/3910869727001/r1grbpyUjf_default/index.min.js"></script>
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12" data-responsivegrid-height-l="2.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--has-bottom-controls tile--has-footer tile--bannerContentBottom" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-liggend.svg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-enkel.svg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Description of image" class="img--contain">
                </picture>
            </div>

            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h6 class="tile__bannerHeading tile__bannerHeading--strong">Header videotegel</h6>
                    <p class="tile__bannerSubheading">en sub maximaal 90 karakters inclusief spaties voor het beste resultaat</p>
                </div>
            </div>
            <div class="tile__footer">
                <button class="playButton tile__playButton"  title="Bijschrift bij de Video die hier staat" data-controller="generic/ui/video/VideoButton" data-config="{&quot;videoId&quot;: &quot;5477459690001&quot;,&quot;account&quot;: &quot;3910869727001&quot;,&quot;player&quot;: &quot;r1grbpyUjf&quot;}">
                    <span class="alt">Speel Video af</span>
                    <span class="icon icon--play-solid icon--nsWhite"></span>
                </button>
                <time aria-label="tijdsduur: 1 minuut 3 seconden" datetime="PT1M3S" class="tile__videoDuration">
                    1:03
                </time>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Video banner staand with SVG, Dark text top, with heading
@parent Banners
@description Video banner staand with SVG, Dark text top, with heading<br /><br />
<strong>Note: </strong><br />
If you want the text in the banner to vertically align with its neighbour(s) add <code>data-responsivegrid-height-add-to-next-row="true"</code> to the banner's <code>.grid__unit</code> element.<br />
As a result all <code>.grid__unit</code> elements in the same grid rows as the banner will have the same height.

@markup
<!-- Don't copy the script element -->
<script src="//players.brightcove.net/3910869727001/r1grbpyUjf_default/index.min.js"></script>
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12" data-responsivegrid-height-l="2.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--has-bottom-controls tile--has-footer" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-liggend.svg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/banner/banner_fiets-enkel.svg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Description of image" class="img--contain">
                </picture>
            </div>

            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h6 class="tile__bannerHeading">Header videotegel met <strong>bold</strong> teksten voor bijvoorbeeld <strong>NS Flex</strong></h6>
                </div>
            </div>
            <div class="tile__footer">
                <button class="playButton tile__playButton"  title="Bijschrift bij de Video die hier staat" data-controller="generic/ui/video/VideoButton" data-config="{&quot;videoId&quot;: &quot;5477459690001&quot;,&quot;account&quot;: &quot;3910869727001&quot;,&quot;player&quot;: &quot;r1grbpyUjf&quot;}">
                    <span class="alt">Speel Video af</span>
                    <span class="icon icon--play-solid icon--nsWhite"></span>
                </button>
                <time aria-label="tijdsduur: 1 minuut 3 seconden" datetime="PT1M3S" class="tile__videoDuration">
                    1:03
                </time>
            </div>
        </div>
    </div>
</div>
*/

/*
@name Video banner staand, Light text top, with subheading
@parent Banners
@description Video banner staand, Light text top, with subheading<br /><br />
<strong>Note: </strong><br />
If you want the text in the banner to vertically align with its neighbour(s) add <code>data-responsivegrid-height-add-to-next-row="true"</code> to the banner's <code>.grid__unit</code> element.<br />
As a result all <code>.grid__unit</code> elements in the same grid rows as the banner will have the same height.

@markup
<!-- Don't copy the script element -->
<script src="//players.brightcove.net/3910869727001/r1grbpyUjf_default/index.min.js"></script>
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12 l-4-12" data-responsivegrid-height-l="2.0" data-responsivegrid-height-m="1.0">
        <div class="tile tile--banner tile--whiteText tile--has-bottom-controls tile--has-footer" data-controller="generic/ui/ClickableTile">
            <div class="tile__backgroundImage">
                <picture>
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/uitgelicht_landing_ov-chipkaart_480x480.jpg" media="(min-width: <%= breakpoints.medium %>px)">
                    <source srcset="<%= path.styleguideStatic %>/images/uitgelicht/uitgelicht_landing_ov-chipkaart_660x660.jpg" media="(min-width: <%= breakpoints.small %>px)">
                    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Description of image" class="img--contain">
                </picture>
            </div>

            <div class="tile__content">
                <div class="tile__bannerContent">
                    <h6 class="tile__bannerHeading tile__bannerHeading--strong">Header videotegel</h6>
                    <p class="tile__bannerSubheading">en sub maximaal 90 karakters inclusief spaties voor het beste resultaat</p>
                </div>
            </div>
            <div class="tile__footer">
                <button class="playButton tile__playButton"  title="Bijschrift bij de Video die hier staat" data-controller="generic/ui/video/VideoButton" data-config="{&quot;videoId&quot;: &quot;5477459690001&quot;,&quot;account&quot;: &quot;3910869727001&quot;,&quot;player&quot;: &quot;r1grbpyUjf&quot;}">
                    <span class="alt">Speel Video af</span>
                    <span class="icon icon--play-solid icon--nsWhite"></span>
                </button>
                <time aria-label="tijdsduur: 1 minuut 3 seconden" datetime="PT1M3S" class="tile__videoDuration">
                    1:03
                </time>
            </div>
        </div>
    </div>
</div>
*/

$banner-height: toRem(270px);
$banner-height-m: toRem(330px);
$banner-footer-height: toRem($button-height);

$banner-default-top-padding: toRem($tile-space + 10px);
$banner-default-bottom-padding: toRem(32px);
$banner-bottom-controls-padding: toRem($global--grid-halfgutter * 5);

.tile--fullyTransparent {
    background: transparent;
    box-shadow: none;
}

@mixin no-background {
    &:not(.tile--whiteText) {
        .tile__backgroundImage::after {
            display: none;
        }
    }
}

%banner {
    min-height: 100%;
    height: $banner-height;

    @include respond-min($m) {
        min-height: 100%;
        height: $banner-height-m;
    }
}

.tile--contentBanner {
    @extend %banner;

    /*
    & img { // TODO: animation effect for actionbanner
        transition: all .2s ease;
        width: 107%;
        &:hover {
            width: 100%;
        }
    }
    */

    @include respond-min($m) {
        padding-right: toRem(18px);
    }
}

@mixin leftAlignedBannerContent($contentWidth: 50%, $gradientWidth: 75%, $gradientEndOpacity: 0) {
    .tile__backgroundImage::after {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, $gradientEndOpacity) $gradientWidth);
    }

    //when text is not white, remove background-image
    @include no-background;

    .tile__content {
        .tile__bannerContent {
            width: $contentWidth;
            margin-right: toRem($global--grid-halfgutter + $tile-space);
        }
    }
}

@mixin rightAlignedBannerContent($contentWidth: 50%, $gradientWidth: 75%, $gradientEndOpacity: 0) {
    .tile__backgroundImage::after {
        background-image: linear-gradient(to right, rgba(0, 0, 0, $gradientEndOpacity) (100% - $gradientWidth), rgba(0, 0, 0, 0.4) 100%);
    }

    //when text is not white, remove background-image
    @include no-background;

    .tile__content {
        .tile__bannerContent {
            position: relative;
            left: calc(100% - #{$contentWidth});
            width: $contentWidth;
            margin-left: toRem($global--grid-halfgutter + $tile-space);
            padding-right: toRem($global--grid-halfgutter + $tile-space);
        }
    }

    .tile__footer {
        .button {
            left: 50%;
            margin-left: toRem($global--grid-halfgutter + $tile-space);
            margin-right: 0;
        }
    }

    .tile__link {
        left: calc(50% + #{$global--grid-halfgutter});
    }
}

.tile--banner {
    @extend %banner;

    padding-top: $banner-default-top-padding;

    .tile__backgroundImage::after {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.2) 100%);
    }

    //when text is not white, remove background-image
    @include no-background;

    .tile__content {
        .tile__bannerContent {
            color: $ns-blue;
            text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
        }
    }

    .tile__link {
        margin-top: toRem(20px);
    }

    .tile__footer {
        > .button {
            margin-right: $global--grid-halfgutter;
        }

        .buttonGroup {
            margin-top: 0;

            .button {
                margin-bottom: 0;
                margin-top: toRem($global--grid-halfgutter);
                margin-right: toRem($font-size-base);

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    // Replacement of grid class styling below
    &.tile--bannerContentLeft {
        @include respond-min($m) {
            &.tile--extra-wide {
                @include leftAlignedBannerContent;
            }

            &.tile--fullText {
                @include leftAlignedBannerContent(100%, 100%, 0.2);
            }
        }

        @include respond-min($l) {
            @include leftAlignedBannerContent;

            &.tile--extra-wide {
                @include leftAlignedBannerContent(66%, 90%);
            }
        }
    }

    &.tile--bannerContentRight {
        @include respond-min($m) {
            &.tile--extra-wide {
                @include rightAlignedBannerContent;
            }

            &.tile--fullText {
                @include rightAlignedBannerContent(100%, 100%, 0.2);
            }
        }

        @include respond-min($l) {
            @include rightAlignedBannerContent;

            &.tile--extra-wide {
                @include rightAlignedBannerContent(66%, 90%);
            }
        }
    }

    &.tile--l-one-column {
        @include respond-minMax($m, $l) {
            @include leftAlignedBannerContent;
        }
    }

    // Setting component styling using grid classes = bad practice.
    // more generic modifiers introduced above ^^
    @include respond-minMax($m, $l) {
        .grid__unit.m-12-12 & {
            @include leftAlignedBannerContent;
        }

        .grid__unit.m-12-12 &.tile--bannerContentRight {
            @include rightAlignedBannerContent;
        }

        .grid__unit.l-12-12 &.tile--bannerContentLeft.tile--fullText {
            @include leftAlignedBannerContent(100%, 100%, 0.2);
        }

        .grid__unit.l-12-12 &.tile--bannerContentRight.tile--fullText {
            @include rightAlignedBannerContent(100%, 100%, 0.2);
        }
    }

    @include respond-min($l) {
        .grid__unit.l-8-12 & {
            @include leftAlignedBannerContent;
        }

        .grid__unit.l-12-12 & {
            @include leftAlignedBannerContent(66%, 90%);
        }

        .grid__unit.l-8-12 &.tile--bannerContentRight {
            @include rightAlignedBannerContent;
        }

        .grid__unit.l-8-12 &.tile--fullText {
            @include leftAlignedBannerContent(100%, 100%, 0.2);
        }
    }

    //position the text on the bottom side of the banner
    @include respond-min($l) {
        &.tile--bannerContentBottom {
            .tile__backgroundImage::after {
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.4) 100%);
            }

            //when text is not white, remove background-image
            @include no-background;

            .is-ready & { // Class set by JS on the containing .grid__unit

                .tile__content {
                    position: absolute;
                    height: calc(50% - #{$banner-default-top-padding + $banner-default-bottom-padding + toRem($global--grid-halfgutter)});
                    display: block;
                    width: calc(100% - #{$tile-space * 2});
                    bottom: $banner-default-bottom-padding;
                }
            }

            &.tile--has-footer {
                .is-ready & {
                    .tile__content {
                        height: calc(50% - #{$banner-footer-height + $banner-default-top-padding + $banner-default-bottom-padding + toRem($global--grid-halfgutter)});
                        bottom: $banner-footer-height + $banner-default-bottom-padding;
                    }
                }

                &.tile--has-bottom-controls {
                    .is-ready & {
                        .tile__content {
                            height: calc(50% - #{$banner-bottom-controls-padding + $banner-default-top-padding + toRem($global--grid-halfgutter)});
                            bottom: $banner-bottom-controls-padding;
                        }
                    }
                }
            }
        }

        &.tile--bannerContentTop {
            .tile__backgroundImage::after {
                background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.4) 100%);
            }

            //when text is not white, remove background-image
            @include no-background;
        }
    }

    &.tile--clickable {
        &.tile--fullyTransparent {
            &:hover {
                box-shadow: none;
            }
        }
    }

    &.tile--has-bottom-controls {
        padding-bottom: $banner-bottom-controls-padding;
    }
}

.tile--whiteText {
    .tile__title {
        * {
            color: $ns-white;
            text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
        }
    }

    .tile__content {
        .tile__bannerContent {
            .tile__bannerHeading,
            .tile__bannerSubheading {
                color: $ns-white;

                @include ns-text-shadow;
            }
        }
    }

    .tile__link {
        @include ns-text-shadow(0.5);
    }
}

.tile--ratio-1x1 {
    height: 0;
    padding: 100% 0 0 0;
}

.tile--ratio-2x1 {
    height: 0;
    padding: 50% 0 0 0;

    @include respond-min($l) {
        padding: 100% 0 0 0;
    }
}

.tile__contentLabel {
    margin-left: toRem(-$tile-space);
    //margin-right: toRem($tile-space);
    padding: toRem(20px);
    padding-left: toRem($tile-space);
    margin-top: toRem($tile-ImgHeight - $tile-space);
    max-width: toRem(400px);

    > p,
    h3,
    .tile__contentLabelHeading {
        color: $white;
        font-size: toRem(30px);
        line-height: toRem(35px);
        margin-bottom: toRem(5px);
        margin-top: toRem(-2px); //TODO: baseline title is equal to the tile title next to it.
    }

    @extend .tile--nsLightBlue;

    @include respond-min($m) {
        max-width: toRem($min-viewport-width);
    }
}

.tile__bannerHeading {
    @include fontsize('L');
}

.tile__bannerSubheading {
    @include fontsize('M');
}

.tile__bannerHeading--strong {
    font-weight: bold;
}

.tile__playButton {
    position: absolute;
    bottom: 0;
    left: 0;
}

.tile__videoDuration {
    position: absolute;
    right: $global--grid-halfgutter;
    bottom: $global--grid-halfgutter;
    margin-bottom: 0;
    padding: 2px 9px;
    background-color: $white;
    color: $ns-blue;
    font-weight: bold;
    font-size: toRem(14px);
    line-height: $line-height-small;
}
