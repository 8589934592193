/*
Grid en griditems

@name Grid
@description
Some (not all!) examples of grid constellations. A griditem lives in a `.grid` container. The griditem gets `.grid__unit` and,
additionally, some unit-classes per breakpoint. By default a griditem is 100% (`s-4-4`, `m-12-12` and `l-12-12`). Only exceptions to this 100%-default need to be defined.
The `.dss-outline` class is there only for example purposes. These should be removed when you use the markup.
@section Objects
@type UI
@extended false

@markup
<div class="pf-util-border grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="pf-util-border grid__unit l-8-12">
        <span>.grid__unit.l-8-12</span>
    </div>
    <div class="pf-util-border grid__unit l-4-12">
        <span>.grid__unit.l-4-12</span>
    </div>
    <div class="pf-util-border grid__unit s-2-4 m-6-12 l-8-12">
        <span>.grid__unit.s-2-4.m-6-12.l-8-12</span>
    </div>
    <div class="pf-util-border grid__unit m-6-12 l-4-12">
        <span>.grid__unit.m-6-12  l-4-12</span>
    </div>
    <div class="pf-util-border grid__unit l-6-12">
        <span>.grid__unit.l-6-12</span>
    </div>
    <div class="pf-util-border grid__unit l-6-12">
        <span>.grid__unit.l-6-12</span>
    </div>
</div>

*/

/*
Grid, showing and hiding

@name Grid Hidden
@parent Grid
@description
Per media break a `.grid_item`s can be hidden (and shown). De visibility of these items are (like all css) mobile-first.
This means that an `.s-hidden` `.grid__item` will be hidden at all breakpoints, unless a `.x-visible` class is used, ie `.m-visible`
@section Layout
@type UI
@extended false

@markup
<div class="pf-util-border grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="pf-util-border grid__unit s-4-4 m-12-12 l-8-12">
      <span>s-4-4 m-12-12 l-8-12<span>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-12-12 l-4-12 s-hidden m-visible">
        <span>s-4-4 m-12-12 l-4-12<br>s-hidden m-visible</span>
    </div>
    <div class="pf-util-border grid__unit s-2-4 m-6-12 l-8-12">
        <span>s-2-4 m-6-12 l-8-12</span>
    </div>
    <div class="pf-util-border grid__unit s-2-4 m-6-12 l-4-12">
        <span>s-2-4 m-6-12  l-4-12</span>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-12-12 l-6-12">
        <span>s-4-4 m-12-12 l-6-12</span>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-12-12 l-6-12">
        <span>s-4-4 m-12-12 l-6-12</span>
    </div>
</div>

*/

/*
Grid, heights

@name Grid Heights
@description
Each `.grid_item`'s is given a height by javascript. The height of all `grid__items`'s in a row will be equal, and as high as the `.grid__item` with the most (highest) content.
There are some variants on this: it's possible to create a twice as high `.grid__item` or a half-height `.grid__item'.
To do this we need to add a data-attribute to the `.grid__item`:
@section Layout
@type UI
@extended false

@modifier    data-responsivegrid-height-l="0.5"
@modifier    data-responsivegrid-height-m="1"
@modifier    data-responsivegrid-height-l="2"

@markup
<div class="pf-util-border grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="pf-util-border grid__unit s-4-4 m-12-12 l-4-12 ">
        <div>this<br>is<br>a<br>lot<br>of<br>dummy-content</div>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-12-12 l-4-12">
        <div>this<br>is<br>a<br>lot<br>of<br>dummy-content</div>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-12-12 l-4-12"  data-responsivegrid-height-l="0.5">
        <div>this<br>grid__item<br> is half high</div>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-12-12 l-4-12"  data-responsivegrid-height-l="0.5">
       <div>this<br>grid__item<br> is half high</div>
    </div>
</div><br>
<div class="pf-util-border grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="pf-util-border grid__unit s-4-4 m-12-12 l-4-12 ">
        <div>this<br>is<br>a<br>lot<br>of<br>dummy-content</div>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-12-12 l-4-12">
        <div>this<br>is<br>a<br>lot<br>of<br>dummy-content</div>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-12-12 l-4-12" data-responsivegrid-height-l="2" style="float:right;">
        <div>Since I'm a double-height .grid__item, AND I want to be on the right-hand side, I need a float:right; style.</div>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-12-12 l-4-12 ">
        <div>this<br>is<br>a<br>lot<br>of<br>dummy-content</div>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-12-12 l-4-12">
        <div>this<br>is<br>a<br>lot<br>of<br>dummy-content</div>
    </div>
</div><br>
<div class="pf-util-border grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="pf-util-border grid__unit s-4-4 m-4-12 l-4-12" data-responsivegrid-height-l="2" data-responsivegrid-height-m="1">
        <div>I'm a double height .grid__item, AND I want to be on the left, so I should be before all items that align on my side.<br>
        Howerver, on breakpoint medium, I'll be only one-high.</div>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-4-12 l-4-12 ">
        <div>this<br>is<br>a<br>lot<br>of<br>dummy-content</div>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-4-12 l-4-12">
        <div>this<br>is<br>a<br>lot<br>of<br>dummy-content</div>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-6-12 l-4-12 ">
        <div>this<br>is<br>a<br>lot<br>of<br>dummy-content</div>
    </div>
    <div class="pf-util-border grid__unit s-4-4 m-6-12 l-4-12">
        <div>this<br>is<br>a<br>lot<br>of<br>dummy-content</div>
    </div>
</div>

*/

@use 'sass:math';

%grid__heading {
    @include fontsize('XL');

    margin-bottom: 0;
}

// --------------------------------------------------
//  Grid container
// --------------------------------------------------

.grid {
    @extend %clearfix;

    .grid {
        margin: toRem(-1 * $global--grid-quartergutter);

        @include respond-min($m) {
            margin: toRem(-1 * $global--grid-halfgutter);
        }
    }
}

.grid__unit {
    padding: toRem($global--grid-quartergutter);
    width: 100%;
    float: left;
    position: relative;

    @include respond-min($m) {
        padding: toRem($global--grid-halfgutter);
    }
}

// --------------------------------------------------
//  Grid using Flexbox
// --------------------------------------------------

.grid--flex {
    display: flex;
    flex-wrap: wrap;
}

.grid--flex > .grid__unit {
    flex-direction: column;
    float: none;
}

.grid .grid--flex {
    margin-bottom: 0; /* prevent margin collapsing */
}

.grid--flex > .grid__unit:first-child:last-child {
    align-self: flex-start; /* prevent stretching to its children in single grid unit layouts */
}

// --------------------------------------------------
//  Grid Unit Sizing
// --------------------------------------------------

@mixin grid_columns($grid-sizes, $size_character) {
    @each $grid-size in $grid-sizes {
        @for $i from 1 through $grid-size {
            .#{$size_character}-#{$i}-#{$grid-size} {
                width: percentage(math.div($i, $grid-size));
                float: left;
            }

            .#{$size_character}-#{$i}-#{$grid-size}-right {
                width: percentage(math.div($i, $grid-size));
                float: right;
            }

            .#{$size_character}-push-left-#{$i}-#{$grid-size} {
                margin-left: percentage(math.div($i, $grid-size));
            }

            .#{$size_character}-push-right-#{$i}-#{$grid-size} {
                margin-right: percentage(math.div($i, $grid-size));
            }
        }
    }

    .#{$size_character}-hidden {
        display: none;
    }

    .#{$size_character}-visible {
        display: block;
    }

    .#{$size_character}-visible--inline-block {
        display: inline-block;
    }
}

// grid-sizes-map specifies the available grid-sizes per breakpoint
$grid-sizes-s: (4);
$grid-sizes-m: (8, 12);
$grid-sizes-ml: (8, 12);
$grid-sizes-l: (10, 12);
$grid-sizes-xl: (7, 12);

@include grid_columns($grid-sizes-s, 's');

// Tablet grid sizes.
@include respond-min($m) {
    @include grid_columns($grid-sizes-m, 'm');
}
// Betweem tablet and desktop grid sizes.
@include respond-min($ml) {
    @include grid_columns($grid-sizes-ml, 'ml');
}

// Desktop grid sizes.
@include respond-min($l) {
    @include grid_columns($grid-sizes-l, 'l');
}

// XL grid sizes
@include respond-min($xl) {
    @include grid_columns($grid-sizes-xl, 'xl');

    .xl-1-2 {
        width: 50%;
    }

    .xl-4-10 {
        width: 40%;
    }

    .xl-6-10 {
        width: 60%;
    }
}

// Grid divider

.grid__unit--divider {
    margin-top: toRem(30px);
    margin-bottom: toRem(-5px);

    &:first-child {
        margin-top: 0;
    }
}

.grid__heading {
    @extend %grid__heading;
}

// Adds extra padding to the bottom
.grid--bottomPush {
    padding-bottom: toRem($global--grid-quartergutter + 30px);

    @include respond-min($m) {
        padding-bottom: toRem($global--grid-halfgutter + 30px);
    }
}

//Grid units with no padding
.grid--touch {
    .container & {
        margin-left: 0;
        margin-right: 0;

        @include respond-min($m) {
            margin-left: toRem(-$global--grid-halfgutter);
            margin-right: toRem(-$global--grid-halfgutter);
        }
    }

    > .grid__unit {
        padding: 0;

        @include respond-min($m) {
            padding: 0 0 toRem(35px) 0;
        }
    }
}

//Created for videoplayer in detail page
.grid__unit--noTopBottomPush {
    padding-top: 0;
    padding-bottom: toRem(55px);

    @include respond-min($m) {
        padding-bottom: toRem(75px);
    }
}

// Grid units with no top & bottom padding (on small screen)
.grid__unit--s-noTopBottomPush {
    padding-top: 0;
    padding-bottom: 0;

    @include respond-min($m) {
        padding-top: toRem($global--grid-halfgutter);
        padding-bottom: toRem($global--grid-halfgutter);
    }
}

/*
Grid header

A header within a grid, can be used as a starting item or somewhere in the middle. The link (often to an overview page) is optional.

Markup:
<div class="grid">
    <div class="grid__unit grid__unit--divider s-4-4 m-12-12 gridHeader">
        <h2 class="headingXL gridHeader__heading">Dagje uit</h2>
        <a href="" class="arrowLink gridHeader__link">alles over Dagje Uit</a>
    </div>
</div>

Styleguide 4.4
*/

.gridHeader__heading {
    margin-bottom: toRem(5px);

    @include respond-min($m) {
        margin-right: toRem(20px);
        display: inline-block;
        margin-bottom: toRem($line-height-step * 2);
    }
}

.gridHeader__link {
    margin-bottom: toRem($line-height-step * 2);
}
