/**
 * Size helpers
 */

// We don't need all breakpoints
$size-breakpoints: (
    'sm': $sm,
    'm': $m,
    'ml': $ml,
    'l': $l,
    'xl': $xl,
    'xxl': $xxl,
    'xxxl': $xxxl
);

$header-height: $global--header-height;
$header-height-ml: 115px;
$header-height-xxl: 121px;

@mixin size-line($breakpointName) {
    .h-height-fullscreen#{$breakpointName} {
        height: calc(#{$global--full-viewport-height-fallback} - #{toRem($header-height)}) !important;
        height: calc(#{$global--full-viewport-height} - #{toRem($header-height)}) !important;

        @include respond-min($ml) {
            height: calc(#{$global--full-viewport-height-fallback} - #{toRem($header-height-ml)}) !important;
            height: calc(#{$global--full-viewport-height} - #{toRem($header-height-ml)}) !important;
        }

        @include respond-min($xxl) {
            height: calc(#{$global--full-viewport-height-fallback} - #{toRem($header-height-xxl)}) !important;
            height: calc(#{$global--full-viewport-height} - #{toRem($header-height-xxl)}) !important;
        }

        .hide-header & {
            height: #{$global--full-viewport-height-fallback} !important;
            height: #{$global--full-viewport-height} !important;
        }
    }

    .h-max-height-fullscreen#{$breakpointName} {
        max-height: calc(#{$global--full-viewport-height-fallback} - #{toRem($header-height)}) !important;
        max-height: calc(#{$global--full-viewport-height} - #{toRem($header-height)}) !important;

        @include respond-min($ml) {
            max-height: calc(#{$global--full-viewport-height-fallback} - #{toRem($header-height-ml)}) !important;
            max-height: calc(#{$global--full-viewport-height} - #{toRem($header-height-ml)}) !important;
        }

        @include respond-min($xxl) {
            max-height: calc(#{$global--full-viewport-height-fallback} - #{toRem($header-height-xxl)}) !important;
            max-height: calc(#{$global--full-viewport-height} - #{toRem($header-height-xxl)}) !important;
        }

        .hide-header & {
            max-height: #{$global--full-viewport-height-fallback} !important;
            max-height: #{$global--full-viewport-height} !important;
        }
    }
}

@mixin sizes() {
    //Defaults
    @include size-line('');
    //Per breakpoint
    @each $breakpoint-name, $breakpoint in $size-breakpoints {
        @include respond-min(#{$breakpoint}) {
            @include size-line(-#{$breakpoint-name});
        }
    }
}

@include sizes();
