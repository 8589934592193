/*
@name footnote
@description Displays an ordered list
@section Objects
@type UI
@extended false

@markup
<ol class="footnote">
    <li class="footnote__item">
        <p>De OV-chipkaart bevat een zogenaamde contactloze Radio Frequency IDentifier (RFID)-chip. Deze chip bevat een elektronische portemonnee met saldo en een ‘OV-module’. Voor meer informatie zie <a href="#">OV-chipkaart.nl</a>.</p>
    </li>
    <li class="footnote__item">
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
    </li>
</ol>

*/

@keyframes highLight {
    0% { background-color: $white; }
    40% { background-color: $ns-grey-90; }
    100% { background-color: $white; }
}

.footnote__item {
    @include fontsize('body-small');

    &.richText {
        p {
            font-size: inherit;
            margin-bottom: 1.5625em; // 1.5625rem gerelateerd aan aangepaste font-size (14px)

            &:last-of-type {
                display: inline;
            }
        }
    }

    &::before {
        float: left;
        font-size: inherit;
    }

    &:target {
        animation-name: highLight;
        animation-delay: 0.25s;
        animation-duration: 3s;
    }
}

.footnote__item-refLink {
    margin-left: toRem(5px);

    &,
    &:hover {
        box-shadow: none;
    }
}
