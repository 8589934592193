// bodyItemVideo

@use 'sass:math';

.bodyItemVideo {
    height: 100%;
    position: relative;

    @include respond-min($m) {
        width: 100%;
    }

    @include respond-min($l) {
        margin-left: 0;
        margin-right: 0;
    }
}

.bodyItemVideo__img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    width: 100%;
}

.bodyItemVideo__holder {
    // reset for global width/height: 100% rule introduced to fix picture for use with img--contain.
    width: auto;
    height: auto;
    display: inline-block;
    position: relative;

    .bodyItem__image--video & {
        display: block;
    }
}

.bodyItemVideo__caption {
    margin-top: $global--grid-halfgutter;
    color: $ns-grey-40;
    font-size: toRem(14px);
    line-height: math.div(strip-units($font-size-base), 14);
    width: 100%;
}

.bodyItemVideo__contentPositioner {
    position: relative;
}

.bodyItemVideo__playButton {
    position: absolute;
    left: 0;
    bottom: 0;
    top: auto;
    transform: none;
}

.bodyItemVideo__videoDuration {
    position: absolute;
    right: $global--grid-halfgutter;
    bottom: $global--grid-halfgutter;
    margin-bottom: 0;
    padding: 2px 9px;
    background-color: $white;
    color: $ns-blue;
    font-weight: bold;
    font-size: toRem(14px);
    line-height: $line-height-small;
}
