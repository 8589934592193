/**
 * The holder is the only "scrollable" top-level container element.
 */
.picker__holder {
    // The base stylings.
    position: absolute;
    width: toRem($picker-min-width);
    background-color: $ns-white;
    outline: none;
    overflow: hidden;

    .picker--opened & {
        overflow: hidden;
        // Reveal the content.
        max-height: toRem($classic-max-height);
        opacity: 1;

        // Straighten the picker.
        transform: translateY(0) perspective(600px) rotateX(0);

        // Everything should be smoothly animated – except the height & border.
        transition: opacity $speed-animate-in ease-out;
        // Add a light shadow.
        box-shadow: $classic-box-shadow;
        border-radius: $input-border-radius;
    }

    margin-top: toRem(-1px);

    // Hide everything to begin with.
    max-height: 0;
    opacity: 0;

    @include respond-min($m) {
        border: toRem(1px) solid $ns-grey-70;
    }

    // Everything should be smoothly animated – the height & border should wait till the rest is done.
    transition: transform $speed-animate-in ease-out opacity $speed-animate-in ease-out, max-height 0s $speed-animate-in;

    .is-fixed & {
        position: fixed;
        left: 50%;
        top: 50%;
        margin-left: toRem(-($picker-min-width * 0.5));
        margin-top: toRem(-($classic-max-height * 0.5));
    }
}
