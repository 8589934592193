/*
@name Calamity
@description Calamity header
@section Components
@type UI
@extended false
@background transparent

@markup
<div class="calamity">
    <div class="container">
        <div class="grid calamity__container">
            <div class="grid__unit s-4-4 l-7-12">
                <div class="icon icon--alert icon--nsRed calamity__icon">
                    <span class="alt">Waarschuwing:</span>
                </div>
                <div class="calamity__body">
                    <div class="calamity__header">
                        <h3 class="calamity__heading">Zware storing rond Utrecht Centraal vanwege een brand in de stationshal</h3>
                        <p class="inlineNotification inlineNotification--alert calamity__lastUpdate">
                            <span class="icon icon--nsRed icon--clock icon--inline inlineNotification__icon"></span>
                            <span class="inlineNotification__text">
                                Laatste update: 23 mei 2016, 20:53 uur
                            </span>
                        </p>
                    </div>
                    <div class="calamity__content">
                        <p>
                            Gummi bears dessert pie applicake liquorice. Fruitcake marzipan tart. Lemon drops tootsie roll muffin
                            sweet sesame snaps tiramisu cupcake. Chocolate bar chocolate cake pastry pudding dragée dessert. Lemon drops
                            tootsie roll. Gummi bears dessert pie applicake liquorice. Fruitcake marzipan tart.
                        </p>
                    </div>
                </div>
                <div class="calamity__link">
                    <a href="javascript:void(0)" class="arrowLink" title="Zware storing rond Utrecht Centraal vanwege een brand in de stationshal">Bekijk de details</a>
                </div>
            </div>
        </div>
    </div>
</div>
*/

$calamity-bodyPadding--top: 62px;
$calamity-bodyPadding--top--desktop: 92px;
$calamity-itemSpacing: 10px;

.calamity {
    background: $ns-white;
    padding-top: toRem($calamity-bodyPadding--top - $global--grid-quartergutter);

    @include respond-min($m) {
        padding-top: toRem($calamity-bodyPadding--top--desktop - $global--grid-halfgutter);
    }

    @include respond-min($l) {
        padding-top: toRem($calamity-bodyPadding--top - $global--grid-halfgutter);
    }
}

.calamity__body {
    margin-bottom: toRem($tile-space * 0.5 - $calamity-itemSpacing);
    overflow: hidden;
    padding-left: toRem($tile-space);
    padding-right: toRem($tile-space * 0.5);
    position: relative;

    @include respond-min($m) {
        margin-bottom: toRem($tile-space - $calamity-itemSpacing);
        padding-right: toRem($tile-space * 0.5);
    }

    &::before {
        border-left: toRem(5px) solid $ns-red-alert;
        bottom: toRem($calamity-itemSpacing);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: toRem(5px);
    }
}

.calamity__header {
    position: relative;
    margin-top: toRem(-5px);
    margin-bottom: toRem(-2px);

    @include respond-min($m) {
        margin-top: toRem(-7px);
        margin-bottom: 0;
    }
}

.calamity__heading {
    color: $ns-red-alert;

    @include fontsize('L');
}

.calamity__lastUpdate {
    margin-bottom: toRem($calamity-itemSpacing);
}

.calamity__content {
    margin-bottom: toRem(-5px);

    *:last-child {
        margin-bottom: toRem($calamity-itemSpacing);
    }

    @extend %visuallyhidden;

    @include respond-min($m) {
        @include show;
    }
}

// calamity__icon
.calamity__iconHolder {
    margin-top: toRem(-35px);
    padding-right: toRem($tile-space);
    position: relative;
    text-align: right;
}

.calamity__icon {
    position: absolute;
    top: toRem(-33px);
    left: toRem(($tile-space + $global--grid-quartergutter) - $icon-padding);

    @include respond-min($m) {
        top: toRem(-44px);
        left: toRem(34px);
        width: toRem(58px);
        height: toRem(58px);
    }
}

.calamity__link {
    margin-left: toRem($tile-space);
    margin-bottom: toRem($tile-space);
}
