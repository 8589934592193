/*
@name Collapse
@description
This is a "details" component in NS Style. This is the browser standard and thus more accessible version of <a href="/platform/components/collapsible-deprecated.html">Collapsible</a> component.
You can use `data-controller="generic/ui/ConditionalCollapse"` with `data-config="{"disabledFromBreakpoints": ["m"],"enabledFromBreakpoints": ["xxl"]}"`
to enable or disable the collapse for a specific breakpoint(s).

@section Components
@type UI
@extended false

@modifier .collapse--lighterColor     - Collapse with a lighter color scheme
@modifier .collapse--right            - Collapse with the controls ons the right

@markup
<details class="collapse {{modifier_class}}" data-eventdata="{}">
    <summary class="collapse__header">Header</summary>
    <div class="collapse__body">
        Body
    </div>
</details>
*/

$collapse-color: $ns-blue;
$collapse-hover-color: $ns-light-blue;

$collapse-color-lighter: $ns-light-blue;
$collapse-hover-color-lighter: $ns-blue;

// mixin to be able to rewrite the color without duplicate code
@mixin collapse-icon($color: $collapse-color, $hover-color: $collapse-hover-color) {
    &:not([open]) {
        .collapse__header {
            @include iconLink__iconAndColor('plus', $color, $hover-color);
        }
    }

    &[open] {
        .collapse__header {
            @include iconLink__iconAndColor('minus', $color, $hover-color);
        }
    }
}

.collapse {
    @include collapse-icon;

    &.collapse--lighterColor {
        @include collapse-icon($collapse-color-lighter, $collapse-hover-color-lighter);
    }

    &.collapse--right {
        .collapse__header {
            @extend %iconizedTextHolder--right;

            padding-left: 0;

            &::before {
                left: auto;
                right: 0;
                top: calc(50% - #{toRem($icon-size-base * 0.5)});
            }
        }
    }
}

.collapse__header {
    @extend %iconizedTextHolder;
    @extend %iconLink--collapsible;

    cursor: pointer;
    padding-top: toRem($icon-size-base * 0.5);
    padding-bottom: toRem($icon-size-base * 0.5);
    width: 100%;

    &::before {
        top: toRem(11.5px);
    }

    &:focus {
        @extend %outline;
    }

    &::-webkit-details-marker {
        display: none;
    }
}

.collapse__header--slim {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 10px;

    &::before {
        top: toRem(-5px);
    }
}

.collapse__body--padded {
    padding-bottom: toRem($icon-size-base * 0.5);
}

.no-details .collapse,
.collapse.collapse--disabled {
    .collapse__header {
        padding-left: 0;
        cursor: auto;

        &:hover {
            color: $collapse-color;
        }

        &::before {
            display: none;
        }
    }

    &.collapse--lighterColor {
        .collapse__header {
            &:hover {
                color: $collapse-color-lighter;
            }
        }
    }

    &.collapse--right {
        .collapse__header {
            padding-right: 0;
        }
    }
}
