/*
@name List
@description Displays an ordered list
@section Objects
@type UI
@extended false

@markup
<ul>
    <li>Plan uw reis en klik op het reisadvies dat u favoriet wilt maken.</li>
    <li>Kies&nbsp;bij iPhone&nbsp;vervolgens voor opties (rechts boven in scherm) en kies voor ‘opslaan’ en&nbsp;bij Android voor het sterretje (rechts boven in scherm).</li>
    <li>Uw reis wordt nu opgeslagen bij ‘Mijn reizen’. Meldingen op opgeslagen reizen kunt u eenvoudig aan- en uitzetten of aanpassen.</li>
</ul>

*/

/*

@name Link list
@parent List
@description Displays the many types of lists and how to use them.
@type UI
@extended false

@markup
<ul class="list">
    <li class="list__item">
        <a class="arrowLink {{modifier_class}}" href="javascript:void(0)">
            Klantenservice Landing
        </a>
    </li>
    <li class="list__item">
        <a class="arrowLink {{modifier_class}}" href="javascript:void(0)">
            Klantenservice Thema
        </a>
    </li>

    <li class="list__item">
        <a class="arrowLink {{modifier_class}}" href="javascript:void(0)">
            Klantenservice Thema<br>Grote Banner Rechts
        </a>
    </li>

    <li class="list__item">
        <a class="arrowLink {{modifier_class}}" href="javascript:void(0)">
            Klantenservice Detail
        </a>
    </li>
</ul>

*/

/*
@name Ordered list
@parent List
@description Displays an ordered list
@type UI
@extended false

@markup
<ol>
    <li>Plan uw reis en klik op het reisadvies dat u favoriet wilt maken.</li>
    <li>Kies&nbsp;bij iPhone&nbsp;vervolgens voor opties (rechts boven in scherm) en kies voor ‘opslaan’ en&nbsp;bij Android voor het sterretje (rechts boven in scherm).</li>
    <li>Uw reis wordt nu opgeslagen bij ‘Mijn reizen’. Meldingen op opgeslagen reizen kunt u eenvoudig aan- en uitzetten of aanpassen.</li>
</ol>

*/

/*

@name Horizontal list
@parent List
@description Horizontal version of a link list
@type UI
@extended false

@markup
<ul class="list list--horizontal">
    <li class="list__item list__horizontalItem">
        <a class="" href="javascript:void(0)">
            Klantenservice Landing
        </a>
    </li>
    <li class="list__item list__horizontalItem">
        <a class="" href="javascript:void(0)">
            Klantenservice Thema
        </a>
    </li>

    <li class="list__item list__horizontalItem">
        <a class="" href="javascript:void(0)">
            Klantenservice Thema met Banner
        </a>
    </li>

    <li class="list__item list__horizontalItem">
        <a class="" href="javascript:void(0)">
            Klantenservice Detail
        </a>
    </li>
</ul>
*/

/*

@name List USP Alternative
@parent List
@description The USP have an underline. The empty list items also still have an underline.
@type UI
@extended false
@background white

@markup
<ul class="list list--usp-alt">
    <li class="list__item">
        40% korting in het weekend
    </li>
    <li class="list__item">
        40% korting in de daluren
    </li>

    <li class="list__item">
    </li>

    <li class="list__item">
    </li>
</ul>
*/

$base-bullet-svg: 'data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%228%22%20height%3D%228%22%20xmlns%3Ardf%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2F02%2F22-rdf-syntax-ns%23%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20enable-background%3D%22new%200%200%208%208%22%20xml%3Aspace%3D%22preserve%22%20viewBox%3D%220%200%208%208%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20xmlns%3Acc%3D%22http%3A%2F%2Fcreativecommons.org%2Fns%23%22%20xmlns%3Adc%3D%22http%3A%2F%2Fpurl.org%2Fdc%2Felements%2F1.1%2F%22%3E%3Cg%3E%3Crect%20fill-rule%3D%22nonzero%22%20height%3D%228%22%20width%3D%228%22%20y%3D%220%22%20x%3D%220%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E';
$checkmark-bullet-svg: 'data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2235px%22%20height%3D%2235px%22%20viewBox%3D%220%200%2035%2035%22%20enable-background%3D%22new%200%200%2035%2035%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20d%3D%22M26.9%2C9.6c-0.2-0.2-0.5-0.2-0.7%2C0L14.4%2C21.4l-2.5-4.9c-0.2-0.5-0.8-0.7-1.3-0.4c-0.5%2C0.2-0.7%2C0.8-0.4%2C1.3l3.3%2C6.4v0v0%20l13.5-13.5C27%2C10.2%2C27%2C9.8%2C26.9%2C9.6z%22%2F%3E%3C%2Fsvg%3E';

ul,
ol {
    padding-left: 0;
    margin-top: toRem(16px); // when it's the first thing in a flow
    margin-bottom: toRem(32px); // li's have 1em margin-bottom, that 'collapses' into this one.
    * + & {
        margin-top: 0; // when it's in a flow of elements
    }
}

ul {
    list-style-image: url(colorizeEncodedSVG($base-bullet-svg, $ns-light-blue));

    &.greyBullets {
        list-style-image: url(colorizeEncodedSVG($base-bullet-svg, $ns-grey-70));
    }

    &.yellowBullets {
        list-style-image: url(colorizeEncodedSVG($base-bullet-svg, $ns-yellow));
    }

    &.redBullets {
        list-style-image: url(colorizeEncodedSVG($base-bullet-svg, $ns-red-alert));
    }
}

$list-bullit-space: 25px; // desired
$list-bullit-icon-width: 18px;
$list-bullit-text-space: $list-bullit-space - $list-bullit-icon-width;
$list-bottom-margin-height: $font-size-base * 0.5;

%listItem {
    margin-bottom: toRem($list-bottom-margin-height);
    padding-left: toRem($list-bullit-text-space);
    margin-left: toRem($list-bullit-icon-width);
}

li {
    @extend %listItem;
}

// Placeholder class to reset default element styling
%listReset {
    margin-bottom: 0;
    padding-left: 0;
    margin-left: 0;
}

ol {
    list-style: none;
    counter-reset: counter-list;

    > li {
        position: relative;

        &::before {
            position: relative;
            display: inline-block;
            margin-left: toRem(-$list-bullit-space);
            min-width: toRem($list-bullit-space);
            text-align: left;
            content: counter(counter-list) '.';
            counter-increment: counter-list;
            color: $ns-light-blue;
            font-size: toRem($font-size-base);
        }
    }
}

.list {
    @extend %list;
}

.list__item {
    @extend %list__item;
    margin-bottom: toRem($list-bottom-margin-height);
}

.list--horizontal {
    @include respond-min($m) {
        @include clearfix;

        > li {
            float: left;
            margin-bottom: 0;
            margin-right: toRem($global--grid-gutter);
        }
    }
}

.list--hasIcon .list__item {
    @include respond-min($m) {
        min-height: toRem($icon-size-base);
        line-height: toRem($icon-size-base);
        vertical-align: middle;
        margin-bottom: 0;
    }

    .arrowLink::before,
    .arrowLink--right::after,
    .arrowLink--rightBlue::after {
        top: toRem(-1.6px);
    }

    .icon {
        margin-left: toRem(-$icon-padding);
        vertical-align: middle;
        margin-top: toRem(-3px);
    }
}

.list--inline {
    .list__item {
        display: inline-block;
    }
}

.list--distributed {
    display: flex;
    justify-content: space-between;

    .list__item {
        flex: 1 1 0; /* stylelint-disable-line length-zero-no-unit  */ // IE 11 compat
    }
}

.list--reset {
    .list__item {
        @extend %listReset;
    }
}

.list__item--close {
    margin-bottom: 0;
}

.listItem__fixedLabel {
    display: inline-block;
    min-width: toRem(62px);
}

@include respond-min($m) {
    .list--2Cols,
    .list--3Cols {
        columns: 2;
        column-gap: toRem(20px);
        column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        margin-bottom: toRem(-$list-bottom-margin-height);

        .list__item {
            margin-bottom: 0;
            padding-bottom: toRem($list-bottom-margin-height);
            column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;

            .fileLink::before {
                overflow: hidden;
            }
        }
    }
}

@include respond-min($l) {
    .list--3Cols {
        columns: 3;
    }
}

.list--divided {
    margin-top: 0;

    @include respond-min($m) {
        margin-top: 0; //resets tile__content margin-top
    }

    .list__item {
        border-bottom: toRem(1px) solid $ns-grey-90;
        margin-bottom: 0;

        &:last-child {
            border-bottom: none;

            @include respond-min($l) {
                border-bottom: toRem(1px) solid $ns-grey-90;
            }
        }
    }
}

$greenCheckmark: url(colorizeEncodedSVG($checkmark-bullet-svg, $ns-green));

//A USP list with green checkmarks
.list--usp {
    list-style: none;

    li {
        margin-bottom: toRem($font-size-base);
        position: relative;

        &::before {
            content: url(colorizeEncodedSVG($checkmark-bullet-svg, $ns-green));
            position: absolute;
            left: toRem(-28px);
            top: toRem(-4px);
        }
    }
}

$usp-line-height: 30px;

.list--usp-alt {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent toRem(29px), $ns-grey-90 toRem(1px));
    background-size: 100% toRem($usp-line-height);
    min-height: toRem(4 * $usp-line-height);

    .list__item {
        color: $ns-grey-20;

        @include fontsize('body-small');

        margin-bottom: 0;
        line-height: toRem($usp-line-height);
        min-height: toRem($usp-line-height);
    }
}

.list--small {
    .list__item {
        color: $ns-grey-20;

        @include fontsize('body-small');

        margin-bottom: toRem(5px);
    }
}

/**
 * A list with specification on the right
 */

.list--specs .list__item {
    display: table;
    width: 100%;
}

.list__spec {
    display: table-cell;
    color: $ns-grey-40;
    text-align: right;
    vertical-align: top;
}

/**
 * Description list with a vertical blue heading
 * On screensize medium and up it's a 2 column list
 * On screensize small list items are stacked
 */

.descriptionList {
    .descriptionList__key,
    .descriptionList__value {
        vertical-align: top;
        padding: 0;
        margin: 0;
    }

    .descriptionList__key {
        color: $ns-blue;
    }

    .descriptionList__value {
        margin-bottom: toRem($font-size-base);
        // disables margin bottom if there is only one paragraph
        p:last-child {
            margin-bottom: 0;
        }
    }

    @include respond-min($m) {
        .descriptionList__key,
        .descriptionList__value {
            float: left;
            margin-bottom: toRem($font-size-base);
        }

        .descriptionList__value {
            width: 50%;
        }

        .descriptionList__key {
            clear: both;
            margin-right: toRem($font-size-base);
            width: calc(50% - #{toRem(16px)});
        }
    }
}

.timetableList {
    overflow: auto;

    .timetableList__key,
    .timetableList__value {
        float: left;
        margin: 0;
        white-space: nowrap;
    }

    .timetableList__key {
        clear: left;
        margin-right: toRem(6px);
    }
}
