/*
@name Minicart
@description This icon is used in the header. When the minicart is empty, it is invisible.
It's only visible when it's filled.
@section Components
@extended false
@className miniCart

@markup
<li class="ns-menu__list-item ns-menu__list-item--miniCart miniCart" data-controller="generic/ui/MiniCart"
    data-config="{&quot;labels&quot;:{&quot;articleSingular&quot;:&quot;artikel&quot;,&quot;articlePlural&quot;:&quot;artikelen&quot;,&quot;shoppingCartText&quot;:&quot;Winkelwagen&quot;},&quot;cartUrl&quot;:&quot;/producten/winkelwagen&quot;}">
</li>
*/

.miniCart {
    // Hide cart on default unless it's filled.
    // Use this non-standard method in order not to interfere
    // with _header.scss's way of showing/hiding headerItems
    // on small screens.
    visibility: hidden;
    position: absolute;
}

.miniCart--filled {
    visibility: visible;
    position: static;
}

.miniCart__icon {
    font-size: toRem(14px);
    line-height: toRem(23px);
}

.miniCart__articleText {
    color: $ns-blue;
}

.miniCart__amount {
    padding-left: toRem(5px);
}
