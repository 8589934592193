/*
@name Hero
@description Huge images on top of pages. `.hero--major` The default hero as a height of 230px at small and 370px at medium+; its width is 100% of the viewport. Other than that, a Hero has a default negative bottom margin, which makes the following item overlap the Hero.
@section Components
@extended true

@modifier .hero--smallOverlap - A hero banner with a promotion sticker on top of it. The promotion sticker is placed within a grid. If you want to have the lower yellow contentblock a bit over the image
you have to add the `.hero--smallOverlap` class on the same level as the `.hero` class

@markup
<div class="container container--extended">
    <div class="hero hero--major {{modifier_class}}">
        <picture>
            <source srcset="<%= path.styleguideStatic %>/images/klantenservice/hero_1400x370.jpg" media="(min-width: 912px)">
            <source srcset="<%= path.styleguideStatic %>/images/klantenservice/hero_912x370.jpg" media="(min-width: 640px)">
            <source srcset="<%= path.styleguideStatic %>/images/klantenservice/hero_640x230.jpg" media="(min-width: 0px)">
            <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Description of image" class="img--contain">
        </picture>
    </div>
</div>
<div class="container">
    <div class="grid" data-controller="generic/ui/ResponsiveGrid">
        <div class="grid__unit s-4-4">

                <div class="tile tile--nsYellow tile--gradient tile--smallGradient tile--slim tile--noBottom">
                <div class="tile__header">
                    <header class="headingGroup">
                        <h1 class="headingXL tile__header--mobileVisuallyHidden">Met de NS kom je verder</h1>
                    </header>
                </div>
                <div class="tile__content">
                    <div style="border: 1px solid rgba(0,0,0,0.5); height: 40px; line-height: 40px; text-align: center; font-weight: bold;">
                        &lt; &lt; &lt; <span class="m-visible-inline">PLACEHOLDER VOOR DE </span>REISPLANNER <span class="m-visible-inline">ANGULAR </span>APP &gt; &gt; &gt;
                        <br>
                    </div>
                </div>
                <div class="tile__footer">
                    <div class="separator--noTop separator--alphaGray tile__footerContent">
                        <ul class="list list--horizontal list--hasIcon">
                            <li class="list__item">
                                <a class="arrowLink--rightBlue" href="javascript:void(0)">
                                    <span class="icon icon--alert icon--nsRed"></span>
                                    <span class="s-visible--inline-block m-hidden">Verstoringen (4)</span>
                                    <span class="s-hidden m-visible--inline-block">Storingen en werkzaamheden (4)</span>
                                </a>
                            </li>
                            <li class="list__item s-hidden m-visible"><a class="arrowLink--rightBlue" href="javascript:void(0)">Meer reisinformatie</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

*/

$hero-height--mobile--default: 230px;
$hero-height--tablet--default: 370px;

$hero-height--mobile--major: 300px;
$hero-height--tablet--major: 480px;

$hero-height--mobile--homepage: 380px;
$hero-height--tablet--homepage: $hero-height--mobile--homepage;

.hero {
    display: grid;
    min-height: toRem($hero-height--mobile--default); // TODO: remove rems?
    margin-bottom: toRem(-$hero-overlap--mobile--default);
    overflow: hidden;
    position: relative;

    @include respond-min($m) {
        display: block;
        height: toRem($hero-height--tablet--default);
        margin-bottom: toRem(-$hero-overlap--tablet--default);
    }
}

.hero--smallOverlap {
    margin-bottom: toRem(-$hero-overlap--mobile--homepage);

    @include respond-min($m) {
        margin-bottom: toRem(-$hero-overlap--tablet--homepage);
    }
}

.hero--noOverlap {
    margin-bottom: toRem($global--grid-quartergutter);

    @include respond-min($m) {
        margin-bottom: toRem($global--grid-halfgutter);
    }
}

.hero--major {
    min-height: toRem($hero-height--mobile--major); // TODO: remove rems?

    @include respond-min($m) {
        height: toRem($hero-height--tablet--major);
    }
}

.hero--homepage {
    min-height: toRem($hero-height--mobile--homepage);

    @include respond-min($m) {
        height: toRem($hero-height--tablet--homepage);
    }
}

.hero__loaderContainer {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;
}

.hero__loader {
    $heroLoader-background-size : 40px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-bottom: toRem($hero-overlap--mobile--default);
    background-color: $body-background-color;
    text-align: center;

    &::before {
        display: block;
        content: '';

        @include createIconBg('loader', $ns-grey-70);

        background-size: toRem($heroLoader-background-size);
        background-repeat: no-repeat;
        background-position: center;
        width: toRem($heroLoader-background-size);
        height: toRem($heroLoader-background-size);
        animation: rotating 800ms linear infinite;
    }

    @include respond-min($m) {
        padding-bottom: toRem($hero-overlap--tablet--default);
    }

    .hero--smallOverlap & {
        padding-bottom: toRem($hero-overlap--mobile--homepage);

        @include respond-min($m) {
            padding-bottom: toRem($hero-overlap--tablet--homepage);
        }
    }

    .hero--noOverlap & {
        padding-bottom: 0;
    }
}
