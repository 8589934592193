/*
@name Form
@description Styling for form instances
@section Objects
@type UI
@extended false

@modifier form--configurator

@markup
<form class="form"></form>

*/

.form {
    // No default styling
}

.form__group {
    // No default styling
}

.form--configurator {
    .form__group {
        padding-bottom: toRem(4px);
        margin-bottom: toRem($global--grid-halfgutter);
        border-bottom: toRem(1px) solid $ns-grey-alpha-10;

        @include respond-min($m) {
            margin-bottom: 0;
            border-bottom: none;
        }
    }
}
