
// **************************************************
//
//  _reset.scss
//
// **************************************************

@import "../../../node_modules/normalize.css/normalize";

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

figure {
    margin: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
