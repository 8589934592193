/*
@name Collapsible list (deprecated)
@description
<p>This component is deprecated. Please use <a href="/platform/components/collapse-list.html">Collapse list</a>. This is more accessible version of this component.</p>
<p>An element that contains a clickable header and a body element that is toggled visible/invisible upon clicking
For Javascript functionality, this element requires `data-controller="generic/ui/Collapsible"` to be set on the parent container.
This will cause the first child component to become the header and the second to become the body element.</p>

@section Components
@type UI
@extended false
@className collapsibleList

@markup
<ul class="list collapsibleList"
    data-controller="generic/ui/Collapsible"
    data-config="{&quot;selectors&quot;: {&quot;headerSelector&quot;: &quot;.collapsibleList__header&quot;}}">
    <li class="collapsibleList__item">
        <div class="collapsibleList__header">Header</div>
        <div class="collapsibleList__body">Body</div>
    </li>
    <li class="collapsibleList__item">
        <div class="collapsibleList__header">Header</div>
        <div class="collapsibleList__body">Body</div>
    </li>
    <li class="collapsibleList__item">
        <div class="collapsibleList__header">Header</div>
        <div class="collapsibleList__body">Body</div>
    </li>
</ul>
*/

.is-collapsible {
    &.collapsibleList {
        margin-top: toRem(26px);
        margin-bottom: toRem(16px);
    }

    .collapsibleList__item {
        border-bottom: toRem(1px) solid $ns-grey-70;
        list-style: none;
        margin: 0;
        padding: 0;

        &:first-child {
            border-top: toRem(1px) solid $ns-grey-70;
        }
    }

    .collapsibleList__header {
        @extend %collapsibleHeader;
        @extend %iconLink--collapsible;

        @include iconLink__iconAndColor('plus', $ns-light-blue);

        &.is-active {
            @include iconLink__iconAndColor('minus', $ns-light-blue);
        }

        padding: toRem(17px) toRem(35px) toRem(17px) 0;
        margin: 0;
        font-size: $font-size-base;
        width: 100%;

        &:focus {
            @extend %outline;
        }

        &::before {
            top: calc(50% - #{toRem(17px)});
            left: auto;
            right: 0;
        }
    }

    .collapsibleList__body {
        @extend %collapsibleBody;

        padding-bottom: toRem(17px);
    }
}
