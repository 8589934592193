/*
@name Tile graphic
@parent Tiles
@category tiles
@type UI
@extended false
@description

This component has a icon placed in the top right corner. This is done with the class `.tile--tilegraphic`.
If you wish to position it the same way, make sure to use this class.
In this example we are only displaying one icon type.

<br/><br/>

To add a new icon and/or color make sure to place it in the `$bg-tilegraphs:()` variable. This will automatically prefix your color and/or icon with `tilegraphic--`.
Therefor when using you have to use it with the prefix.
the `$bg-tilegraphs:()` has a key value relation. Which is where the name of the icon suits as the key and the color as the value.
Here is an example of the usage: <br/>
`$bg-tilegraphs:('hike-large':('nsBlue'));`

@markup
<div class="grid" data-controller="generic/ui/ResponsiveGrid">
    <div class="grid__unit s-4-4 m-6-12">
        <div class="tile tile--tilegraphic">
            <div class="tile__title">
                <h3 class="tile__heading headingM">
                    Top 5 wandelingen
                    <span class="tile__graphic tilegraphic tilegraphic--products-large tilegraphic--nsBlue"></span>
                </h3>
            </div>
            <div class="tile__content">
                <ul class="list list--specs">
                    <li class="list__item">
                        <a href="eropuit_wandeling-detail.shtml" class="arrowLink">Fort de Roovere</a>
                        <span class="list__spec">12.6&nbsp;km</span>
                    </li>
                    <li class="list__item">
                        <a href="eropuit_wandeling-detail.shtml" class="arrowLink">De Bretten</a>
                        <span class="list__spec">11&nbsp;km</span>
                    </li>
                    <li class="list__item">
                        <a href="eropuit_wandeling-detail.shtml" class="arrowLink">Park Lingezegen</a>
                        <span class="list__spec">7&nbsp;km</span>
                    </li>
                    <li class="list__item">
                        <a href="eropuit_wandeling-detail.shtml" class="arrowLink">Beerschoten</a>
                        <span class="list__spec">6&nbsp;km</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
*/

$bg-tilegraphs: (
    'audience-large':('nsBlue'),
    'products-large':('nsBlue'),
    'customerservice-large':('nsBlue','nsWhite'),
    'hike-large':('nsBlue'),
    'group':('nsBlue')
);

@each $name, $colors in $bg-tilegraphs {
    @each $color in $colors {
        @if str-length($color) > 0 {
            .tilegraphic.tilegraphic--#{$name}.tilegraphic--#{$color} {
                $mygraphcolor: map-get($ns-icon-colors, $color);

                @if $mygraphcolor {
                    @include createIconBg($name,$mygraphcolor);
                }
            }
        }

        @else {
            .tilegraphic.tilegraphic#{$name} {
                @include createIconBg($name);
            }
        }
    }
}

// Mixin for tilegraphic.
@mixin tilegraphic {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: toRem($tilegraphic-size-base);
    height: toRem($tilegraphic-size-base);

    @include respond-min($m) {
        background-size: 150%;
    }
}

.tilegraphic {
    @include tilegraphic();
}

/*
Tilegraphic - Sizing
*/

.tilegraphic--large {
    &.tilegraphic {
        width: toRem($tilegraphic-size-large);
        height: toRem($tilegraphic-size-large);
    }
}
