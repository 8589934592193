/*
@name Table
@description
In order for the table to have the correct styling it needs to be wrapped inside the `.richText` container.
This class will give it its zebra striped styling.
<br/>
The `.tableContainer--fade` adds a white fade on the side. This will give the user an indication that there is more information by scrolling sideways.
Make sure the table has this class.
<br/><br/>
If you want to add additional or supporting information just add the `<caption>` tag right after the `<table>` tag.
The caption will be placed at the bottom of the table.
<br/><br/>
You might have noticed that the `<table>` tag has some attributes. These are optional and makes the table even more concrete.
<br/><br/>
<strong>important:</strong><br/>
If *don't want the yellow line under the table headers use `<td>` instead of `<th>`.

@section Objects
@type UI
@extended false
@className tableContainer

@markup
<div class="richText">
<div class="tableContainer tableContainer--fade">
        <div class="tableContainer__scroll">
            <div class="tableContainer__content" data-table="">
               <table border="0" cellpadding="1" cellspacing="1" id="idTable" summary="Samenvatting">
               <caption>This is the caption for the table. Here you can add additional information</caption>
                   <thead>
                       <tr class="responsiveTable__headerRow">
                           <th scope="col" style="min-width: 100px;"><strong>Positie</strong></th>
                           <th scope="col" style="min-width: 150px;"><strong>Type Trein</strong></th>
                           <th scope="col" style="min-width: 400px;"><strong>Traject</strong></th>
                           <th scope="col" style="min-width: 300px;"><strong>Status</strong></th>
                       </tr>
                   </thead>
                   <tbody>
                       <tr>
                           <td>1</td>
                           <td>Sprinter</td>
                           <td>Amsterdam Centraal (16:04) - Haarlem - Uitgeest - Alkmaar - Hoorn (17:39)</td>
                           <td>Deze trein heeft volgens de norm gereden</td>
                       </tr>
                       <tr>
                           <td>2</td>
                           <td>Sprinter</td>
                           <td>Amsterdam Centraal (16:04) - Haarlem - Uitgeest - Alkmaar - Hoorn (17:39)</td>
                           <td>Deze trein heeft volgens de norm gereden</td>
                       </tr>
                       <tr>
                           <td>3</td>
                           <td>Sprinter</td>
                           <td>Amsterdam Centraal (16:04) - Haarlem - Uitgeest - Alkmaar - Hoorn (17:39)</td>
                           <td>Deze trein heeft volgens de norm gereden</td>
                       </tr>
                       <tr>
                           <td>4</td>
                           <td>Sprinter</td>
                           <td>Amsterdam Centraal (16:04) - Haarlem - Uitgeest - Alkmaar - Hoorn (17:39)</td>
                           <td>Deze trein heeft volgens de norm gereden</td>
                       </tr>
                       <tr>
                           <td>5</td>
                           <td>Sprinter</td>
                           <td>Amsterdam Centraal (16:04) -&gt;Haarlem - Uitgeest - Alkmaar - Hoorn (17:39)</td>
                           <td>Deze trein heeft volgens de norm gereden</td>
                       </tr>
                       <tr>
                           <td>6</td>
                           <td>Sprinter</td>
                           <td>Amsterdam Centraal (16:04) - Haarlem - Uitgeest - Alkmaar - Hoorn (17:39)</td>
                           <td>Deze trein heeft volgens de norm gereden</td>
                       </tr>
                       <tr>
                           <td>7</td>
                           <td>Sprinter</td>
                           <td>Amsterdam Centraal (16:04) - Haarlem - Uitgeest - Alkmaar - Hoorn (17:39)</td>
                           <td>Deze trein heeft volgens de norm gereden</td>
                       </tr>
                       <tr>
                           <td>8</td>
                           <td>Sprinter</td>
                           <td>Amsterdam Centraal (16:04) - Haarlem - Uitgeest - Alkmaar - Hoorn (17:39)</td>
                           <td>Deze trein heeft volgens de norm gereden</td>
                       </tr>
                       <tr>
                           <td>9</td>
                           <td><a href="javascript:void(0)">Row title #1</a></td>
                           <td>Description row 1</td>
                           <td></td>
                       </tr>
                   </tbody>
               </table>
            </div>
        </div>
        <div class="tableContainer__fadeElement" style="height: 719px;"></div>
    </div>
</div>
*/
.tableContainer {
    position: relative;
    overflow: hidden;
}

.tableContainer__scroll {
    position: relative;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: toRem(50px);
}

.tableContainer--fade {
    margin-right: toRem(-$tile-space);

    .container--gridWidth & {
        margin-right: toRem(-21px);

        @include respond-min($m) {
            margin-right: toRem(-$tile-space);
        }
    }

    //Create $tile-space padding on the right
    .tableContainer__content {
        padding-right: toRem($tile-space);
        box-sizing: content-box;
        display: inline-block;

        .container--gridWidth & {
            padding-right: toRem(21px);

            @include respond-min($m) {
                padding-right: toRem($tile-space);
            }
        }
    }

    //overlay has no space for margin
    .overlay & {
        margin-right: 0;
    }
}

.tableContainer__fadeElement {
    display: none;

    .tableContainer--fade & {
        background-image: linear-gradient(to right, rgba($white, 0) 0, rgba($white, 1) 100%);
        display: block;
        height: 100%;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate3d(0, 0, 0); // fix iOS/Android issues, force this layer on top.
        width: toRem($tile-space);
        z-index: 1;

        .container--gridWidth & {
            width: toRem(21px);

            @include respond-min($m) {
                width: toRem($tile-space);
            }
        }
    }
}

%genericTableStyling {
    position: relative;
    margin-bottom: toRem(30px);
    border: 0;

    caption {
        text-align: left;
        color: $ns-grey-60;
        caption-side: bottom;
        margin-top: toRem(12px);
        // width: 50%;
    }

    th,
    td {
        vertical-align: top;
    }

    thead {
        tr {
            th {
                font-weight: normal;
                padding: toRem(12px) toRem($tile-space * 0.5);
                text-align: left;
                color: $ns-blue;
                border-bottom: toRem(2px) solid $ns-yellow;
            }
        }
    }

    tbody {
        tr {
            th {
                font-weight: bold;
                padding: toRem(12px) toRem($tile-space * 0.5);
                text-align: left;
                color: $ns-blue;
            }

            td {
                padding: toRem(12px) toRem($tile-space * 0.5);

                &:last-child {
                    border-right: none;
                }
            }

            td,
            th {
                background: $ns-white;
            }

            &:nth-child(odd) {
                td,
                th {
                    background: $ns-grey-90;
                }
            }

            &:last-child {
                td,
                th {
                    border-bottom: toRem(1px) solid $ns-grey-70;
                }
            }
        }
    }

    // links in table are overwritten globally, so CK-editor doesn't need to change its output
    a {
        @extend .arrowLink;
    }
}
