@use 'sass:math';

.separator--top {
    @include separator-top;
    // create distance between separator and content.
    padding-top: toRem($tile-space * 0.5); // default vertical rhythm value.
}

.separator--largeTop {
    @include separator-top;
    // create large distance between separator and content.
    padding-top: toRem($tile-space);
}

.separator--smallTop {
    @include separator-top;
    // create smaller distance between separator and content.
    padding-top: toRem($tile-space * 0.25);
}

.separator--noTop {
    @include separator-top;
}

.separator--alphaGray {
    border-color: $ns-grey-alpha-10;
}

.articleSeparator {
    border: none;
    clear: both;
    margin-bottom: toRem(22px);
    margin-left: 0;
    margin-right: 0;
    margin-top: toRem(22px);

    @include separator-bottom;

    @include respond-min($l) {
        $margin-calculated: calc((100% + #{toRem($global--grid-gutter)}) / 12);
        $margin-fallback: percentage(math.div(1, 12));

        margin-left: $margin-fallback;
        margin-left: $margin-calculated;
        margin-right: $margin-fallback;
        margin-right: $margin-calculated;
    }
}

.articleSeparator--fullWidth {
    margin-left: 0;
    right: 0;
}
