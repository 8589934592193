/*
@name Notification inline
@description
A component which is used to display a message to the user in case of an exception.

@section Components
@type UI
@extended false

@modifier .inlineNotification--alert                    - A message with high urgency

@markup
<p class="inlineNotification {{modifier_class}}">
    <span class="icon icon--alert icon--nsRed icon--inline inlineNotification__icon">
        <span class="alt">Let op:</span>
    </span>
    <span class="inlineNotification__text">Er is iets misgegaan... At eum nihili facit; Nec tamen </span>
</p>

*/

.inlineNotification {
    @extend %iconizedTextHolder;

    .inlineNotification__icon {
        @extend %iconizedTextIconPosition;
    }

    &.inlineNotification--blue {
        color: $ns-blue;
    }

    &.inlineNotification--alert {
        color: $ns-red-alert;
    }
}
