.datePicker {
    position: relative;
}

.datePicker__button {
    position: absolute;
    display: inline-block;
    top: toRem(($input-height - $icon-size-base) * 0.5);
    right: toRem($icon-offset * 0.5);
    background: none;
    border: none;

    &,
    &:hover {
        box-shadow: none;
    }

    .formfield & {
        // TODO: proper fix
        //right: 36px;
    }
}
