/*

@name Fonts
@description Fonts
@section Basics
@type UI
@extended false

*/

$font-version: "1.0.1";
$font-path: "https://static-ns-nl-data.fep-p.cla.ns.nl/apps/nessie/fonts/#{$font-version}";

@font-face {
    font-family: 'NS Sans';
    src:
        url('#{$font-path}/nssans-regular.woff2') format('woff2'),
        url('#{$font-path}/nssans-regular.woff') format('woff'),
        url('#{$font-path}/nssans-regular.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NS Sans';
    src:
        url('#{$font-path}/nssans-italic.woff2') format('woff2'),
        url('#{$font-path}/nssans-italic.woff') format('woff'),
        url('#{$font-path}/nssans-italic.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'NS Sans';
    src:
        url('#{$font-path}/nssans-bolditalic.woff2') format('woff2'),
        url('#{$font-path}/nssans-bolditalic.woff') format('woff'),
        url('#{$font-path}/nssans-bolditalic.ttf') format('truetype');
    font-display: swap;
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'NS Sans';
    src:
        url('#{$font-path}/nssans-bold.woff2') format('woff2'),
        url('#{$font-path}/nssans-bold.woff') format('woff'),
        url('#{$font-path}/nssans-bold.ttf') format('truetype');
    font-display: swap;
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'NS Sans';
    src:
        url('#{$font-path}/nssans-demi.woff2') format('woff2'),
        url('#{$font-path}/nssans-demi.woff') format('woff'),
        url('#{$font-path}/nssans-demi.ttf') format('truetype');
    font-weight: 550;
    font-style: normal;
    font-display: swap;
  }

/* If NS Sans font is not displaying characters correctly (for example the + sign with certain font-sizes),
   fallback to standard fonts. */
.font-fallback {
    font-family: $font-fallback;
}
