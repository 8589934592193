/*
@name Footer
@description Website footer
@section Components
@type UI
@extended true
@markup
<footer class="footer">
    <div class="container">
        <div class="grid footer__grid" data-controller="generic/ui/ResponsiveGrid" data-type="footermenu">
            <div class="grid__unit m-6-12 l-3-12" data-type="topfootermenuitem" style="height: 318px;">
                <div class="footer__linkbox">
                    <h3 class="headingXXS footer__heading">Direct naar...</h3>
                    <ul class="list">
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink">Reisinformatie</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink">Uitgelicht</a>
                        </li>
                        <li class="list__item">
                                <a href="javascript:void(0)" class="arrowLink">Zakelijk</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink">Klantenservice</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="grid__unit m-6-12 l-3-12" data-type="topfootermenuitem" style="height: 318px;">
                <div class="footer__linkbox">
                    <h3 class="headingXXS footer__heading">Onze producten</h3>
                    <ul class="list">
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink">Kaartjes &amp; Toeslagen</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink">Abonnementen</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink">Deur tot deur</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink">Dagje uit</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink" target="_blank" rel="external">Internationale treinkaartjes</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="grid__unit m-6-12 l-3-12" data-type="topfootermenuitem" style="height: 318px;">
                <div class="footer__linkbox">
                    <h3 class="headingXXS footer__heading">Meer NS</h3>
                    <ul class="list">
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink">Over NS</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink" target="_blank" rel="external">Werken bij NS</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink">Partners in mobiliteit</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink" target="_blank" rel="external">Nieuws</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="grid__unit m-6-12 l-3-12" data-type="socialfootermenuitem" style="height: 318px;">
                <div class="footer__linkbox">
                    <h3 class="headingXXS footer__heading">Sociale media</h3>
                    <ul class="list">
                        <li class="list__item">
                            <a href="javascript:void(0)" class="iconLink iconLink--facebook" target="_blank" rel="external">Facebook</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="iconLink iconLink--x" target="_blank" rel="external">X</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="iconLink iconLink--instagram" target="_blank" rel="external">Instagram</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="iconLink iconLink--youtube" target="_blank" rel="external">YouTube</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="iconLink iconLink--linkedin" target="_blank" rel="external">LinkedIn</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="grid footer__grid" data-type="bottomfootermenu">
            <div class="grid__unit footer__grid-unit">
                <div class="footer__menuContainer separator--largeTop">
                    <ul class="footer__menu list list--horizontal" data-controller="generic/ui/EqualWidth">
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink arrowLink--grey">Disclaimer</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink arrowLink--grey">Privacy</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink arrowLink--grey">Bedrijfsinformatie</a>
                        </li>
                        <li class="list__item">
                            <a href="javascript:void(0)" class="arrowLink arrowLink--grey">Voorwaarden en folders</a>
                        </li>
                    </ul>
                    <div class="nslogo nslogo--footer">
                        <a class="nslogo__link" href="javascript:void(0)" title="Ga naar de homepagina">
                            <img class="nslogo__image" src="<%= path.static %>/images/nslogo.svg" height="20" width="52" alt="Logo Nederlandse Spoorwegen">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
*/

$footer-spacer: $tile-space;

.footer {
    position: relative;
    width: 100%;
    background-color: $white;
    line-height: toRem($line-height-base);

    .list__item:last-child {
        margin-bottom: 0;
    }

    .hide-footer & {
        display: none;
    }

    .nessie & {
        border-top: 1px solid $nes-border-default;
    }
}

.footer__grid {
    &:first-child {
        .footer__grid-unit {
            padding-bottom: 0;
        }
    }

    &:last-child {
        .footer__grid-unit {
            padding-top: 0;
        }
    }

    &:only-child {
        .footer__grid-unit {
            padding-bottom: toRem($global--grid-halfgutter);
        }

        .footer__menuContainer {
            border-top: 0;
        }
    }
}

.footer__grid-unit {
    & + & {
        .footer__linkbox {
            padding-top: 0;

            @include respond-min($l) {
                padding-top: toRem($footer-spacer);
            }
        }
    }

    &:nth-child(2) {
        .footer__linkbox {
            @include respond-min($m) {
                padding-top: toRem($footer-spacer);
            }
        }
    }
}

.footer__linkbox {
    padding: toRem($footer-spacer);

    .nessie & {
        padding-left: 0;
        padding-right: 0;
    }
}

.footer__menuContainer {
    @include clearfix;

    margin: 0 toRem($footer-spacer) toRem(18px);
    padding-top: toRem($footer-spacer);
    border-top: toRem(1px) solid $ns-grey-90;

    .nessie .footer & {
        margin-left: 0;
        margin-right: 0;
    }
}

.footer__menu {
    float: left;
    width: calc(100% - #{toRem(52px + $footer-spacer)}); // .nslogo width + a margin
}

.footer__heading {
    font-weight: bold;
}

.footer.footer--separator {
    // chained for specificity (must override ':only-child' selector within .footer__grid ^^)
    .footer__menuContainer {
        border-top: toRem(1px) solid $ns-grey-90;
    }
}
